import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { TokenService } from './token.service';
import { CACHE_AUTH_TOKEN } from '@models/constants';
import { Observable, throwError } from 'rxjs';
import {
  IPortInCreateReq,
  PortInCreateReqRemote,
  IportInCreateRes,
  GetPortResponse,
  PortOutBody
} from '@pages/service-page/rain-one-actions/port-my-number/port-in.interface';
import { catchError, tap } from 'rxjs/operators';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PortInFailureModalComponent } from '@pages/service-page/rain-one-actions/port-my-number/port-in-modals/port-in-failure-modal/port-in-failure-modal.component';
import { PortPendingModalComponent } from '@pages/service-page/rain-one-actions/port-my-number/port-in-modals/port-pending-modal/port-pending-modal.component';
import { RainNumberFailureModalComponent } from '@pages/service-page/rain-one-actions/port-my-number/port-in-modals/rain-number-failure-modal/rain-number-failure-modal.component';
import { Store } from '@ngxs/store';
import { PortSimPending } from '../store/actions/number-porting-actions';
import { UIState } from '../shared/store/state/ui.state';
import { AuthState } from '../core/store/state/auth.state';


@Injectable({
  providedIn: 'root'
})
export class NumberPortingService {
  constructor(
    private _http: HttpClient,
    private tokenSVC: TokenService,
    private configService: ConfigService,
    private ngModal: NgbModal,
    public activeModal: NgbActiveModal,
    private store: Store
  ) { }

  portInByMsisdn(rain_msisdn: string): Observable<PortInCreateReqRemote> {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-in/${rain_msisdn}/number-swap`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.post<PortInCreateReqRemote>(url, httpOptions);
  }

  //port-in welcome message
  portInWelcomeMessage(id: string) {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-in/${id}/send-welcome-message`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.post(url, httpOptions);
  }
  //cancel number port in using id
  reversePortById(id: string): Observable<GetPortResponse> {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-in/${id}/cancel`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.post<GetPortResponse>(url, httpOptions);
  }
  //cancel number port in using id

  cancelPortInById(id: string): Observable<GetPortResponse> {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-in/${id}/cancel`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };
    const portCancelation = {
      reason: 'Cancel Port In Request',
      ported_in_error: true
    };
    return this._http.post<GetPortResponse>(url, portCancelation, httpOptions);
  }
  //validate port in OTP
  validatePortInOtp() {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-in/validate-otp`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.post(url, httpOptions);
  }

  createPortInrequest(body: PortInCreateReqRemote): Observable<PortInCreateReqRemote> {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-in/create-request`;

    let uiMode = this.store.selectSnapshot(UIState.GetUIMode);
    let smeToken = this.store.selectSnapshot(AuthState.getSmeToken)
    let userToken = ((uiMode !== 'consumer') && smeToken) ? smeToken : this.tokenSVC.get(CACHE_AUTH_TOKEN);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken
      })
    };

    let modalRef: NgbModalRef;
    return this._http.post<PortInCreateReqRemote>(url, body, httpOptions).pipe(
      tap({
        next: () => {
          this.activeModal.close();

          modalRef = this.ngModal.open(PortPendingModalComponent, {
            centered: true,
            size: 'sm',
            windowClass: 'slideInUp d-flex'
          });
          this.store.dispatch(new PortSimPending(true))
          modalRef.componentInstance.id = body?.service_id;
        },
        error: (e) => {
          modalRef.close();
          //todo  generic error component modal that recieves error and displays it
          this.ngModal.open(RainNumberFailureModalComponent, {
            centered: true,
            size: 'sm',
            windowClass: 'slideInUp d-flex'
          });
          //error = modalref
          return throwError(e);
        }
      })
    );
  }

  getCurrentPortStatusByServiceId(id: string): Observable<GetPortResponse> {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-in/${id}/service`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.get<GetPortResponse>(url, httpOptions);
  }

  getCurrentPortStatusById(id: string): Observable<GetPortResponse> {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-in/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.get<GetPortResponse>(url, httpOptions);
  }

  getPortInRainMsisdn(): Observable<IportInCreateRes> {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-in/search`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };

    return this._http.get<IportInCreateRes>(url, httpOptions);
  }
  portOutRequest(body: PortOutBody): Observable<PortOutBody> {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-out/validate-otp`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };
    return this._http.post<PortOutBody>(url, body, httpOptions);
  }


  getPortOutById(id: string) : Observable<any> {
    const url: string = `${this.configService.BASE_API_URL}/bss-api/v1/mnp-service/port-out/${id}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenSVC.get(CACHE_AUTH_TOKEN)
      })
    };
    
    return this._http.get<any>(url, httpOptions)
  }
}
