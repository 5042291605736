import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Utils } from '@Utils';
import { CoreState } from '../store/state/core.state';
import { ConfigService } from '@services/config.service';
import { CoverageCheckedOnRegistration } from 'src/app/store/actions/cart.action';
import { ClearState } from 'src/app/store/actions/number-porting-actions';
import { ClearServicesState, ClearServicePolicies } from 'src/app/store/actions/services.actions';
import { AuthenticationService } from '@services/auth.service';
import { UIState } from 'src/app/shared/store/state/ui.state';
import { AuthState } from '../store/state/auth.state';

interface SsoUrlParams {
  tauToken?: string,
  token?: string,
  redirectUrl?: string,
  tokenSme?: string,
  mode?: string,
  config?: {[key: string]: any}
}

@Injectable({
  providedIn: 'root',
})
export class SSOService {
  private cryptor = new Utils.Helpers.Encryptor();
  private _defaultTargetDomain = ""
  
  constructor(
    private store: Store,
    private configService: ConfigService,
    private authService: AuthenticationService
  ) {
    this._defaultTargetDomain = window.location.href.includes('precipitation') ? 'https://www.sit.raingo.co.za/?sheet=sign_in' : `${this.configService.SSO_RAINGO_URL}/?sheet=sign_in`;
    // this._defaultTargetDomain = 'https://odyssey-local.rain.network:4200/?sheet=sign_in';
  }

  /**
   *
   * @description creates the url with the AES encrypted sso qParams to redirect to rainGo.co.za
   * @param {string} [redirectToSlug='my-rain']
   * @return {*}  {Promise<string>}
   * @memberof SSOService
   */
  public createUrl(redirectToSlug?: string): Promise<string> {
    const tokens = this.store.selectSnapshot(CoreState.getTokens);
    const mode = this.store.selectSnapshot(UIState.GetUIMode);
    const hasPrepaid = this.store.selectSnapshot(AuthState.getHasPrepaid);

    const config = {
      hasPrepaid
    };

    const paramsMap: SsoUrlParams = {
      tauToken: tokens.auth,
      token: tokens.idm,
      redirectUrl: redirectToSlug || 'home',
      config
    };

    if (tokens?.tokenSme) {
      paramsMap.tokenSme = tokens?.tokenSme;
      paramsMap.mode = mode
    }
    
    return new Promise((resolve, reject) => {
      const encryptedData = this.cryptor.encrypt(JSON.stringify(paramsMap));
      let url: URL = new URL(`${
        this._defaultTargetDomain
      }&sso=${encodeURIComponent(encryptedData)}`);

      if(url) {
        this.signOutBeforeSSO();
        resolve(url.href)
      };
      reject('unable to create url');
    });
  }

  /**
   *
   * @description a function to decrypt the sso payload
   * @param {string} fullUrl
   * @return {*}  {*}
   * @memberof SSOService
   */
  public decodeUrlParams(fullUrl: string): Promise<string> {
    return new Promise((resolve, reject) => {
        const encodedData = this._getParamsByName('sso', fullUrl) as string;
        const decryptedData = this.cryptor.decrypt(encodedData);
        if(decryptedData) resolve(decryptedData);
        else reject('unable to decode sso payload')
    });
  }

  /**
   *
   * @description internal function to get the sso qParams when recieving the a redirect from rain.co.za
   * @private
   * @param {string} name
   * @param {string} url
   * @return {*}  {(string | null)}
   * @memberof SSOService
   */
  private _getParamsByName(name: string, url: string): string | null {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  private signOutBeforeSSO() {
    this.store.dispatch([
      new ClearServicesState(),
      new ClearServicePolicies(),
      new CoverageCheckedOnRegistration(false),
      new ClearState()
    ])
      localStorage.removeItem('selected_payment_date');
      this.authService.signOut();
      sessionStorage.clear();
  }
}
