import { Component, OnInit } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { SSOService } from 'src/app/core/services/sso.service';

@Component({
  selector: 'rain-setting-up-network',
  templateUrl: './setting-up-network.component.html',
  styleUrls: ['./setting-up-network.component.scss']
})
export class SettingUpNetworkComponent implements OnInit {

  constructor( 
    private sso: SSOService,
    private dataLayerService: DataLayerService ) { }

  ngOnInit(): void {
  }
  openRainGo() {
    this.dataLayerService.rainGoClicked();
    return this.sso.createUrl().then(
      (encodedUrl) => {
        window.open(encodedUrl, '_self')
      }
    );
  }

}
