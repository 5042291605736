import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapModule } from '@components/map/map.module';
import { AgreeCookiesComponent } from './agree-cookies.component';

@NgModule({
    declarations: [
        AgreeCookiesComponent
    ],
    imports: [
        CommonModule,
        MapModule
    ],
    exports: [AgreeCookiesComponent],
    providers: [],
})
export class AgreeCookiesModule { }
