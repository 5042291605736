<div class="modal-content border-0 px-sm-20">
    <div class="modal-header border-0 mb-10">
      <div class="modal-img-close-btn z-index-5">
        <img src="../../assets/svg/close.svg" class="pointer" alt="Close" (click)="activeModal.dismiss('close click')" />
      </div>
    </div>
    
    <div>
      <div class="text-center modal-body px-10 pt-1">
        <h2 class="text-primary" [innerHtml]="title"></h2>
  
        <p class="mb-0">{{copy}}</p>
        <p class="mt-4" *ngIf="secondCopy">{{secondCopy}}</p>
  
      </div>
      <div class="modal-footer border-top-0 d-flex mb-10 justify-content-center">
        <button class="btn btn-sm btn-primary m-2 px-10" (click)="activeModal.dismiss('close click')" data-dismiss="modal">
          got it
        </button>
  
      </div>
    </div>
  </div>