import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CACHE_4G_5G_MIGRATION_DETAILS } from '@models/constants';
import { CacheService } from '@services/cache.service';

@Component({
  selector: 'app-success-error-modal',
  templateUrl: './success-error-modal.component.html',
  styleUrls: ['./success-error-modal.component.scss']
})
export class SuccessErrorModalComponent {
  @Input()
  title: string;

  @Input()
  serviceId: string;

  @Input()
  description: string;

  @Input()
  btnName: string;

  constructor(public activeModal: NgbActiveModal, private router: Router, private cacheService: CacheService) {}

  confirm() {
    if (this.serviceId) {
      this.router.navigateByUrl(`/service/${this.serviceId}`);
      this.cacheService.remove(CACHE_4G_5G_MIGRATION_DETAILS);
    }
    this.activeModal.close();
  }
}
