import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationModalComponent } from '@components/confirm-modal/confirm-modal.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FourgUpsellComponent } from '@pages/fiveg-page/fourg-upsell/fourg-upsell.component';
import { DataLayerService } from '@services/data-layer.service';
import { ProductService } from '@services/product.service';
import { CloseModal } from 'src/app/shared/store/actions/ui.actions';
import { UIState } from 'src/app/shared/store/state/ui.state';


@Component({
  selector: 'app-out-of-coverage-modal',
  templateUrl: './out-of-coverage-modal.component.html',
  styleUrls: ['./out-of-coverage-modal.component.scss']
})
export class OutOfCoverageModalComponent implements OnInit, OnDestroy {
  @Select(UIState.GetUIMode) selectedUiMode$ : Observable<string>;
  
  @ViewChild('mainContent', { static: true }) mainContent: TemplateRef<any>;

  @ViewChild('confirmedContent', { static: true }) confirmedContent: TemplateRef<any>;

  @ViewChild('errorContent') errorContent: TemplateRef<any>;

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  @Input()
  latitude: number;

  @Input()
  longitude: number;

  @Input()
  notify5G: boolean;

  @Input()
  fromPage: string;

  @Input()
  notify4G: boolean;

  @Input()
  product: any;

  @Input()
  address: string = null;

  @Input()
  type: string;

  description: string

  public status: 'content' | 'confirmed' = 'content';
  name: string;
  fourGFoundfrom5gPage = false;

  nameValidationMessages: ValidationErrors = {
    required: 'First name is required.',
  };
  lastNameValidationMessages: ValidationErrors = {
    required: 'Last name is required.'
  };
  emailValidationMessages: ValidationErrors = {
    required: 'Email is required.',
    pattern: 'Please enter a valid email address.',
  };
  cellNumberValidationMessages: ValidationErrors = {
    required: 'Cell number is required.',
    pattern: 'Please enter a valid cell number.'
  };

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private dataLayerService: DataLayerService,
    private productService: ProductService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.fourGFoundfrom5gPage = this.notify4G;

    if (this.notify4G === true && this.fromPage === '5G-page') {
      this.notify4G = false;
    }

    if (this.notify5G !== undefined || this.notify4G !== undefined) {
      if (this.notify5G && this.notify4G) {
        this.description = `If you’d like to be contacted when your neighbourhood is in 4G or 5G coverage, please fill in your details below and select the network you’re interested in.`;
      } else if (!this.notify5G && this.notify4G) {
        this.description = `If you’d like to be contacted when your neighbourhood is in 4G coverage, please fill in your details below and select the network you’re interested in.`;
      } else {
        this.description = `If you’d like to be contacted when your neighbourhood is in 5G coverage, please fill in your details below and select the network you’re interested in.`;
      }

    } else {
      this.description = `if you'd like to be contacted when 5G is in your neighbourhood, please fill in your details below.`;
    }

  }

  public handleSubmit(event) {
    this.name = event.name;

    if (event.status) {
      this.activeModal.dismiss();
      this.displayConfirmModal();
      this.status = 'confirmed';
    }
  }

  public getTemplate() {
    switch (this.status) {
      case 'content':
        return this.mainContent;
      case 'confirmed':
        this.displayConfirmModal();
        break;
    }
  }

  displayConfirmModal() {
    
    this.confirmDialog();
  }

  show4GProduct(): void {
    this.productService.getById('10').subscribe((product) => {
      this.dataLayerService.modalView(this.dataLayerService.modalTitleMap['unlimited-home-5G-standard-in-coverage']);
      const modalRef = this.modalService.open(FourgUpsellComponent, { size: <any>'lg', windowClass: 'slideInUp', centered: true });
      modalRef.componentInstance.fromGotSim = false;
      modalRef.componentInstance.product = product.value;
      modalRef.componentInstance.fromPage = this.fromPage;
      modalRef.componentInstance.title = `<h2 class="text-primary modal-title mt-20 fiveg-title text-center">stay connected while you wait</h2>`;
      modalRef.componentInstance.mobileTitle = `<h2 class="text-primary modal-title mb-10 mt-20 fiveg-title text-center">stay connected<br> while you wait</h2>`;
    });

  }

  confirmDialog() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'md' as any,
      centered: true
    });
    modalRef.componentInstance.data = {
      title: `Thanks ${this.name}`,
      description: `<p class="pt-sm-10 pl-sm-20 pr-sm-20">We have got your details. We will let you know as soon as there is coverage in your area.</p> `,
      buttonName: 'got it'
    };

    if(window.location.href.includes('register/cart')) {
      this.onClose.emit(true);
    }
  }

  hide() {
    this.activeModal.close();

  }

  ngOnDestroy(): void {
    this.onClose.emit(true);
    this.store.dispatch(new CloseModal({name: 'notify'}))
  }
}
