import { CACHE_CURRENT_DELIVERY_CONFIGS, CONFIG_COMMENTS, CONFIG_FRIENDLY_NAME, RAINONE_101_5G_SIM, RAINONE_5G_SIM } from './../models/constants';
import { Injectable } from '@angular/core';
import { isEmpty, isNil, remove, some, uniq } from 'lodash';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { v4 } from 'uuid';
import { BaseComponent } from '../baseComponent';
import { IAddress } from '@models/addressDetail';
import {
  CACHE_CART,
  CACHE_CURRENT_DELIVERY_ADDRESS,
  CACHE_CURRENT_ORDER,
  CACHE_CURRENT_ORDER_ID,
  CONFIG_VALUE_ADDS,
  RICA_TYPE_COURIER,
  RICA_TYPE_E_RICA,
  DEFAULT_POSTAL_CODE,
  CACHE_GENERATE_VOUCHER
} from '@models/constants';
import { IDictionary } from '@models/dictionary';
import { IOrderDetail } from '@models/orderDetail';
import { IProductDetail, IProductItemDetail, ProductDetail, ProductTypes } from '@models/productDetail';
import { Result, Statuses } from '@models/result';
import { CacheService } from './cache.service';
import { ConfigService } from './config.service';
import { DataLayerService } from './data-layer.service';
import { CartExpiryService } from './idle.service';
import { IOrderAddItem, OrderAddRequest, OrderService } from './order.service';
import { ProductService } from './product.service';
import { Store } from '@ngxs/store';
import { ServicesState } from '../store/state/services.state';
import { ProductState } from '../store/state/product.state';
import { SwitchAccountTypes } from '../core/store/actions/billing.actions';
import { AddToCart, ResetCart } from '../store/actions/cart.action';
import { CartState } from '../store/state/cart.state';
import { ServiceStatuses } from '@models/serviceDetail';
import { OrderState } from '../store/state/order.state';


interface ICartConfig extends IDictionary<any> { }
export interface ICartItemMapped {
  item: CartItem;
  product: IProductDetail;
}
export interface CartItem {
  id: string;
  productId: string;
  config: ICartConfig;
}
export class DeliveryAddress {

  static mapfromString(address: string) {
    const newAddress = {
      city: '',
      province: '',
      buildingName: null,
      country: null,
      floorLevel: null,
      instructions: null,
      streetName: '',
      unitNumber: null,
      streetNumber: '',
      suburb: '',
      postalCode: '0000'
    }
    if (address) {
      const addressArray = address.split(',')
      let startIndex = 0;
      if (isNaN(+addressArray[0])) {
        newAddress.streetNumber = addressArray[0];
        startIndex = 1;
      }

      newAddress.streetName = addressArray[0 + startIndex];
      newAddress.suburb = addressArray[1 + startIndex];
      newAddress.city = addressArray[2 + startIndex];
    }
    return newAddress;
  }



  static mapFromUserDetail(addresses: IAddress[]): IDeliveryAddress {

    const address = addresses[0];
    let streetNumber = isNil(address.streetNumber) ? '' : address.streetNumber;
    let streetName = isNil(address.streetName) ? '' : address.streetName;
    const suburb = isNil(address.suburb) ? '' : address.suburb;
    const postalCode = isNil(address.postalCode) ? '' : address.postalCode;
    const province = isNil(address.province) ? '' : address.province;
    const city = isNil(address.city) ? '' : address.city;

    return {
      city: city,
      province: province,
      buildingName: null,
      country: null,
      floorLevel: null,
      instructions: null,
      streetName,
      unitNumber: null,
      streetNumber,
      suburb: suburb,
      postalCode: postalCode
    };
  }

  static mapfromGoogle(result: GoogleAddress): IDeliveryAddress {
    const addressComponents = result.address_components;

    let city,
      province,
      streetNumber,
      streetName,
      suburb,
      postalCode = DEFAULT_POSTAL_CODE;

    addressComponents.forEach(element => {
      element.types.forEach(type => {
        if (type === 'administrative_area_level_2') {
          city = element.long_name;
        } else if (type === 'administrative_area_level_1') {
          province = element.long_name;
        } else if (type === 'street_number') {
          streetNumber = element.long_name;
        } else if (type === 'street_name' || type == 'route') {
          streetName = element.long_name;
        } else if (type === 'sublocality') {
          suburb = element.long_name;
        } else if (type === 'postal_code') {
          postalCode = element.long_name;
        }
      });
    });

    return {
      city: city,
      province: province,
      streetNumber: streetNumber,
      suburb: suburb,
      postalCode: postalCode,
      buildingName: null,
      country: null,
      floorLevel: null,
      instructions: null,
      streetName,
      unitNumber: null
    };
  }
}

export interface IDeliveryAddress extends IAddress {
  instructions: string;
}

export interface IDeliveryConfig {
  name: string;
  value: string;
}
/**
 *
 *
 * @export
 * @class CartService
 * @extends {BaseComponent}
 */
@Injectable({
  providedIn: 'root'
})
export class CartService extends BaseComponent {
  
  public onAdded: Subject<CartItem> = new Subject<CartItem>();
  public onRemoved: Subject<CartItem> = new Subject<CartItem>();
  public onUpdated: Subject<number> = new Subject<number>();
  public onCleared: Subject<void> = new Subject<void>();

  constructor(
    private configService: ConfigService,
    private cacheService: CacheService,
    private orderService: OrderService,
    private productService: ProductService,
    private cartExpiryService: CartExpiryService,
    private dataLayerService: DataLayerService,
    private store: Store
  ) {
    super();

    this.cacheService.onCleared.pipe(takeUntil(this.ngUnsubscribe)).subscribe(_x => {
      if(!this.cacheService.getObject<CartItem[]>(CACHE_CART) || this.cacheService.getObject<CartItem[]>(CACHE_CART).length < 1) {
        this.store.dispatch( new ResetCart());
      }
      this.onCleared.next();
    });
  }

  getOrder(): IOrderDetail {
    return this.cacheService.getObject<IOrderDetail>(CACHE_CURRENT_ORDER);
  }

  hasDeliveryAddress(): boolean {
    return this.cacheService.exists(CACHE_CURRENT_DELIVERY_ADDRESS);
  }

  setDeliveryAddress(deliveryAddress: IDeliveryAddress) {
    this.cacheService.setObject(CACHE_CURRENT_DELIVERY_ADDRESS, deliveryAddress);
  }

  setDeliveryConfigs(deliveryConfigs: IDeliveryConfig[]) {
    this.cacheService.setObject(CACHE_CURRENT_DELIVERY_CONFIGS, deliveryConfigs);
  }

  getDeliveryAddress(): IDeliveryAddress {
    return this.cacheService.getObject(CACHE_CURRENT_DELIVERY_ADDRESS);
  }

  createOrder(addons?: any): Observable<Result<IOrderDetail>> {
    const currentOrder = this.getOrder();
    
    if (isNil(currentOrder)) {
      return forkJoin(this.productService.get(), this.getMappedItems()).pipe(
        switchMap(([productsResult, itemProducts]) => {
          let products = [];

          if (productsResult.status == Statuses.Success) {
            products = productsResult.value;
          }

          const twoInOneForFriend = JSON.parse(localStorage.getItem(CACHE_GENERATE_VOUCHER));

          if (twoInOneForFriend) {
            for (let i = 0; i < itemProducts?.length; i++) {
              if (itemProducts[i].product.id === '8') {
                itemProducts[i].product?.items.splice(3, 3);
              }
            }
          }

          const deliveryInfo = this.getDeliveryInfo(itemProducts, products);
  
          return this.createOrderLines(itemProducts).pipe(
            map(items => {
              return { items, deliveryInfo };
            })
          );
        }),
        map((result: { deliveryInfo: { deliveryType: string; ricaType: string }; items: IOrderAddItem[] }) => {
          const { deliveryInfo, items } = result;
          
          const deliveryAddress = this.getDeliveryAddress();
          
          const order = {
            deliveryType: deliveryInfo.deliveryType,
            ricaType: deliveryInfo.ricaType,
            items,
            deliveryAddress: null,
            instructions: ''
          } as OrderAddRequest;

          if (isNil(deliveryAddress) == false) {
            order.deliveryAddress = {
              buildingName: deliveryAddress.buildingName,
              floorLevel: deliveryAddress.floorLevel,
              city: deliveryAddress.city,
              postalCode: deliveryAddress.postalCode,
              province: deliveryAddress.province,
              streetName: deliveryAddress.streetName,
              streetNumber: deliveryAddress.streetNumber,
              suburb: deliveryAddress.suburb,
              unitNumber: deliveryAddress.unitNumber,
              gps_coordinates: deliveryAddress.gps_coordinates
            };
            order.instructions = deliveryAddress.instructions;
          }


          return order;
        }),
        switchMap(order => {
          
          return this.orderService.add(order);
        }),
        tap(result => {
          
          if (result.status == Statuses.Success) {
            this.cacheService.setObject(CACHE_CURRENT_ORDER, result.value);

            this.setOrderId(result.value.id);
          }
        })
      );
    } else {
      return of(Result.success(currentOrder));
    }
  }

  getDeliveryInfo(
    itemProducts: ICartItemMapped[],
    products: IProductDetail[]
  ): { deliveryType: string; ricaType: string } {
    let deliveryType = 'scheduled';
    let ricaType = RICA_TYPE_COURIER;

    const hasErica = some(itemProducts, mappedCartItem => {
      return ProductDetail.hasErica(mappedCartItem.product, products);
    });

    if (hasErica) {
      deliveryType = 'rica';
      ricaType = RICA_TYPE_E_RICA;
    }

    return { deliveryType, ricaType };
  }

  private createOrderLines(itemProducts: ICartItemMapped[]): Observable<IOrderAddItem[]> {
    return this.productService.get().pipe(
      map(result => {
        const products = result.value;
        
        const orderLines = itemProducts.map(mappedItem => {
          const items: IOrderAddItem[] = [];

          const product = mappedItem.product;

          const cartItem = mappedItem.item;
          let currentOrderLine = {
            config: this.getConfigForProduct(cartItem, product),
            productId: product.id
          };

          if (product.type == ProductTypes.Bundle) {
            const itemOrderLines = product.items.map(x => ({
              config: this.getConfigForProduct(cartItem, x),
              productId: x.id
            }));
            items.push(...itemOrderLines);

            currentOrderLine = null;
          } else if (product.type == ProductTypes.Variant) {
            const baseProductId = mappedItem.product.baseId;

            currentOrderLine.productId = baseProductId;
          }

          if (isEmpty(product.addons) == false) {
            const nonOptionalAddons = product.addons.filter(x => x.optional == false);

            const value_adds = nonOptionalAddons.map(x => ({ id: x.id }));

            currentOrderLine.config[CONFIG_VALUE_ADDS] = value_adds;
          }

          if (isNil(currentOrderLine) == false) {
            items.push(currentOrderLine);
          }

          return items;
        });
        
        return orderLines.reduce((prev: IOrderAddItem[], next: IOrderAddItem[]) => (prev = prev.concat(next)), []);
      })
    );
  }

  private getConfigForProduct(item: CartItem, product: IProductDetail | IProductItemDetail): any {
    const config = { [CONFIG_VALUE_ADDS]: [] };
    for (let key in item.config) {
      let cartItemvalue = item.config[key];

      if (isNil(product.config) == false && isNil(product.config[key]) == false) {
        config[key] = cartItemvalue;
      }
    }

    return config;
  }

  getCount(): number {
    let cartItems: CartItem[] = this.internalGetAll();
    return cartItems.length;
  }

  getCount$(): Observable<number> {
    let cartItems: CartItem[] = this.internalGetAll();
    return of(cartItems.length);
  }

  getNewSimName(): string {
    const num = this.getCount();
    let friendlyName = 'SIM ' + (num + 1);
    const cartItems = this.internalGetAll();
    for (let i = 1; i < num + 1; i++) {
      let sameName = false;
      if (cartItems.find(x => x.config.friendly_name === 'SIM ' + (num + i))) {
        sameName = true;
      }

      if (!sameName) {
        friendlyName = 'SIM ' + (num + i);
        break;
      }
    }

    return friendlyName;
  }

  add(productId: string, config: ICartConfig): Observable<CartItem> {
    let cartItems: CartItem[] = this.cacheService.getObject<CartItem[]>(CACHE_CART) ?? [];

    const cartItem: CartItem = {
      id: v4(),
      productId,
      config
    };
    //TODO  use store
    cartItems.push(cartItem);
    this.cacheService.setObject(CACHE_CART, cartItems, this.configService.CART_EXPIRY);
    this.onAdded.next(cartItem);
    this.onUpdated.next(cartItems.length);
    this.cartExpiryService.startTimer();
    this.store.dispatch(new AddToCart(cartItem))
    // return this.dataLayerService.addToCart(cartItem).pipe(
    //   map(_result => {
    //     return cartItem;
    //   })
    // );

    return of(cartItem)
  }

  private internalSet(cartItems: CartItem[]) {
    this.cacheService.setObject(CACHE_CART, cartItems, this.configService.CART_EXPIRY);
  }

  getItem(id: string): Observable<CartItem> {
    let cartItems: CartItem[] = this.internalGetAll();

    return of(cartItems.filter(x => x.id == id)[0]);
  }

  getItems(): Observable<CartItem[]> {
    let cartItems: CartItem[] = this.internalGetAll();

    return of(cartItems);
  }

  getItemsSnapshot(): CartItem[] {
    let cartItems: CartItem[] = this.internalGetAll();

    return cartItems;
  }

  getMappedItems(): Observable<ICartItemMapped[]> {
    let cartItems: CartItem[] = this.internalGetAll();
    if (isEmpty(cartItems)) {
      return of([]);
    }
    const productIds = uniq(cartItems.map(x => x.productId));

    const productObservables = productIds.map(x => this.productService.getById(x));

    return forkJoin(...productObservables).pipe(
      map(productResults => {
        
        const products = productResults.filter(x => x.status == Statuses.Success).map(x => x.value);

        const mappedCartItems = cartItems.map(item => {
          const product = products.find(x => x.id == item.productId);

          if (product?.config?.subtype?.includes('nvidia') && product?.config?.rainCustomer) {
            const discountedPrice = product?.price > 1 ? product?.price * (80/100) : product?.price;
            return {
              item,
              product: {
                ...product,
                price: discountedPrice,
                recurringPrice: discountedPrice
              }
            }
          }

          return { item, product: product };
        });

        return mappedCartItems;
      })
    );
  }

  private internalGetAll() {
 
    const addons = this.store.selectSnapshot(CartState.GetAllVas).map((i) => {
      return {
        config: [],
        id: v4(),
        productId: i.id
      }
    }) as any;
    
    let cartItems: CartItem[] = [];
    if (this.cacheService.exists(CACHE_CART)) {
      cartItems = [...this.cacheService.getObject<CartItem[]>(CACHE_CART)];
      if(addons && addons.length > 0) {
        cartItems =  [...cartItems, ...addons];
      }
      if (isNil(cartItems)) {
        cartItems = [];
      }
    }
    return cartItems;
  }

  remove(cartProduct: ICartItemMapped): Observable<CartItem> {
    if(cartProduct) {
      let cartItems: CartItem[] = this.internalGetAll();
      const cartItem = cartItems.filter(x => x.id === cartProduct.item?.id)[0];
  
      if (isNil(cartItem) == false) {
        remove(cartItems, x => x.id === cartProduct.item?.id);
  
        this.internalSet(cartItems);
      }
      // this.store.dispatch(new RemoveFromCart(cartProduct?.item))
      this.onRemoved.next(cartItem);
  
      this.onUpdated.next(cartItems.length);
  
      this.cartExpiryService.startTimer();
      
      // return this.dataLayerService.removeFromCart(cartItem).pipe(
      //   map(_result => {
      //     return cartItem;
      //   })
      // );
      return of(cartItem)
    }
  }

  clear(): Observable<boolean> {
    let cartItems: CartItem[] = [];

    this.internalSet(cartItems);

    this.onCleared.next();

    this.onUpdated.next(0);

    this.cartExpiryService.stopTimer();
    this.store.dispatch(new ResetCart())
    return of(true);
  }

  update(id: string, config: ICartConfig): Observable<boolean> {
    let cartItems: CartItem[] = this.internalGetAll();

    const item = cartItems.find(x => x.id == id);

    item.config = config;

    this.internalSet(cartItems);

    this.onUpdated.next(this.getCount());

    this.cartExpiryService.startTimer();

    return of(true);
  }

  setOrderId(orderId: string) {
    this.cacheService.set(CACHE_CURRENT_ORDER_ID, orderId);
  }

  public switchPackages() {
    const isPostPaid = this.store.selectSnapshot(ServicesState.hasPostPaid);
    const upFrontPackages = this.store.selectSnapshot(ProductState.allUpfrontProducts);
    const allProducts = this.store.selectSnapshot(ProductState.allProducts);

    const cart = this.cacheService.getObject<CartItem[]>(CACHE_CART);    

    if(isPostPaid && cart) {
        const hasUpFrontProducts = cart.filter((p) => upFrontPackages.find((up) => up.id === p.productId));

        this.store.dispatch( new SwitchAccountTypes('PaymentDate'));
        if(hasUpFrontProducts.length > 0) {

          this.clear();
          hasUpFrontProducts.forEach((up) => {
            const grandfatherId = allProducts.find((p) =>p.id === up.productId).grandfather;
            const grandfather = allProducts.find((p) =>p.id === grandfatherId);

            this.add(grandfather.id, {
              [CONFIG_FRIENDLY_NAME]: '5G SIM',
              [CONFIG_COMMENTS]: null
            })
          });

        }

        
        
      // const hasUFproducts = cartItems.find((p) => p.)
    } else {
      this.store.dispatch( new SwitchAccountTypes('BillCycleDate'));
    }
  }

  public checkIfHasRainOne5G() {
  
    const allServices = this.store.selectSnapshot(ServicesState.getAllServices);

    const hasActiveRainONE = allServices?.filter((s) => {
      return RAINONE_101_5G_SIM.includes(s.productId) && s.status === ServiceStatuses.Active
    }); 

    if (hasActiveRainONE.length > 0) return true;

    const allOrders = this.store.selectSnapshot(OrderState.GetallNonCancelledOrders);
    

    let matchFound = false; 

    if (allOrders && allOrders?.length > 0) {
      const rainOnder = allOrders?.find(o => {
        o?.items?.forEach(item => {
          if (RAINONE_101_5G_SIM.includes(item.productId)) {
            matchFound = true;
          }
        });
      });
      return matchFound;
    }
  }

}
