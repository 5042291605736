import { Injectable } from '@angular/core';
import { State, Selector, Store, Action, StateContext } from '@ngxs/store';
import * as fromLoaderActions from '../actions/loader.actions';
import { UIActions } from '../actions/ui.actions';
import { colord, extend } from "colord"
import lchPlugin from "colord/plugins/lch"
import { ColorPalette } from './ui-interfaces';

extend([lchPlugin])
export type UIMode = 'consumer' | 'sme' | 'mobile';
export interface UIStateModal {
    show_loader: boolean;
    show: boolean,
    selectedSkinColor: string,
    selectedColor: string,
    mode: UIMode
}

@State<UIStateModal>({
    name: 'UI',
    defaults: {
        show_loader: false,
        show: false,
        selectedSkinColor: "blue-pattern",
        selectedColor: '#DB124B',
        mode: 'consumer'
    }
})
@Injectable()
export class UIState {

    constructor(private store: Store) { }

    @Selector()
    static isLoaderOpen(state: UIStateModal) { return state.show_loader; }

    @Selector()
    static show(state: UIStateModal) {
        return state.show
    }
    @Selector()
    static getSelectedSkinColor(state: UIStateModal) {
        return state.selectedSkinColor
    }
    @Selector()
    static getSelectedColor(state: UIStateModal) {
        return state.selectedColor
    }

    @Selector()
    static GetUIMode(state: UIStateModal): UIMode {
        return state.mode
    }

    @Selector([UIState.getSelectedColor])
    static getColorPalette(state: UIStateModal, selectedHexColor: string): ColorPalette {
        const levels : string[] = [];
        let textColor: string;

        const selectedColor = colord(selectedHexColor)

        const lchColor = selectedColor.toLch()
        const hslColor = selectedColor.toHsl()

        if (selectedColor.isDark()) {
            lchColor.l = 88
            lchColor.h = lchColor.h - 6

            const saturationDecimal = (hslColor.s / 100)

            const desaturationAmount = saturationDecimal * 0.75
            const saturationAmount = saturationDecimal < 0.1 ? saturationDecimal * 0.1 : saturationDecimal * 0.19
            const baseColor = colord(lchColor).desaturate(desaturationAmount)
    
            for (let i = 1; i < 6; i++ ) {
    
                const newColor = baseColor.saturate(saturationAmount * i).darken(0.133 * i).toLchString()
    
                levels.push(newColor)
            }
            textColor = baseColor.saturate(saturationAmount * 4).darken(0.135 * 4).toLchString()
        } else {
            lchColor.l = 80
            lchColor.h = lchColor.h - 2

            const saturationDecimal = (hslColor.s / 100)

            const desaturationAmount = saturationDecimal * 0.69
            const saturationAmount = saturationDecimal * 0.3
            const baseColor = colord(lchColor).desaturate(desaturationAmount)
    
            for (let i = 1; i < 6; i++ ) {
    
                const newColor = baseColor.saturate(saturationAmount * i).darken(0.05 * i).toLchString()
    
                levels.push(newColor)
            }
            textColor = baseColor.saturate(saturationAmount * 4).darken(0.05 * 4).toLchString()
        }

        return {levels, textColor}
    }

    @Action(fromLoaderActions.ShowLoader)
    showLoader(ctx: StateContext<UIStateModal>, action: fromLoaderActions.HideLoader) {
        ctx.patchState({
            show_loader: true
        })
    }

    @Action(fromLoaderActions.HideLoader)
    hideLoader(ctx: StateContext<UIStateModal>, action: fromLoaderActions.HideLoader) {
        ctx.patchState({
            show_loader: false
        })
    }

    @Action(UIActions.ToggleValue)
    toggleValue(ctx: StateContext<UIStateModal>, action: UIActions.ToggleValue) {
        const { show } = action;

        ctx.patchState({ show });

    }
    @Action(UIActions.SetSelectedSkinColor)
    setSelectedSkinColor(ctx: StateContext<UIStateModal>, action: UIActions.SetSelectedSkinColor) {
    const { skin } = action;

        ctx.patchState({ selectedSkinColor: skin });

    }
    @Action(UIActions.SetSelectedColor)
    setSelectedColor(ctx: StateContext<UIStateModal>, action: UIActions.SetSelectedColor) {
        const { color } = action;

        ctx.patchState({ selectedColor: color });

    }

    @Action(UIActions.SwitchUIMode)
    SwitchUIMode(ctx: StateContext<UIStateModal>, action: UIActions.SwitchUIMode) {
        const {mode} = action.payload;

        ctx.patchState({ mode });

    }
}