import { isEmpty, isNil } from "lodash";
import moment from 'moment';
import { IDictionary } from './dictionary';
import { ModelHelper } from "./modelHelper";
import { IProductDetail } from './productDetail';
import { IRemoteScheduledAction, IRemoteService, IRemoteServiceAddon } from "./remote/remoteService";
import { IRemoteServiceOrderDetails } from "./remote/remoteServiceOrderDetails";
import { IRemoteServiceOrderRequest } from "./remote/remoteServiceOrderRequest";
import { IServiceOrderDetails } from "./serviceOrderDetails";
import { IServiceOrderRequest } from "./serviceOrderRequest";



export enum ServiceStatuses {
  Active = 0,
  Unknown,
  PreProvisioned,
  Deactivated,
  FailedProvisioning,
  Suspended,
  Canceled
}

export class ServiceDetail {

  public static getSpendLimitFromTo(service: IServiceDetail, products: IProductDetail[]) {

    let spendLimitFrom = isNil(service.spendLimitAvailable) == false ? service.spendLimitAvailable : 0;

    let spendLimitTo = isNil(service.spendLimitInitial) == false ? service.spendLimitInitial : 0;

    return { spendLimitFrom, spendLimitTo }
  }

  static adapt(remote: IRemoteService): IServiceDetail {
    let scheduledAction: IScheduledActionDetail = null;
    let addOns: IServiceDetailAddOn[] = [];

    if (remote?.scheduled_action) {
      scheduledAction = ScheduledActionDetail.adapt(remote?.scheduled_action as any);
    }

    if (isNil(remote.addons) == false) {
      addOns = remote.addons.map(x => ServiceDetailAddOn.adapt(x));
    }

    return {
      additional_balances: remote.additional_balances,
      productId: remote.product_id,
      id: remote.id,
      internationalDialing: remote.international_dialling,
      msisdn: remote.service_reference,
      spendLimit: remote.spend_limit,
      iccid: remote.associated_resource,
      expiryDate: ModelHelper.toDate(remote.expiry_date),
      spendLimitInitial: ModelHelper.toFloat(remote.spend_limit_initial),
      spendLimitAvailable: ModelHelper.toFloat(remote.spend_limit_available),
      spendLimitConsumed: ModelHelper.toFloat(remote.spend_limit_consumed),
      peakUsage: ModelHelper.toFloat(remote.peak_usage),
      offpeakUsage: ModelHelper.toFloat(remote.offpeak_usage),
      name: remote.name,
      statusCode: remote.status,
      status: ServiceDetail.mapStatus(remote.status),
      imei: null,
      manufacturer: null,
      model: null,
      productName: null,
      addons: addOns,
      scheduledAction,
      metadata: isNil(remote.meta) ? {} : remote.meta,
      activationDate: ModelHelper.toDate(remote.activation_date),
      uopMrc: ModelHelper.toFloat(remote.uop_mrc),
      chip_in_order: remote.chip_in_order || null,
      fiveG_migration_order: remote.fiveG_migration_order || null,
      available_balance: remote.available_balance || null,
      balance_at_last_top_up: remote.balance_at_last_top_up || null,
      available_volume: remote.available_volume || null,
      bundle_expiry: remote.bundle_expiry || null,
      last_topup_date: remote.last_topup_date || null,
      last_topup_volume: remote.last_topup_volume || null,
      total_topup_volume: remote.total_topup_volume || null,
      total_usage: remote.total_usage || null,
      orderDetails: this.mapOrderDetails(remote.order_details),
      orderRequests: this.mapOrderRequests(remote.order_requests),
      data_available_volume: remote.data_available_volume,
      data_last_topup_volume: remote.data_last_topup_volume,                   ///GIGS
      data_last_topup_date: remote.data_last_topup_date,
      data_expiry_date: remote.data_expiry_date,
      parent_service_id: remote.parent_service_id,
      pending_migration: remote?.pending_migration,
      assigned_by: remote?.assigned_by || null
    };
  }
  static mapOrderDetails(order_details: IRemoteServiceOrderDetails) {

    const orderDate = order_details.order_date;
    const orderId = order_details.order_id
    return { orderDate, orderId }
  }


  static mapOrderRequests(order_requests: IRemoteServiceOrderRequest[]) {
    return order_requests
  }

  public static mapStatus(status: number): ServiceStatuses {
    switch (status) {
      case 0: return ServiceStatuses.Active;
      case 100: return ServiceStatuses.PreProvisioned;
      case 200: return ServiceStatuses.Deactivated;
      case 300: return ServiceStatuses.FailedProvisioning;
      case 400: return ServiceStatuses.Suspended;
      case 900: return ServiceStatuses.Canceled;

      default: return ServiceStatuses.Unknown;
    }
  }
}

export class ScheduledActionDetail {
  static adapt(scheduled_action: IRemoteScheduledAction[]): IScheduledActionDetail {
    if (scheduled_action.length === 0) return null;

    let sa = scheduled_action?.[0];
    let type = ScheduledActionTypes.None;

    if (
      isNil(sa?.action_type) == false &&
      isEmpty(sa?.action_type) == false
    ) {
      type = ScheduledActionTypes[sa?.action_type];

      if (isNil(type)) {
        type = ScheduledActionTypes.None;
      }
    }

    return {
      name: sa?.offer_name,
      type,
      actionName: sa?.action_type,
      date: ModelHelper.toDate(sa?.action_date),
      futureOrderId: sa?.future_order_id,
    };
  }

}

export interface IScheduledActionDetail {
  name: string;
  type: ScheduledActionTypes;
  date: Date;
  actionName: string;
  futureOrderId: string;
}

export enum ScheduledActionTypes {
  None = 0,
  Cancellation,
  Change,
}

export class ServiceDetailAddOn {
  static adapt(remoteServiceAddon: IRemoteServiceAddon): IServiceDetailAddOn {

    const effectiveDate = ModelHelper.toDate(remoteServiceAddon.effective_date);

    const expiryDate = ModelHelper.toDate(remoteServiceAddon.expiry_date);

    const isActive = moment(effectiveDate).isBefore(moment()) && (moment(moment()).isBefore(expiryDate) || isNil(expiryDate));

    return {
      id: remoteServiceAddon.id,
      productName: null,
      effectiveDate,
      expiryDate,
      isActive
    };
  }

}

export interface IServiceDetailAddOn {
  id: string;
  productName: string;
  expiryDate: Date;
  effectiveDate: Date;
  isActive: boolean
}

export interface TrnObject {
  amount: number;
  inserted_at: string;
  name: string;
  status: string;
}

export interface RulesObj {
  auto_refill: {
    available: number;
    expiry: string;
    mode: string;
  }
}

export interface MetaObj {
  amount_at_last_topup?: number;
  amount_of_last_topup?: number;
  date_of_last_topup?: string;
  expiry?: string | null;
  trn: TrnObject[];
}

export interface SubBalance {
    available: number;
    meta: MetaObj;
    rules: RulesObj[];
    types: 'COUNTER';
}

export interface AdditionalBalance {
  free_data: SubBalance;
  voice_sub: SubBalance;
  sms_sub: SubBalance; 
  data_sub: SubBalance;
  voice0: SubBalance;
  sms0: SubBalance;
  data0: SubBalance;
}

export interface IServiceGroup {
  isParentSvc: boolean;
  serviceObject: IServiceDetail;
  subServices: { [id: string]: IServiceDetail };
  category: '5G' | '4G' | 'nvidia';
  isLegacy: boolean;
  isPrepaid: boolean;
}

export interface IServiceDetail {
  id: string;
  internationalDialing: string
  name: string;
  additional_balances: any;
  statusCode: number;
  status: ServiceStatuses;
  imei: string;
  imsi?: string;
  manufacturer: string;
  model: string;
  msisdn: string;
  spendLimitAvailable: number;
  spendLimitConsumed: number;
  spendLimitInitial: number;
  peakUsage: number;
  offpeakUsage: number;
  spendLimit: boolean;
  expiryDate: Date;
  productName: string;
  productId: string;
  iccid: string;
  addons: IServiceDetailAddOn[];
  scheduledAction: IScheduledActionDetail;
  metadata: IDictionary<{ [key: string]: any }>;
  activationDate: Date;
  uopMrc: number;
  fiveG_migration_order?: string;
  chip_in_order?: string;
  available_balance?: number,
  balance_at_last_top_up: number
  available_volume?: number,
  bundle_expiry?: string,
  last_topup_date?: string,
  last_topup_volume?: number | string,
  total_topup_volume?: number | string,
  total_usage: string,
  orderDetails: IServiceOrderDetails,
  orderRequests: IServiceOrderRequest[]
  connectedTowerStatus?: string;
  data_available_volume: number,
  data_last_topup_volume: number;                   ///GIGS
  data_last_topup_date: string,
  data_expiry_date: string,
  product?: IProductDetail,
  parent_service_id: null | string,
  pending_migration: boolean,
  config?: {
    displayName?: string
  };
  assigned_by?: string;
}

export interface ServicePolicyRemote {
  iccid: string
  msisdn: string
  name: string
  policy: string
  product_id: string
  service_id: string
  status: number
}

export interface ServicePolicy {
  iccid: string
  msisdn: string
  name: string
  policy: string
  productId: string
  serviceId: string
  status: number
}

export class ServicePolicyResponse {
  static adapt(remote: ServicePolicyRemote): ServicePolicy {
    return {
      iccid: remote.iccid,
      msisdn: remote.msisdn,
      name: remote.name,
      policy: remote.policy,
      productId: remote.product_id,
      serviceId: remote.service_id,
      status: remote.status
    }
  }
}