import { Component, OnInit, Output, EventEmitter, AfterContentInit, OnDestroy, Input } from '@angular/core';
import { LimitSalesTicketCreationState } from '@components/sales-modal/store/state/limit-ticket-creation.state';
import { Select, Store } from '@ngxs/store';
import { Power4, gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Observable, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { RainFlags } from 'src/app/core/interfaces/rain-flags.interface';
import { FetchRainFlags } from 'src/app/core/store/actions/firebase.actions';
import { FirebaseConfigsState } from 'src/app/core/store/state/firebase-configs.state';
import { UIState } from 'src/app/shared/store/state/ui.state';

@Component({
  selector: 'rain-sticky-nav',
  templateUrl: './sticky-nav.component.html',
  styleUrls: ['./sticky-nav.component.scss']
})
export class StickyNavComponent implements OnInit, AfterContentInit, OnDestroy {
  @Select(LimitSalesTicketCreationState.isTicketCreationAllowed) isTicketCreationAllowed$: Observable<boolean>;
  @Select(FirebaseConfigsState.getFlags) flags$: Observable<RainFlags>;
  @Select(UIState.GetUIMode) selectedUiMode$ : Observable<string>;

  @Output() buyTriggered = new EventEmitter<boolean>();
  @Output() requestSalesCallTriggered = new EventEmitter<boolean>();
  @Output() playVideoTrigger = new EventEmitter<boolean>();
  @Input() animate: boolean = true;

  private documentWidth: number;

  private flags =  new Subscription();
  private ngDestroy: Subject<any> = new Subject();
  public buttonText: string = "play rain | SA20";

  constructor(private store: Store) { }

  ngOnInit(): void {

        this.store.dispatch(new FetchRainFlags());

        this.flags$
        .pipe(takeUntil(this.ngDestroy))
        .subscribe({
          next: (res) => {
            if(res) {
              if(!res.sa_t20_promo) this.buttonText = "see it in colour";
            }
          }
        })
  }

  ngAfterContentInit(): void {
    this.documentWidth = window.innerWidth;

    const timeline = gsap.timeline({ delay: 0.5 });

    if (this.animate) {
      timeline
      .fromTo('.rain-101-sticky-nav', 2, { scale: 0, opacity: 0 }, { scale: 1, opacity: 1, ease: Power4.easeInOut })
      .fromTo('.button1', 0.8, { opacity: 0, scale:0 }, { opacity:1, scale:1, ease: Power4.easeInOut },'-=0.8')
      .fromTo('.button2', 0.8, { scale: 0.3, opacity: 0 }, { opacity:1, scale: 1, ease: Power4.easeInOut }, '-=0.8')
      .fromTo('.button3', 0.8, { scale: 0.3, opacity: 0 }, { opacity:1, scale: 1, ease: Power4.easeInOut }, '-=0.8')
      
      if (this.documentWidth > 768) {
        timeline.fromTo('.button1 .copy-1', 0.8, { width: 1, opacity: 0 }, { width: 120, opacity: 1, ease: Power4.easeInOut })
        .fromTo('.icon-youtube', 1, { scale: 0, opacity: 0, width: '1px' }, { width:'25px', scale: 1, opacity: 1, ease: Power4.easeInOut },'-=0.4')
      .fromTo('.button2 .copy', 0.8, { width: 1, opacity: 0 }, { width: 130, opacity: 1, ease: Power4.easeInOut }, '-=0.8')
      .fromTo('.button3 .copy', 0.8, { width: 1, opacity: 0 }, { width: 100, opacity: 1, ease: Power4.easeInOut }, '-=0.8')
      }

    if (this.documentWidth > 768) {
      gsap.registerPlugin(ScrollTrigger);

      gsap.to('.sticky-text-3', 0.5, {
        scrollTrigger: {
          trigger: '.rain-101-for-phones',
          pin: false,
          start: 'top top',
          end: '120vh',
          toggleActions: 'play none none reverse',

        },
        display: 'inline-block',
        fontWeight: 'bold',
        ease: Power4.easeInOut
      });


      gsap.to('.sticky-text-2', 0.5, {
        scrollTrigger: {
          trigger: '#home',
          start: 'top top',
          end: 'bottom bottom',
          toggleActions: 'play none none reverse',
        },
        display: 'inline-block',
        fontWeight: 'bold',
        ease: Power4.easeInOut
      });
    }
    } else {

      gsap.set('.button1 .copy-1', {width: 120, opacity: 1});
      gsap.set('.button2 .copy', {width: "100%", opacity: 1});
      gsap.set('.button3 .copy', {width: "100%", opacity: 1});
    }
  }

  requestSalesCallFrom() {
    this.requestSalesCallTriggered.emit(true);
  }

  addToCartFromSticky() {


    this.flags = this.store.select(FirebaseConfigsState.getFlags)
    .pipe(take(1))
    .subscribe(result => {
          if(result && result.disable_purchase) return this.requestSalesCallTriggered.emit(true);
          this.buyTriggered.emit(true);
    }) ;
  
  }

  playVideo() {
    this.playVideoTrigger.emit(true);
  }

  ngOnDestroy(): void {
    this.flags.unsubscribe();
    this.ngDestroy.next(null);
    this.ngDestroy.complete();
  }

}
