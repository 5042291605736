import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { BillingState } from 'src/app/core/store/state/billing.state';
import { PaymentStatusItem } from 'src/app/core/store/types/payment-status-item.interface';
import { PAYMENT_STATUS_MAPPING } from 'src/app/core/store/types/payment-status-mapping.constant';

@Component({
  selector: 'rain-payment-error-block',
  templateUrl: './payment-error-block.component.html',
  styleUrls: ['./payment-error-block.component.scss']
})
export class PaymentErrorBlockComponent implements OnInit {

  @Input() manage: boolean = false;

  constructor(private store: Store) { }

  error: PaymentStatusItem = null;

  ngOnInit(): void {
    const { status } = this.store.selectSnapshot(BillingState.GetLastFailedPayment)?.data ?? {};

    if (status) {
      this.error = PAYMENT_STATUS_MAPPING?.[status]
    };
  }

}
