<!-- <div class="container-fluid">
  <div class="bg-our-network">
    <div class="container">
      <h1 class="text-white">4G mobiles</h1>
    </div>
  </div>
</div> -->
<!-- <div class="header-container">
  <img class="img-item" src="./assets/bg/people-4g-rain.png" alt="" />
</div> -->
<div class="container">
  <div class="row">
    <div class="col p-10">
      <div class="copy spacer2 ">
        <span class="header-text-4g"><b>4G mobile</b></span>
        <h2 class="text-primary font-normal py-6">how do I get my phone number?</h2>
        <ul>
          <li>Once you’ve put your rain 4G mobile phone SIM into your phone, you’ll receive an sms with your new number. </li>
          <li>If you don’t receive the sms, you’ll also find your number on your <a [routerLink]="'/my-rain'" class="text-primary">myrain</a> page. </li>
          <li>To keep your old number, go to port number on your <a [routerLink]="'/my-rain'" class="text-primary">myrain</a> page.</li>
        </ul>
        <h2 class="text-primary font-normal py-6">how do I check my balance & buy more?</h2>
        <ul>
          <li>You can either visit my<b>rain</b> to manage your account or SMS 'balance' to <span class="text-font-weight">136.</span></li>
          <li>If you run out of your FREE mobile data, minutes & sms, you can buy more on the go at <a (click)="openRainGo()" class="text-primary" target="_blank"><span>rain<strong>GO</strong></span></a></li>
          <li>These ‘buy more’ purchases are once-off and they never expire.</li>
          <!-- <li>Buy more at <span class="text-primary">R50 a gig, R1 a minute</span> PLUS you unlock extra FREE when you level up. </li> -->
        </ul>
        <h2 class="text-primary font-normal py-6">rain's mobile network is 4G only with VoLTE for voice calls.</h2>
        <ul>
          <li>VoLTE is a technology that allows voice calls to be transmitted over a 4G/LTE network instead of the older 2G or 3G networks.</li>
          <li>It allows you to make HD calls.</li>
          <li>Your calls connect faster.</li>
          <li>It uses less battery.</li>
        </ul>
        <h2 class="text-primary font-normal py-6">does my phone have VoLTE?</h2>
        <ul>
          <li>Most modern 4G/LTE smartphones support VoLTE.</li>
          <li>Check that your software has been updated.</li>
          <li>You may also need to activate VoLTE in your phone settings.</li>
        </ul>
        <h2 class="text-primary font-normal py-6">how do I activate VoLTE?</h2>
        <p>
          Here are the general steps to enable VoLTE, you will need to go to your device's settings menu and select "Mobile Networks" or 
a similar option.
        </p>
        <div class="row">
          <div class="col col-xs-12 m-xs-20">
            <div class="card p-10">
              <div class="card-title text-primary">
                <div class="d-flex justify-content-start">
                  <span class="icon icon-huawei">
                    <img src="./assets/icons/settings-page/icon-huwaie.svg" alt="" />
                  </span>
                  <span class="icon-text font-weight-bold">Huawei</span>
                </div>
              </div>
              <div class="card-body">
                <div class="li-wrapper d-flex">
                  <div class="li-number"><span>1</span></div>
                  <div class="li-text">go to settings</div>
                </div>
                <div class="li-wrapper d-flex">
                  <div class="li-number"><span>2</span></div>
                  <div class="li-text">mobile network</div>
                </div>
                <div class="li-wrapper d-flex">
                  <div class="li-number"><span>3</span></div>
                  <div class="li-text">mobile data</div>
                </div>
                <div class="li-wrapper d-flex">
                  <div class="li-number"><span>4</span></div>
                  <div class="li-text">enable VoLTE or 4G</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col col-xs-12 m-xs-20">
            <div class="card p-10">
              <div class="card-title text-primary">
                <div class="d-flex justify-content-start">
                  <span class="icon icon-huawei">
                    <img src="./assets/icons/settings-page/icon-android.svg" alt="" />
                  </span>
                  <span class="icon-text font-weight-bold">Android</span>
                </div>
              </div>
              <div class="card-body">
                <div class="li-wrapper d-flex">
                  <div class="li-number"><span>1</span></div>
                  <div class="li-text">go to settings</div>
                </div>
                <div class="li-wrapper d-flex">
                  <div class="li-number"><span>2</span></div>
                  <div class="li-text">connections</div>
                </div>
                <div class="li-wrapper d-flex">
                  <div class="li-number"><span>3</span></div>
                  <div class="li-text">mobile networks</div>
                </div>
                <div class="li-wrapper d-flex">
                  <div class="li-number"><span>4</span></div>
                  <div class="li-text">enable VoLTE or 4G</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col col-xs-12 m-xs-20">
            <div class="card p-10">
              <div class="card-title text-primary">
                <div class="d-flex justify-content-start">
                  <span class="icon icon-huawei">
                    <img src="./assets/icons/settings-page/icon-apple.svg" alt="" />
                  </span>
                  <span class="icon-text font-weight-bold">iOS</span>
                </div>
              </div>
              <div class="card-body">
                <div class="li-wrapper d-flex">
                  <div class="li-number"><span>1</span></div>
                  <div class="li-text">go to settings</div>
                </div>
                <div class="li-wrapper d-flex">
                  <div class="li-number"><span>2</span></div>
                  <div class="li-text">mobile data</div>
                </div>
                <div class="li-wrapper d-flex">
                  <div class="li-number"><span>3</span></div>
                  <div class="li-text">mobile data options</div>
                </div>
                <div class="li-wrapper d-flex">
                  <div class="li-number"><span>4</span></div>
                  <div class="li-text">voice & data</div>
                </div>
                <div class="li-wrapper d-flex">
                  <div class="li-number"><span>5</span></div>
                  <div class="li-text">enable VoLTE</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2 class="text-primary font-normal py-10">what if my phone isn't VoLTE compatible ?</h2>
        <p>
          No problem! Just download the <span><img src="assets/images/rain-talk.png" alt=""/></span> app. It’s just like your phone’s built-in phone
          app, and it lets you make HD calls.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid bg-lightblue">
 
      <div class="container">
        <div class="row">
          <div class="col order-1 c-order-xs-2 col-md-2 py-20 text-center text-primary">
            <div class="hide-mobile">
              <img class="rain-talk-icon" src="assets/icons/Raintalk_icon.svg" alt="" />
              <p class="text-center pt-5 fw-bold">
                Download from<br />
                your app store.
              </p>
            </div>
            <div class="hide-desktop mb-10 mb-custom-mobi">
              <div class="d-inline-flex align-items-center">
                <div>
                  <img class="rain-talk-icon" src="assets/icons/Raintalk_icon.svg" alt="" />
                  <h2 class="text-center pt-0 pl-10 mb-2">
                    Download from your app store.
                  </h2>
                </div>
                <div>

                  <div class="d-flex align-items-center justify-content-start pl-10 c-order-xs-3 d-flex-md-12">
                    <div class="icon-app ">
                      <a href="https://play.google.com/store/apps/details?id=za.co.rain.raintalk" target="_blank">
                        <img src="assets/icons/settings-page/Google_Play.svg" alt="" />
                      </a>
                      </div>
                      <div class="icon-app">
                      <a href="https://appgallery.huawei.com/#/app/C107430725" target="_blank">
                        <img src="assets/icons/settings-page/App_Gallery.svg" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <div class="col order-2 c-order-xs-1 col-md-10 text-primary py-20 px-10">
            <h2>
              get HD voice calls with raintalk!</h2>
            <p>The raintalk app is exclusive to rain mobile users.​</p>
              <p>rain's national 4G network doesn't rely on outdated 2G or 3G technology, enabling rainmobile customers to enjoy high definition voice calls through VoLTE (voice over LTE) technology. If your mobile device isn’t compatible with VoLTE, you can still enjoy HD calls by downloading the raintalk app. The raintalk app is currently unavailable on iOS. Most iPhones support VoLTE.</p>
            <div class="hide-mobile">
              <div class="d-flex c-order-xs-3 d-flex-md-12">
                <div class="icon-app ">
                  <a href="https://play.google.com/store/apps/details?id=za.co.rain.raintalk" target="_blank">
                    <img src="assets/icons/settings-page/Google_Play.svg" alt="" />
                  </a>
                </div>
                <div class="icon-app">
                  <a href="https://appgallery.huawei.com/#/app/C107430725" target="_blank">
                    <img src="assets/icons/settings-page/App_Gallery.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
</div>
