import { IServiceDetail, ServiceDetail, ServiceStatuses } from "@models/serviceDetail";
import { IProductDetail } from "@models/productDetail";
import { CONFIG_DATA_PLAN, CONFIG_SIM_TYPE, CONFIG_SPEND_LIMIT, RAIN_ONE_BASE_IDS, SERVICE_META_DATA_CHARACTERISTICS, SIM_TYPE_VIRTUAL } from "@models/constants";
import { get, isEmpty, isNil, sortBy } from "lodash";
import { ConfigService } from "@services/config.service";
import moment from "moment";
import { ModelHelper } from "@models/modelHelper";
import { AssignProductType } from "src/app/core/store/actions/service-connection.actions";
import { Store } from "@ngxs/store";
import { UserService } from "@services/user.service";
import { CacheService } from "@services/cache.service";
import { Injectable } from "@angular/core";
import { ProductState } from "../store/state/product.state";


const CONSTANTS = {
    PREPAID_PRODUCT_ID: 'a911af35-30d0-4e0d-a407-633d27ad8f3a',
    CUSTOMER_A_PRODUCT_ID: '89dd4305-8f41-4d86-a097-416e1046e865',
    CUSTOMER_B_PRODUCT_ID: '12bf79dc-f05b-46cf-a91c-d28b79ed84e1',
}

@Injectable({
    providedIn: 'root'
})

export class TransformDataService {

    services: any[]
    productsMap
        
    constructor(private store: Store, private userService: UserService, private cacheService: CacheService, private configService: ConfigService) {}

    public inputServicesData(services: IServiceDetail[]){
        this.services = services
        this.productsMap = this.store.selectSnapshot(ProductState?.allProductsMap);
        return this
    }

    public filterActiveAndSuspendedServices(){
        this.services = this.services.filter((service) => service?.status === ServiceStatuses?.Active || service?.status === ServiceStatuses?.Suspended)
        this.services = sortBy(this.services, service => service?.name)
        return this
    }

    public appendRainOneDetails(){
        const userHasRainOneProduct = this.services?.find((service) => service?.productName?.toLowerCase().includes('rainone'))

        this.services = this.services?.map((service) => {
            const displayName = this.productsMap?.[service?.productId]?.name
            const isRainOne = service?.productName?.toLowerCase()?.includes('rainone')
            const canMigrate = !!(!service?.pending_migration && 
                this.productsMap[service.productId]?.config?.migration?.find((config) =>  RAIN_ONE_BASE_IDS.includes(config?.id))  && 
                !userHasRainOneProduct)
            return {...service, displayName, isRainOne, canMigrate}
        })
        return this
    }
    
    public appendProductDetail(){
        this.services?.forEach((service) => {
            service.product = this.productsMap[service?.productId]
        })
        return this
    }

    public assignProductType(){
        this.services?.forEach(service => {
            if (service?.productName?.toLocaleLowerCase().includes('5g')) this.store.dispatch(new AssignProductType({ name: service?.name, type: '5G' }));
            if (service?.productName?.toLocaleLowerCase().includes('4g')) this.store.dispatch(new AssignProductType({ name: service?.name, type: '4G' }));
        })
        return this
    }
    public assign241ProductDetail(){
        const redeemedService = this.services?.find(s => s?.productId === CONSTANTS.CUSTOMER_B_PRODUCT_ID); // check 2-for-1
        const hasTwoForOnePomo = Boolean(redeemedService); // check 2-for-1
        const primaryService = this.services?.find(s => s?.productId === CONSTANTS.CUSTOMER_A_PRODUCT_ID); // check 2-for-1
        const primaryServiceId = primaryService?.id; // check 2-for-1
        let hasAssosiatedProduct;
        let _2_4_1OrderLineId;

        if (hasTwoForOnePomo) {
            sessionStorage.setItem('has_241_promo', 'true');
            }
            if (redeemedService?.id || primaryServiceId) {
                if (redeemedService?.id) {
                    _2_4_1OrderLineId = redeemedService?.metadata?.payload?.sales_order_line_id;
                } else {
                    _2_4_1OrderLineId = primaryService?.metadata?.payload?.sales_order_line_id;
                }
            this.userService.getAssociatedPromoService(_2_4_1OrderLineId).subscribe(serviceResult => {
                const associatedProduct = serviceResult?.value ?? null;
                hasAssosiatedProduct = Boolean(associatedProduct);
                if (hasAssosiatedProduct) {
                this.cacheService.setObject('associated241Service', associatedProduct);
                }
            });
            }
        return this
    }

    public appendSpendLimitDetail(){
        this.services?.forEach((service) => {
            if (!isNil(service.product)) {
                const { spendLimitFrom, spendLimitTo } = ServiceDetail.getSpendLimitFromTo(service, []);
                service.spendLimitFrom = spendLimitFrom ?? null;
                service.spendLimitTo = spendLimitTo ?? null;
                service.hasSpendLimit = (spendLimitFrom || spendLimitTo)
                
                this.appendVirtualSimActivatedDetail(service)

            } else {
                service.virtual_sim_activated = null
            }
        })
        return this
    }

    private appendVirtualSimActivatedDetail = (service) => {
        if (service?.product?.config &&
            service?.product?.config[CONFIG_SIM_TYPE] &&
            service?.product?.config[CONFIG_SIM_TYPE]?.value === SIM_TYPE_VIRTUAL &&
            !isNil(service?.metadata[SERVICE_META_DATA_CHARACTERISTICS]) &&
            !isNil(service?.metadata[SERVICE_META_DATA_CHARACTERISTICS]?.qr_code)
        ) {
            service.virtual_sim_activated = false
        } else {
            service.virtual_sim_activated = true
            service.hasSpendLimit = get(service, `product.config.${CONFIG_SPEND_LIMIT}`, false)
            this.appendAddonDetail(service)
        }
    }

    private appendAddonDetail = (service) => {
        const serviceProductHasAddons = !isEmpty(service?.product?.addons)
        const serviceHasAddons = !isEmpty(service?.addons)

        if (!serviceProductHasAddons && !serviceHasAddons) {
            service.dataPlan = isNil(service?.product?.config[CONFIG_DATA_PLAN]) ? '' : service?.product?.config[CONFIG_DATA_PLAN]?.value;
        }

        if (serviceProductHasAddons && serviceHasAddons) {
            service?.addons?.forEach(addon => {
                const addonProduct = this.productsMap[addon.id];

                if (isNil(addonProduct) === false &&
                    isNil(addonProduct?.config) === false &&
                    isNil(addonProduct?.config[CONFIG_DATA_PLAN]) === false
                ) { service.dataPlan = addonProduct?.config[CONFIG_DATA_PLAN]?.value }

                service.isUOP = isNil(addonProduct) === false && addonProduct?.id === this.configService?.UOP_ADDON_ID;
            });
        }
    } 

    filterPrepaidServices = () => {
        const prepaidService = this.services?.find(s => s.productId === CONSTANTS?.PREPAID_PRODUCT_ID)
        if (Boolean(prepaidService)) {
            prepaidService.prepaidHasExpired = this.calculatePrepaidDays(prepaidService)

            return {
                hasPrepaidService: true,
                prepaidService: prepaidService ?? {},
            }
        } else {
            return {
                hasPrepaidService: false,
                prepaidService: null,
            }
        }
    }

    private calculatePrepaidDays(prepaidService) {
        const remainingDays = Math.floor(moment.duration(moment(ModelHelper.toDate(prepaidService?.bundle_expiry)).diff(moment())).asDays());
        return remainingDays < 0;
        }
}
