import { IProductDetail, ProductViewModel } from "@models/productDetail"
import { RainOneProduct } from "src/app/core/interfaces/rain-one-products.interface";

export class GetAllProducts{
    public static type ="[PRODUCTS] GetAllProducts"
}

export class SetPostPaidFiveGProducts{
    public static type ="[PRODUCTS] Set Postpaid FiveG products"
    constructor(public activeProducts:IProductDetail[]){}
}

export class SetUpfrontFiveGProducts{
    public static type ="[PRODUCTS] Set Upfront FiveG products"
    constructor(public activeProducts:IProductDetail[]){}
}

export class SetPostPaidFourGProducts{
    public static type ="[PRODUCTS] Set PostPaid FiveG products";
    constructor(public activeProducts:IProductDetail[]){}
}

export class SetUpfrontFourGProducts{
    public static type ="[PRODUCTS] Set Upfront FourG products";
    constructor(public allProducts:IProductDetail[]){}
}

export class SetStandAlone4GAUProducts {
    public static type ="[PRODUCTS] Set stand alone 4G AU products";
    constructor(public allProducts:IProductDetail[]){}
}

export class SetUpfrontICCIDProducts{
    public static type ="[PRODUCTS]  Set Upfront ICCID Products";
    constructor(public allProducts:IProductDetail[]){}
}

export class SetBlackFridayProducts{
    public static type ="[PRODUCTS]  Set  Black Friday Products";
    constructor(public products:any){}
}

export class SetPostPaidICCIDProducts{
    public static type ="[PRODUCTS] Set Post Paid ICCID Products";
    constructor(public allProducts:IProductDetail[]){}
}
export class PrepareProductsForDisplay{
    public static type ="[PRODUCTS] Prepare Products For Display";
    constructor(public products:IProductDetail[], public dictionaryKey: string,public category: string){}
}

export class GetActiveProducts{
    public static type ="[PRODUCTS] Get Active Products";
}

export class SetSimDeliveryOptions{
    public static type ="[PRODUCTS] Set Sim Delivery Options";
    constructor(public allProducts: ProductViewModel[]){}
}

export class FetchProductsFromCatelogue {
    public static type ="[PRODUCTS: Fetch] Fetch products from product catelogue";
}
export class FetchProductsFromCatelogueSuccess {
    public static type ="[PRODUCTS: Fetch] Fetch products from product catelogue success";
    constructor(public readonly payload: Array<RainOneProduct>) {}
}
export class FetchProductsFromCatelogueFail {
    public static type ="[PRODUCTS: Fetch] Fetch products from product catelogue fail";
    constructor(public readonly payload: any) {}
}





