import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './footer/footer.component';
import { HeaderModule } from 'src/app/layout/header/header.module';
import { NavModalComponent } from './nav-modal/nav-modal.component';
import { LoginModule } from '@components/login/login.module';
import { AgreeCookiesModule } from '@components/agree-cookies/agree-cookies.module';
import { ConfirmSignOutModalComponent } from '@components/confirm-sign-out-modal/confirm-sign-out-modal.component';



@NgModule({
  declarations: [
    FooterComponent,
    NavModalComponent,
    ConfirmSignOutModalComponent
  ],
  imports: [
    CommonModule,
    HeaderModule,
    RouterModule,
    LoginModule,
    AgreeCookiesModule
  ],
  exports: [
    FooterComponent,
    HeaderModule,
    NavModalComponent
  ]
})
export class LayoutModule { }
