<ng-container *ngIf="(selectedService$ | async)  as selectedService">

    <div class="cancel-tile-container" *ngIf="selectedService?.productName.includes('5G') ||  selectedService?.productName.includes( 'rainOne')  && (!selectedService?.productName.includes( 'rainOne 4G')  && !selectedService?.productName.includes( '4G'))" >
        <div class="cancel-tile-top">
            <h2 *ngIf="selectedService?.scheduledAction?.actionName !== 'Cancellation'">thinking of <span class="bold-text"> cancelling?</span></h2>
            <h2 *ngIf="selectedService?.scheduledAction?.actionName === 'Cancellation'">cancellation <span class="bold-text"> pending </span></h2>
    
            <div class="cancel-tile-content">

                <!-- 5g legacy product    -->
                <!-- cancellation button off -->
                <p *ngIf="hideServiceCancellation &&  legacyFiveG &&  selectedService?.scheduledAction?.actionName !== 'Cancellation'">We really hope not. But if you decide to go, call us on 081 610 1000, select option 5 and then option 4.</p>
                <!-- cancellation button on -->
                <p *ngIf="!hideServiceCancellation && legacyFiveG &&  selectedService?.scheduledAction?.actionName !== 'Cancellation'">We really hope not. But if you decide to go, click the button below to request a cancellation.</p>
                <!-- 5g legacy product    -->
                

                <!-- 5g  non legacy product    -->
                <p *ngIf="!legacyFiveG &&  selectedService?.scheduledAction?.actionName !== 'Cancellation'">We really hope not. But if you do decide to go, please note that canceling rain<span
                    class="bold-text">one</span> will also mean the cancellation of your 4G mobile SIMs.</p>
                
                <!-- cancellation button off -->
                <p *ngIf="!legacyFiveG && hideServiceCancellation && selectedService?.scheduledAction?.actionName !== 'Cancellation'">To cancel your rain<span
                    class="bold-text">one</span>, please call us on 081 610 1000, select option 5 and then option 4.</p>

                <p *ngIf="!legacyFiveG && !hideServiceCancellation && selectedService?.scheduledAction?.actionName !== 'Cancellation'">Click the button below to request a cancellation.</p>
                <br>
                <!-- 5g  non legacy product    -->
    
                <p class="bold-text cancellation-terms">
                    This cancellation will only be finalised once your free-to-use 5G
                    router has been returned.
                </p>
    
                <p *ngIf="selectedService?.scheduledAction?.actionName === 'Cancellation'">If you’ve changed your mind and would like to continue your rain plan, click the button below.</p>
                <br>
                
            </div>
        </div>
    
        <div class="cancel-tile-button-container">
            <button *ngIf="!hideServiceCancellation && selectedService?.scheduledAction?.actionName !== 'Cancellation'"
                class="btn btn-sm btn-selectedService-primary cancel-btn" (click)="cancel()">request cancellation</button>
            <button *ngIf="selectedService?.scheduledAction?.actionName === 'Cancellation'"
                class="btn btn-sm btn-outline-primary cancel-btn" (click)="reverseRainOneCancellation()">reverse
                cancellation</button>
        </div>
    </div>
    
    <!---show this for legacy-->
    <div class="cancel-tile-container-fourG" *ngIf="gService.category === '4G'">
        <div class="fourG-cancellation-top">
            <h2 *ngIf="selectedService?.scheduledAction?.actionName !== 'Cancellation'">thinking of <span class="bold-text"> cancelling?</span></h2>
            <h2 *ngIf="selectedService?.scheduledAction?.actionName === 'Cancellation'">cancellation <span class="bold-text"> pending </span></h2>
            <div class="fourG-cancellation-content">
                <!-- 4g legacy product    -->
                <!-- cancellation button off -->
                <p *ngIf=" hideServiceCancellation && !selectedService?.productName.includes( 'rainOne') && selectedService?.scheduledAction?.actionName !== 'Cancellation'">We really hope not. But if you decide to go, call us on 081 610 1000, select option 5 and then option 4.</p>
                <!-- cancellation button on -->
                <p *ngIf=" !hideServiceCancellation && !selectedService?.productName.includes( 'rainOne') && selectedService?.scheduledAction?.actionName !== 'Cancellation'">We really hope not. But if you decide to go, click the button below to request a cancellation.</p>
                <!-- 4g legacy product -->

                <!-- 4g non legacy -->
                <!-- cancellation button off and cancellation button on -->
                <p *ngIf="selectedService?.productName.includes( 'rainOne') && selectedService?.scheduledAction?.actionName !== 'Cancellation'">We really hope not. But if you do decide to go, please note that canceling rain<span class="bold-text">one</span>  will also mean the cancellation of your 4G mobile SIMs.</p>
                <!-- cancellation button on -->
                <p  *ngIf="selectedService?.productName.includes( 'rainOne') && !hideServiceCancellation && selectedService?.scheduledAction?.actionName !== 'Cancellation'">Click the button below to request a cancellation.</p>
                <!-- cancellation button off -->
                <p  *ngIf="selectedService?.productName.includes( 'rainOne') && hideServiceCancellation && selectedService?.scheduledAction?.actionName !== 'Cancellation'">To cancel your rain<span
                    class="bold-text">one</span>, please call us on 081 610 1000, select option 5 and then option 4.</p>
                <!-- 4g non legacy -->
                <br>
                <p *ngIf="selectedService?.scheduledAction?.actionName === 'Cancellation'">If you’ve changed your mind and would like to continue your rain plan, click the button below.</p>
            </div>
        </div>
        <div class="fourG-cancellation-actions">
            <button class="fourG-cancellation-btn"
                *ngIf="!hideServiceCancellation && selectedService?.scheduledAction?.actionName !== 'Cancellation'"
                (click)="legacyFourGCancellation()">request cancellation</button>
            <button class="fourG-cancellation-btn"
                *ngIf="selectedService?.scheduledAction?.actionName === 'Cancellation'"
                (click)="reverseFourGCancellation()">reverse cancellation</button>
        </div>
    </div>
    
</ng-container>