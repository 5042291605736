<div class="rain-go-tile-wrap">
    <div class="top-wrapper" (click)="openRainGo()">
        <div class="rain-go-header">
        <img class="rain-go-logo" src="../../assets/images/rain-101\homepage\rainGO-logo.svg" alt="rainGo">
        <p class="py-8"><span class="bold-text">buy more</span> data, minutes<br> on the go</p>
        </div>
        <div class="rain-go-img">
            <img *ngIf=" (selectedMode$ |async) === 'consumer' && !isRainMobile" src= "assets/images/rain-101/homepage/rainGOPhone-8.png" alt="rainGo">
            <img *ngIf=" (selectedMode$ |async) === 'sme'" src= "assets/images/rain-101/homepage/rainGOPhone-11.png" alt="rainGo">
            <img *ngIf="isRainMobile" src= "assets/images/rain-101/homepage/rainGOPhone-9.jpg" alt="rainGo">
        </div>
    </div>
    <div class="bottom-wrapper">
        <button (click)="openRainGo()" class="rain-go-btn" >
        go to rain<strong>GO</strong>
    </button>
    </div> 
</div>
