import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { validationMessages } from '../../pipes';
import { FormControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'rain-rain-textarea',
  templateUrl: './rain-textarea.component.html',
  styleUrls: ['./rain-textarea.component.scss']
})
export class RainTextareaComponent implements OnInit {

  @Input() control: FormControl;
  @Input() messages = validationMessages;
  @Input() public label: string;
  @Input() public blueText: boolean = false;
  @Input() public maxlength: string = '255';
  @Input() public minlength: string;
  @Input() public name: string;
  @Input() public type: string = 'text';
  @Input() public placeholder: string = '';
  @Input() public autocomplete: string = 'off';
  @Input() public display: 'column' | 'row' = 'column';
  @Input() public markValid: boolean = false;
  @Input() public focusInput: boolean = false;
  @Input() showLabel: boolean = true;
  @Output() public onKeyEnter: EventEmitter<any> = new EventEmitter()
  @Output() public onKeyUp: EventEmitter<any> = new EventEmitter()


  public isFocused: boolean = false;

  constructor(@Inject(DOCUMENT) private dom: Document) { }

  ngOnInit(): void {
  }

  public onBoundaryClick(e: Event) {
    return this.focusInputElement();
  }

  private focusInputElement() {
    const inputEl: HTMLInputElement = this.dom.querySelector(`input[name="${this.formatLabel(this.label)}"]`);
    if (inputEl !== undefined) inputEl.focus();
    this.isFocused = true;
  }

  public formatLabel(l: string) {
    return l.replace(' ', '_');
  }

  public onBlur() {

    if (this.control.value && this.control.value !== '') {
      return;
    }

    return this.isFocused = !this.isFocused;
  }

  public onKeyPress(e) {
    this.onKeyEnter.emit(e);
  }
  public KeyUp(e) {
    this.onKeyUp.emit(e);
  }

  public onLabelClick() {
    if (this.isFocused) return;

    return this.focusInputElement();
  }

}
