<section class="wrapper">
    <div class="skin-color-container">

        <div class="skin-wrapper gray-background full-width">
            <div class="primary-text-container">
                <span class="skin-text-heading">the NEW <strong>101 skins<sup class="font-weight-normal">&trade;</sup></strong> collection is here</span>
            </div>

            <div class="skin-color-image" *ngIf="optionSelected ==='rain'">
                <div class="spinner-wrapper" *ngIf="loading" >
                    <div class="spinner"></div>
                </div>
                <img class="image" (load)="onImageLoad()" [ngClass]="{'loading': loading, 'is-ios-rainOne-image' : is_ios}" *ngIf="selectedSkinColor"
                    src="assets/images/rain-101/skin-colors/flat-cpe/{{ selectedSkinColor }}.png"
                    alt="the rain one plan is here">
            </div>
            <div class="skin-color-image" *ngIf="optionSelected ==='art'">
                <div class="spinner-wrapper" *ngIf="loading" >
                    <div class="spinner"></div>
                </div>
                <img class="image" (load)="onImageLoad()" [ngClass]="{'loading': loading, 'is-ios-rainOne-image' : is_ios}" *ngIf="selectedSkinColor"
                    src="assets/images/rain-101/skin-colors/art-skins/{{ selectedSkinColor }}.png"
                    alt="the rain one plan is here">
            </div>
            <!-- <div class="secondary-text-container">
                <span class="skin-text-collection" *ngIf="!isWorkMode">Pick the one that's right for you.</span>
                <span class="skin-text-collection" *ngIf="isWorkMode">Pick the one that fits your business.</span>
            </div> -->

            <div class="collection-toggle-wrapper">
                <button (click)="setType('art')" [ngClass]="{'selected-option': optionSelected === 'art'}">art collection </button>
                <button (click)="setType('rain')" [ngClass]="{'selected-option': optionSelected === 'rain'}">rain collection </button>
            </div>

            <div class="skin-buttons-container" *ngIf="optionSelected ==='rain'">
                <button class="btns-flex-item" [ngClass]="{'is-ios' : is_ios}" *ngFor="let color of skinColors | slice:0:10;"
                    (click)="selectSkinColor(color)">
                    <img [ngClass]="{'selected': color === selectedSkinColor}"
                      src="assets/images/rain-101/skin-colors/flat-cpt-btn/{{ color }}.svg" alt="the 101">
                </button>
            </div>
            <div class="skin-buttons-container" *ngIf="optionSelected ==='art'">
                <button class="btns-flex-item" [ngClass]="{'is-ios' : is_ios}" *ngFor="let color of artColors | slice:0:10;"
                    (click)="selectSkinColor(color)">
                    <img [ngClass]="{'selected': color === selectedSkinColor}"
                     src="assets/images/rain-101/skin-colors/art-skins-btn/{{ color }}.png" alt="the 101">
                </button>
            </div>
        </div>
        <div *ngIf="isWorkMode" class="blue-router-room half-width"> <img
                src="assets/images/rain-101/skin-colors/slider/Lifestyle_01_Work-top-new.jpg" alt="the 101">
        </div>
        <div *ngIf="isWorkMode" class="green-router-room half-width"><img
                src="assets/images/rain-101/skin-colors/slider/Lifestyle_03_Work-botttom-new.jpg" alt="the 101">
        </div>
        <div *ngIf="!isWorkMode" class="blue-router-room half-width"> <img
                src="assets/images/rain-101/skin-colors/slider/Lifestyle_01_Work-top.png" alt="the 101">
        </div>
        <div *ngIf="!isWorkMode" class="green-router-room half-width"><img
                src="assets/images/rain-101/skin-colors/slider/Lifestyle-art.png" alt="the 101">
        </div>
    </div>
</section>