import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@services/config.service';
import { Observable, of } from 'rxjs';
import { RainOneProduct } from '../../interfaces/rain-one-products.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductCatelogueService {
  constructor(private _http: HttpClient, private configService: ConfigService) {}

  public fetchRainOneProducts(): Observable<{ result: Array<RainOneProduct> }> {
    const isSit = environment.config === 'sit' || !window.location.href?.includes("rain.co.za");
    let url: string = 'https://prod-bss-api.rain.co.za/firestore/base?apikey=zI7RnqlIeA7GwkHQVXNa5vZSOPfAuEug';
    if (isSit) {
      url = 'https://sit-bss-api.rain.network/firestore/base?apikey=8UGKLP8cd4CA2KyFFNn01spu10SC0PeZ';
    }
    
    return this._http.get<{ result: Array<RainOneProduct> }>(url);
  }
}
