import { Component } from '@angular/core';
import { Select, Store} from '@ngxs/store';
import { Observable } from 'rxjs';
import { CoreState } from 'src/app/core/store/state/core.state';
import { DataLayerService } from '@services/data-layer.service';
import { SSOService } from 'src/app/core/services/sso.service';

@Component({
  selector: 'rain-sim-for-data',
  templateUrl: './sim-for-data.component.html',
  styleUrls: ['./sim-for-data.component.scss']
})
export class SimForDataComponent {

  constructor(
    private store: Store,
    private dataLayerService: DataLayerService,
    private sso: SSOService,
  ){}
  @Select(CoreState.activeBreakpoint) activeBreakpoint$: Observable<string>;

  openRainGoIfSignedIn() {
    const isAuthed = this.store.selectSnapshot(CoreState.isAuthenticated);
    
    if (!isAuthed) {
      const url = "https://www.raingo.co.za"; // add environment urls
      window.location.href = "https://www.raingo.co.za"
      return
    }

    this.dataLayerService.rainGoClicked();
    return this.sso.createUrl().then(
      (encodedUrl) => {
        window.open(encodedUrl, '_self')
      }
    );
  }
}
