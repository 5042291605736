import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAP_SEARCH_CONFIG } from '@models/constants';
import { FormHelper } from '../../helpers/form.helper';
import { validationMessages } from '../../pipes';

@Component({
  selector: 'sf-google-placesform-input',
  templateUrl: './rain-google-places-input.component.html',
  styleUrls: ['./rain-google-places-input.component.scss']
})
export class RainGooglePlacesInputComponent extends FormHelper implements AfterViewInit {
  @Input() control: FormControl;
  @Input() messages = validationMessages;
  @Input() public label: string;
  @Input() public name: string;
  @Input() public type: string = 'text';
  @Input() public placeholder: string = '';
  @Input() public autocomplete: string = '';
  @Input() public display: 'column' | 'row' = 'column';
  @Input() public markValid: boolean = false;
  @Input() public focusInput: boolean = false;
  @Output() public onKeyEnter: EventEmitter<any> = new EventEmitter()
  @Output() public onKeyUp: EventEmitter<any> = new EventEmitter()
  @Output() public onAddressChange: EventEmitter<any> = new EventEmitter()
  @Output() public onAddressSearch: EventEmitter<any> = new EventEmitter()

  public config = MAP_SEARCH_CONFIG;

  public isFocused: boolean = false;

  constructor(@Inject(DOCUMENT) private dom: Document) {
    super();
  }

  ngAfterViewInit() {
    if (this.focusInput) {
      return this.focusInputElement();
    }
    if (this.control) {
      this.InitFormControl(this.control);

      if (!this.control.value || !this.control.dirty) {
        this.control.valueChanges.subscribe({
          next: (res) => {
            if (res) {
              this.isFocused = true;
            }
          }
        })
      }
    }

  }

  public onBoundaryClick(e: Event) {
    return this.focusInputElement();
  }

  private focusInputElement() {
    const inputEl: HTMLInputElement = this.dom.querySelector(`input[name="${this.formatLabel(this.placeholder)}"]`);
    if (inputEl !== undefined) inputEl.focus();
  }

  public formatLabel(l: string) {
    return l.replace(' ', '_');
  }

  public onBlur() {

    if (this.control.value && this.control.value !== '') {
      return;
    }

    return this.isFocused = !this.isFocused;
  }

  public onKeyPress(e) {
    this.onKeyEnter.emit(e);
  }
  public KeyUp(e) {
    this.onKeyUp.emit(e);
  }

  public handleAddressChange(e) {
    this.onAddressChange.emit(e);
  }
  public handleAddressSearch(e) {
    this.onAddressSearch.emit(e);
  }
}
