import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { UIActions } from '../../store/actions/ui.actions';
import { EmptyCart } from 'src/app/store/actions/cart.action';
import { Navigate } from '@ngxs/router-plugin';

@Component({
  selector: 'purchase-progress-modal',
  templateUrl: './purchase-progress-modal.component.html',
  styleUrls: ['./purchase-progress-modal.component.scss']
})
export class purchaseProgressModal implements OnInit {
  @Input() selectedUIMode?: string;

  constructor(public activeModal: NgbActiveModal, private store: Store) {}

  ngOnInit(): void {}

  changeToSelectedUIMode() {
    this.store.dispatch(new EmptyCart());

    switch (this.selectedUIMode) {
      case 'sme':
        this.store.dispatch([new UIActions.SwitchUIMode({ mode: 'sme' }), new Navigate(['/work'])]);
        break;
      case 'consumer':
        this.store.dispatch([new UIActions.SwitchUIMode({ mode: 'consumer' }), new Navigate(['/home'])]);
        break;
      case 'mobile':
        this.store.dispatch([new UIActions.SwitchUIMode({ mode: 'mobile' }), new Navigate(['/mobile'])]);
        break;
      default:
        this.store.dispatch([new UIActions.SwitchUIMode({ mode: 'consumer' }), new Navigate([''])]);
        break;
    }

    this.close();
  }

  close() {
    this.activeModal.close();
  }

  toggleTermsAccepted() {
    this.close();
  }
}
