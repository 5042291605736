import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@services/auth.service';
import { ElementService } from '@services/element.service';
import { CACHE_REMEMBER_ME } from '@models/constants';
import { Store } from '@ngxs/store';
import { ClearServicesState, ClearServicePolicies } from 'src/app/store/actions/services.actions';
import { CoverageCheckedOnRegistration } from 'src/app/store/actions/cart.action';
import { ClearState } from 'src/app/store/actions/number-porting-actions';
import { AuthState } from 'src/app/core/store/state/auth.state';
import { ClearProfile, ClearTokens } from 'src/app/core/store/actions/auth.actions';
import { UIActions } from 'src/app/shared/store/actions/ui.actions';

@Component({
  selector: 'app-confirm-sign-out-modal',
  templateUrl: './confirm-sign-out-modal.component.html',
  styleUrls: ['./confirm-sign-out-modal.component.scss']
})
export class ConfirmSignOutModalComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthenticationService,
    private elementService: ElementService,
    private store: Store
  ) {
  }

  ngOnInit() {
  }

  signOut(option) {
    this.activeModal.close('close modal');
    if (option) {
      localStorage.removeItem('selected_payment_date');
      this.authService.signOut();
      this.elementService.scrollToHome();
      sessionStorage.clear();
    }
  }
}
