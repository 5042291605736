import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {CardTemplateOneComponent} from './templates/card-template-one/card-template-one.component';
import {RouterSkinsOptionsComponent} from './router-skins-options/router-skins-options.component';
import {StickyNavComponent} from './sticky-nav/sticky-nav.component';
import {GridBaseComponent} from './templates/grid-base/grid-base.component';
import {RainOneModalVideoComponent} from './rain-one-modal-video/rain-one-modal-video.component';
import {RainOnePurchaseComponent} from './rain-one-purchase/rain-one-purchase.component';
import {RainOneWifiDaysComponent} from './rain-one-wifi-days/rain-one-wifi-days.component';
import {CarouselOneComponent} from './carousel-one/carousel-one.component';
import {ArtCarouselComponent} from './carousel-one/art-carousel/art-carousel.component';
import {SimForDataComponent} from '@pages/home/tiles/sim-for-data/sim-for-data/sim-for-data.component';
import {SimForVoiceComponent} from '@pages/home/tiles/sim-for-voice/sim-for-voice/sim-for-voice.component';
import {NonStickyNavComponent} from "./non-sticky-nav/non-sticky-nav.component";
import {SupportButtonModule} from "@components/support-button/support-button.module";


const declarations = [
    CardTemplateOneComponent,
    RouterSkinsOptionsComponent,
    StickyNavComponent,
    NonStickyNavComponent,
    GridBaseComponent,
    RainOneModalVideoComponent,
    RainOnePurchaseComponent,
    RainOneWifiDaysComponent,
    CarouselOneComponent,
    ArtCarouselComponent,
    SimForDataComponent,
    SimForVoiceComponent
]

@NgModule({
    declarations: [
        ...declarations,

    ],
    imports: [
        CommonModule,
        SlickCarouselModule,
        SupportButtonModule
    ],
    exports: [declarations],
})
export class Rain101Module {
}
