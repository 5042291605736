import { IOrderDetail } from '@models/orderDetail';
import { Utils } from 'src/app/utils';

export class GetAllOrders{
    public static type ="[ORDERS] Get AllOrders";
}

export class GetOneOrder{
    public static type = Utils.Helpers.Type("[ORDERS] Get One Order");
    constructor(public orderId: string){}
}


export class SetCurrentOrder{
    public static type = Utils.Helpers.Type("[ORDERS] Set Current Order");
    constructor(public order: any){}
}


export class ResetOrders{
    public static type = Utils.Helpers.Type("[ORDERS] Reset");
}

export class SetOrders {
    public static type = Utils.Helpers.Type("[ORDERS: Set] Set all Orders");
    constructor(public payload: IOrderDetail[]){}
}

export class HasRainOneOrder {
    public static type = Utils.Helpers.Type("[ORDERS] Has Rainone Order")
}


