<div class="form-group" [class.mark-valid]="markValid">
    <div class="field-container" [class.is-focused]="isFocused || control?.value">
        <label [for]="formatLabel(label)" class="label" (click)="onLabelClick()" *ngIf="showLabel">{{ label }}</label>
        <textarea id="comment" matInput class="textArea" placeholder="How can we help?" (keydown.enter)="onKeyPress($event)" (keyup)="KeyUp($event)" [name]="name ? name : formatLabel(label)"
        (focus)="isFocused = true" (blur)="onBlur()" autocomplete="off" [id]="formatLabel(label)" 
        [formControl]="control" [maxlength]="maxlength" class="input py-2 medium-font-size"
        [ngClass]="{ input: label !== 'password', passwordInput: label === 'password',blue: blueText }" readonly
        onfocus="this.removeAttribute('readonly');" [placeholder]="placeholder"></textarea>
    </div>

</div>


