import { GetPortResponse, IPortInCreateReq, PortInCreateReqRemote, PortOutBody, PortStatus } from "@pages/service-page/rain-one-actions/port-my-number/port-in.interface";
import { Utils } from "src/app/utils";




export class SetServiceType {
    public static type = Utils.Helpers.Type('[NUMBER PORTING] Sets service type of the number being ported');
    constructor(public serviceType : string){}
}

export class PortSim {
    public static type = Utils.Helpers.Type('[Number Porting] Associates sim that will be ported');
    constructor(public payload : PortInCreateReqRemote){}
}

export class PortSimPending {
    public static type = Utils.Helpers.Type('[Number Porting] Checks the status of a ported number');
    constructor(public pendingStatus : boolean){}
}

export class numberPortIsCancelled {
    public static type = Utils.Helpers.Type('[Number Porting] checked if the service ID of a port is cancelled');
    constructor(public cancelled : boolean){}
}

export class SetServiceId {
    public static type = Utils.Helpers.Type('[Number Porting] Sets the user service Id');
    constructor(public serviceId : string){}
}


export class PortOut {
    public static type = Utils.Helpers.Type('[Number Porting] Ports out a number that has already been ported into rain');
    constructor(public payload : PortOutBody){}
}
export class GetPortStatusById{
    public static type = Utils.Helpers.Type('[Number Porting] Gets the port status of ported number' )
    constructor(public serviceId : string){}
}
export class GetPortOutStatusById{
    public static type = Utils.Helpers.Type('[Number Porting] Gets the status of ported out number' )
    constructor(public serviceId : string){}
}

export class ResetPortStatusById{
    public static type = Utils.Helpers.Type('[Number Porting] Resets the port status by service Id' )
    constructor(public serviceId : string){}
}
export class ClearState{
    public static type = Utils.Helpers.Type('[Number Porting] Clears number porting state' )
}

export class SetMultiServiceIds {
    public static type = Utils.Helpers.Type('[Number Porting] Sets the service IDS');
    constructor(public servicesIdArray : {id: string, status: string}[]){}
}