import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ScriptService } from '../script/script.service';

const SCRIPT_PATH = 'assets/js/doubleserve.js';
declare let gapi: any;

@Injectable({
  providedIn: 'root'
})
export class AdBlockerService {
  private renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    private scriptService: ScriptService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  init(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const scriptElement = this.scriptService.loadJsScript(this.renderer, SCRIPT_PATH);
      scriptElement.onload = () => resolve(false);
      scriptElement.onerror = () => resolve(true)
    });
  }

}
