import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { DataLayerService } from '@services/data-layer.service';
import { Observable } from 'rxjs';
import { SSOService } from 'src/app/core/services/sso.service';
import { UIMode, UIState } from 'src/app/shared/store/state/ui.state';
import { ProductState } from 'src/app/store/state/product.state';
import { ServicesState } from 'src/app/store/state/services.state';

@Component({
  selector: 'rain-rain-go-tile',
  templateUrl: './rain-rain-go-tile.component.html',
  styleUrls: ['./rain-rain-go-tile.component.scss']
})
export class RainRainGoTileComponent implements OnInit {
  @Select(UIState.GetUIMode) selectedMode$: Observable<UIMode>;
  isRainMobile?: boolean;

  constructor(
    private dataLayerService: DataLayerService,
    private sso: SSOService,
    private store: Store,
  ) { }

  ngOnInit(): void {
    const selectedService = this.store.selectSnapshot(ServicesState.SelectedService);
    const rainMobileProducts = this.store.selectSnapshot(ProductState.GetRainMobileSIMProducts);
    const rainMobileBaseProduct = this.store.selectSnapshot(ProductState.GetRainMobileBundleProducts);

    if (rainMobileProducts && selectedService) {
      const primaryService = rainMobileBaseProduct.items.filter((item) => {
        return item.id === selectedService?.productId;
      });
      if (primaryService) {
        this.isRainMobile = selectedService?.productId === rainMobileProducts[0]?.id;
      }

    }

  }
  openRainGo() {
    this.dataLayerService.rainGoClicked();
    return this.sso.createUrl().then(
      (encodedUrl) => {
        window.open(encodedUrl, '_self')
      }
    );
  }
}
