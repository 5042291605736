import { AdBlockerService } from "./ad-blocker/ad-blocker.service";
import { ConnectionService } from "./connection.service";
import { DeviceChecker } from "./device-checker/device-checker";
import { LayoutService } from "./layout/layout.service";
import { ProductCatelogueService } from "./product-catelogue/product-catelogue.service";
import { ScriptService } from "./script/script.service";
import { ServiceCoverageCheckService } from "./service-coverage-check.service";
import { SmsService } from "./sms/sms.service";
import { SSOService } from "./sso.service";
import { TopUpService } from "./top-up.service";
import { VoucherService } from "./voucher/voucher.service";

export const services: any[] = [
    AdBlockerService,
    ScriptService,
    DeviceChecker,
    LayoutService,
    SmsService,
    VoucherService,
    ConnectionService,
    ProductCatelogueService,
    TopUpService,
    ServiceCoverageCheckService,
    SSOService
];