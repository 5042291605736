import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';
import { CACHE_AUTH_TOKEN, UOP_IDS } from '@models/constants';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { UserService } from './user.service';
import { SupportService } from './support.service';

import { CancellationsRequest } from '@pages/service-page/plan-cancellation-page/cancellations.interface';
import { PinPukInterface } from '@pages/service-page/devices/interfaces/device.interfaces';
@Injectable({
  providedIn: 'root'
})
export class MigrateToRainOneService {
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private configService: ConfigService,
    private userService: UserService,
    private supportService: SupportService,
  ) { }

  public fourGRainOneMigrationService(request: {
    serviceId: string;
    userId: string;
    delivery_address: any;
    product_id: string;
    courier_method: string;
    courier: string;
    paymentType;
    location_id?: string;
    msisdn: string;
  }): Observable<any> {

    let productId: string

    if (UOP_IDS.includes(request.product_id)) {
      productId = request.paymentType === 'upfront' ? '4b549610-7845-4c2c-9a70-06f3831279b2' : 'ebbeaf2b-6a96-4056-9b58-82da44c9df09'
    } else {
      productId = request.paymentType === 'upfront' ? 'dab345b9-5662-4f43-8444-da7d63f74535' : 'ce5e2320-b1a4-4450-899f-8379bea7f9cd'
    }

    let payload = {
      product_id: productId,
      delivery: {
        delivery_address: request.delivery_address,
        courier_method: request.courier_method,
        courier: request.courier
      }
    };

    if (request.location_id) {
      payload.delivery['location_id'] = request.location_id;
    }

    const requestPath = `${this.configService.SERVICE_PROXY_API}/service/${request.serviceId}/_migrate`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.tokenService.get(CACHE_AUTH_TOKEN)}`
      })
    };
    return this.http.patch(requestPath, payload, httpOptions as any).pipe(
      catchError(e => {
        return this.userService.get().pipe(
          map(res => res?.value),
          tap(user => {
            let ticketRequest = {
              name: user.firstName + ' ' + user.lastName,
              email: user.email,
              subject: `rainOne migration`,
              referenceId: user.email,
              body: {
                msisdn: request.msisdn
              },
              captcha: '-'
            };
            return this.supportService.addTicket(ticketRequest).subscribe();
          })
        );
      })
    );
  }

  // public migrateService(request: {
  //   serviceId: string;
  //   userId: string;
  //   delivery_address: any;
  //   product_id: string;
  //   courier_method: string;
  //   courier: string;
  //   paymentType;
  //   location_id?: string;
  //   msisdn: string;
  // }): Observable<any> {
  //   let payload = {
  //     product_id: request.paymentType === 'upfront' ? 'dcf722e0-7d2b-4b79-acb9-1a22397b437b' : '50a6de3d-fc60-4167-9818-4c11c36b26c9',
  //     delivery: {
  //       delivery_address: request.delivery_address,
  //       courier_method: request.courier_method,
  //       courier: request.courier
  //     }
  //   };

  //   if (request.location_id) {
  //     payload.delivery['location_id'] = request.location_id;
  //   }

  //   const requestPath = `${this.configService.SERVICE_PROXY_API}/service/${request.serviceId}/_migrate`;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${this.tokenService.get(CACHE_AUTH_TOKEN)}`
  //     })
  //   };
  //   return this.http.patch(requestPath, payload, httpOptions as any).pipe(
  //     catchError(e => {
  //       return this.userService.get().pipe(
  //         map(res => res?.value),
  //         tap(user => {
  //           let ticketRequest = {
  //             name: user.firstName + ' ' + user.lastName,
  //             email: user.email,
  //             subject: `rainOne migration`,
  //             referenceId: user.email,
  //             body: {
  //               msisdn: request.msisdn
  //             },
  //             captcha: '-'
  //           };
  //           return this.supportService.addTicket(ticketRequest).subscribe();
  //         })
  //       );
  //     })
  //   );
  // }

  public cancelRainOneService(request: { serviceId: string; reason: string; collection_address: string; comments: string }): Observable<any> {
    let payload = {
      reason: request.reason,
      comments: request.comments,
      collection_address: request.collection_address,
      type: 'scheduled'
    };

    const requestPath = `${this.configService.SERVICE_PROXY_API}/service/${request.serviceId}/_cancel`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.tokenService.get(CACHE_AUTH_TOKEN)}`
      })
    };
    return this.http.post(requestPath, payload, httpOptions as any);
  }

  //new cancellation flow pre cancellations
  public rainOnePreCancellationsDate(serviceId: string): Observable<any> {

    const requestPath = `${this.configService.SERVICE_PROXY_API}/service/${serviceId}/_cancellation-date`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.tokenService.get(CACHE_AUTH_TOKEN)}`,
        ApiKey: this.configService.API_KEY
      })
    };
    return this.http.get(requestPath, httpOptions as any);
  }

  public preCancelRainOneService(payload: CancellationsRequest): Observable<any> {
    const { serviceId, fulfillmentType, collectionAddress , reason, comments, contactNumber,type } = payload

    const body = {
      serviceId,
      reason ,
      comments,
      contactNumber,
      fulfillmentType,
      collectionAddress,
      type
    }

    const requestPath = `${this.configService.SERVICE_PROXY_API}/service/${serviceId}/_precancel`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.tokenService.get(CACHE_AUTH_TOKEN)}`
      })
    };
    return this.http.post(requestPath, body, httpOptions as any);
  }

  public revokeRainOnePrecancellation(serviceId: string): Observable<any> {

    const requestPath = `${this.configService.SERVICE_PROXY_API}/service/${serviceId}/_revoke-precancel`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.tokenService.get(CACHE_AUTH_TOKEN)}`
      })
    };
    return this.http.post(requestPath, Option, httpOptions);
  }



  public reverseCancelRainOneService(serviceId: string): Observable<any> {
    const requestPath = `${this.configService.SERVICE_PROXY_API}/service/${serviceId}/_revoke-cancel`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.tokenService.get(CACHE_AUTH_TOKEN)}`
      })
    };
    return this.http.post(requestPath, {}, httpOptions as any);
  }

  public levelMigrateRainOne(request: { serviceId: string; product_id: string, msisdn: string }): Observable<any> {
    let payload = {
      product_id: request.product_id
    };
    
    const requestPath = `${this.configService.SERVICE_PROXY_API}/service/${request.serviceId}/_level-migration`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.tokenService.getTokenForAccountType()}`
      })
    };
    return this.http.post(requestPath, payload, httpOptions as any).pipe(
      catchError(e => {
        this.userService
          .get()
          .pipe(
            map(res => res?.value),
            tap(user => {
              let ticketRequest = {
                name: user.firstName + ' ' + user.lastName,
                email: user.email,
                subject: `rainOne migration`,
                referenceId: user.email,
                body: {
                  msisdn: request.msisdn
                },
                captcha: '-'
              };
              this.supportService.addTicket(ticketRequest).subscribe();
            })
          )
          .subscribe();
        return throwError('migration level up failed');
      })
    );
  }

  public addOn(request: { currentServiceID: string; addOnProductId: string }): Observable<any> {
    let payload = {
      productId: request.addOnProductId
    };

    const requestPath = `${this.configService.SERVICE_PROXY_API}/service/${request.currentServiceID}/_addon`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.tokenService.get(CACHE_AUTH_TOKEN)}`
      })
    };
    return this.http.patch(requestPath, payload, httpOptions as any);
  }

  public getPinPuk(iccid: string): Observable<any> {
    const requestPath = `${this.configService.BASE_API_URL}/V1/rain-web/resource-manager/pinpuk?iccid=${iccid}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.tokenService.get(CACHE_AUTH_TOKEN)}`
      })
    };
    return this.http.get(requestPath, httpOptions as any);
  }

  public migrateService(request: { serviceId: string, userId: string, delivery_address: any, product_id: string, courier_method: string, courier?: string, paymentType, location_id?: string, msisdn: string }, isNvidia?: boolean): Observable<any> {
    let productId = ""
    if (!isNvidia) {
      productId = request.paymentType === 'upfront' ? "dcf722e0-7d2b-4b79-acb9-1a22397b437b" : '50a6de3d-fc60-4167-9818-4c11c36b26c9'
    } else {
      productId = request.product_id

    }

    let payload = {
      product_id: productId,
      delivery: {
        delivery_address: request.delivery_address,
        courier_method: request.courier_method,
        courier: request.courier
      }

    }

    if (request.location_id) {
      payload.delivery["location_id"] = request.location_id
    }

    const requestPath = `${this.configService.SERVICE_PROXY_API}/service/${request.serviceId}/_migrate`;
    const httpOptions = {

      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.tokenService.get(CACHE_AUTH_TOKEN)}`
      }),
    };
    return this.http.patch(requestPath, payload, httpOptions as any).pipe(
      catchError(e => {
        return this.userService.get().pipe(
          map(res => res?.value),
          tap(user => {
            let ticketRequest = {
              name: user.firstName + ' ' + user.lastName,
              email: user.email,
              subject: `rainOne migration`,
              referenceId: user.email,
              body: {
                msisdn: request.msisdn
              },
              captcha: '-'
            }
            return this.supportService.addTicket(ticketRequest).subscribe()
          }))


      })
    )
  }

}
