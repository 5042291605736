import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from 'src/app/baseComponent';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CookieService} from '@services/cookie.service';
import {SupportService} from '@services/support.service';
import {ToastrService} from 'ngx-toastr';
import {takeUntil} from 'rxjs/operators';
import {Select, Store} from '@ngxs/store';
import {CacheService} from '@services/cache.service';
import {CACHE_SUPPORT_BILLING_ADDED, CACHE_SUPPORT_NETWORK_ADDED, MAP_SEARCH_CONFIG} from '@models/constants';
import {AddTicketCreationTimer} from '@components/sales-modal/store/actions/limit-ticket-creations.actions';
import {ConfigService} from '@services/config.service';
import {AuthenticationService} from '@services/auth.service';
import {UserService} from '@services/user.service';
import {LimitSalesTicketCreationState} from '@components/sales-modal/store/state/limit-ticket-creation.state';
import {Observable} from 'rxjs/internal/Observable';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {IServiceGroup} from '@models/serviceDetail';
import {ServicesState} from 'src/app/store/state/services.state';
import {IServicePageConfig} from 'src/app/core/interfaces/service-page-config.interface';
import {DataLayerService} from '@services/data-layer.service';

@Component({
    selector: 'rain-contact-support',
    templateUrl: './contact-support.component.html',
    styleUrls: ['./contact-support.component.scss']
})
export class ContactSupportComponent extends BaseComponent implements OnInit {
    latitude: any;
    longitude: any;
    public activeTab: string = 'network';
    public subject: string = 'rain network support';
    public contactSupportFormNetwork: FormGroup;
    public contactSupportFormBilling: FormGroup;
    public contactSupportFormLegacyNetwork: FormGroup;
    public contactSupportFormLegacyBilling: FormGroup;
    public loading: boolean;
    public isTicketCreationAllowed: boolean;
    public successMessage: boolean;
    public showOverlay: boolean;
    public errorMessage: boolean;
    public isLegacy: boolean = false;
    public formProduct: string;
    public formAddress: string;
    public formComments: string;
    public formName: string;
    public formNumber: string;
    public formEmail: string;
    public siteKey: string;
    public config = MAP_SEARCH_CONFIG;
    @Input() isRainOne: boolean;
    @Input() subServicesArray: IServiceGroup;
    @Input() productId: string;
    @Input() pageConfig: IServicePageConfig;
    @Input() gService: IServiceGroup;
    @Select(LimitSalesTicketCreationState.isTicketCreationAllowed) isTicketCreationAllowed$: Observable<boolean>;
    @Select(ServicesState.GetSelectedServiceGroup) sGroupService$: Observable<IServiceGroup>;
    serviceNamesMap: string[] = [];
    addressSet: boolean;

    constructor(
        private fb: FormBuilder,
        private toastr: ToastrService,
        private cookieService: CookieService,
        private supportService: SupportService,
        private store: Store,
        private cacheService: CacheService,
        private configService: ConfigService,
        private authService: AuthenticationService,
        private userService: UserService,
        private dataLayerService: DataLayerService
    ) {
        super();
    }

    ngOnInit(): void {

        if (this.pageConfig && !this.pageConfig.isLegacyService) {
            const primaryServiceName = this.gService?.serviceObject?.name;
            const subServiceNames = Object.values(this.gService?.subServices || {}).map(subService => subService.name);
            this.serviceNamesMap = [primaryServiceName, ...subServiceNames];
        }
        this.setForm();

        if (this.authService.isSignedIn) {
            this.getCustomerDetails();
        }

        this.siteKey = this.configService.SITE_KEY;

        this.isTicketCreationAllowed$.subscribe(value => {
            this.isTicketCreationAllowed = value;
        });

        this.contactSupportFormNetwork.get('address').valueChanges
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res => {
                this.addressSet = false;
            })

        this.contactSupportFormLegacyNetwork.get('address').valueChanges
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res => {
                this.addressSet = false;
            })
    }

    getCustomerDetails() {
        this.userService
            .get()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res => {
                if (res.status === 1) {
                    this.prePopulateForm(res.value);
                }
            });
    }

    prePopulateForm(user) {
        this.formName = user?.firstName + ' ' + user?.lastName;
        this.formNumber = user?.phone;
        this.formEmail = user?.email;
        this.formNumber
    }

    private setForm() {
        this.contactSupportFormNetwork = this.fb.group({
            product: this.fb.control(this.serviceNamesMap.length === 1 ? this.serviceNamesMap[0] : '', Validators.compose([Validators.required])),
            address: this.fb.control(null, Validators.compose([Validators.required])),
            comments: this.fb.control(null, [Validators.required, Validators.minLength(5)])
        });

        this.contactSupportFormBilling = this.fb.group({
            comments: ['', [Validators.required, Validators.minLength(5)]]
        });

        this.contactSupportFormLegacyNetwork = this.fb.group({
            address: ['', Validators.required],
            comments: ['', [Validators.required, Validators.minLength(5)]]
        });

        this.contactSupportFormLegacyBilling = this.fb.group({
            comments: ['', [Validators.required, Validators.minLength(5)]]
        });
    }


    resetForms() {
        this.contactSupportFormNetwork.setValue({
            product: " ",
            address: null,
            comments: null
        });

        this.contactSupportFormBilling.setValue({
            comments: ''
        });

        this.contactSupportFormLegacyNetwork.setValue({
            address: null,
            comments: ''
        });

        this.contactSupportFormLegacyBilling.setValue({
            comments: ''
        });

        Object.keys(this.contactSupportFormBilling.controls).forEach((control) => {
            this.contactSupportFormBilling.get(control).markAsUntouched();
            this.contactSupportFormBilling.get(control).markAsPristine();
        });
        Object.keys(this.contactSupportFormLegacyBilling.controls).forEach((control) => {
            this.contactSupportFormBilling.get(control).markAsUntouched();
            this.contactSupportFormBilling.get(control).markAsPristine();
        });
        Object.keys(this.contactSupportFormNetwork.controls).forEach((control) => {
            this.contactSupportFormBilling.get(control).markAsUntouched();
            this.contactSupportFormBilling.get(control).markAsPristine();
        });

        Object.keys(this.contactSupportFormLegacyNetwork.controls).forEach((control) => {
            this.contactSupportFormBilling.get(control).markAsUntouched();
            this.contactSupportFormBilling.get(control).markAsPristine();
        });

        this.formAddress = null;
    }

    public toggleButton(button: string) {
        this.activeTab = button === 'network' ? 'network' : 'billing';
        this.subject = this.activeTab === 'network' ? 'rain network support' : 'rain billing support';
        this.resetForms();
    }

    buttonTexts = {
        network: 'network',
        billing: 'billing'
    };

    gotIt() {
        this.showOverlay = false;
    }

    handleAddressChange = (result: Address) => {
        this.addressSet = false;

        this.formAddress = result.formatted_address;

        this.latitude = result.geometry.location.lat();
        this.longitude = result.geometry.location.lng();

        this.addressSet = true;
    };

    public submitForm() {
        this.formProduct = this.contactSupportFormNetwork.value.product;
        this.formComments = this.activeTab === "network" ?
            this.contactSupportFormNetwork.value.comments ||
            this.contactSupportFormLegacyNetwork.value.comments
            :
            this.contactSupportFormBilling.value.comments ||
            this.contactSupportFormLegacyBilling.value.comments;


        const networkSupportCached = this.subject === 'rain network support' && this.cacheService.exists(CACHE_SUPPORT_NETWORK_ADDED);
        const billingSupportCached = this.subject === 'rain billing support' && this.cacheService.exists(CACHE_SUPPORT_BILLING_ADDED);

        this.errorMessage = false;

        if (networkSupportCached || billingSupportCached) {
            this.showOverlay = true;
            this.errorMessage = true;
            this.successMessage = false;
            this.contactSupportFormNetwork.reset();
            this.contactSupportFormBilling.reset();
            this.contactSupportFormLegacyNetwork.reset();
            this.contactSupportFormLegacyBilling.reset();
            this.resetForms()
            return;
        } else {
            this.store.dispatch(new AddTicketCreationTimer(12));
            this.submitTicket();
        }
    }

    submitTicket() {
        this.loading = true;
        const body: any = {
            message: `<h2> subject: ${this.subject || 'rain sales support'}</h2>
      <p style= "font-size: 14px;">(name): ${this.formName}</p>
      <p style= "font-size: 14px;">(tel): ${this.formNumber}</p>
      <p style= "font-size: 14px;">(email): ${this.formEmail}</p>
      <p style= "font-size: 14px;">(product): ${this.formProduct}</p>
      <p style= "font-size: 14px;">(address): ${this.formAddress}</p>
      <p style= "font-size: 14px;">(comments): ${this.formComments}</p>
      <p style= "font-size: 14px;">(platform): WEB</p>
      <p style= "font-size: 14px;">(requestedFrom): Manage Page Contact Support</p>
      <p style= "font-size: 14px;">(ga_tag): ${this.cookieService.getGATag()}</p>`
        };

        const ticketRequest = {
            name: this.formName,
            address: this.formAddress,
            email: this.formEmail,
            subject: this.subject.includes('#')
                ? `${this.subject || 'rain sales support'} ${this.formNumber}`
                : `${this.subject || 'rain sales support'} #${this.formNumber}`,
            referenceId: this.formNumber,
            body,
            captcha: null,
            ga_tag: this.cookieService.getGATag()
        };

        this.supportService
            .addTicket(ticketRequest)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result: any) => {
                this.loading = false;
                if (result) {
                    if (!result?.ticket) {
                        this.loading = false;
                        setTimeout(() => this.toastr.error('An error occurred while submitting. Please try again', null));
                        return;
                    } else {
                        this.loading = false;
                        this.showOverlay = true;
                        this.successMessage = true;
                        this.dataLayerService.manageContactFormSubmit(this.subject);
                        // Set cache based on the subject
                        const cacheKey = this.subject === 'rain network support' ? CACHE_SUPPORT_NETWORK_ADDED : CACHE_SUPPORT_BILLING_ADDED;
                        this.resetForms();
                        this.cacheService.set(cacheKey, 'set', 86400000);
                    }
                }
            });
    };
}
