import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ElementService } from '@services/element.service';
import { SubjectService } from '@services/subject.service';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/baseComponent';

@Component({
  selector: 'app-cancellation-survey-action-modal',
  templateUrl: './cancellation-survey-action-modal.component.html',
  styleUrls: ['./cancellation-survey-action-modal.component.scss']
})
export class CancellationSurveyActionModalComponent extends BaseComponent implements OnInit, OnDestroy {

  @Output() confirmed = new EventEmitter<boolean>();
  @Input() type: string;
  @Input() selectedQuestion: string;
  @Input() serviceId: string;
  @Input() productId: string;
  @Input() address: string;
  @Input() msisdn: string;

  loading = false;
  title: string;
  body: string;

  simSwapSubscription: Subscription;
  isMobile = navigator.userAgent.match(/(iPhone|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i);

  constructor(
    public activeModal: NgbActiveModal,
    private elementService: ElementService,
    private subjectService: SubjectService
  ) {
    super();

    this.simSwapSubscription = this.subjectService.subscribeSimSwapResponse().subscribe(res => {
      if (res) {
        this.activeModal.dismiss();
      }
    });

    this.simSwapSubscription = this.subjectService.subscribeCancel5GResponse().subscribe(res => {
      if (res) {
        this.activeModal.dismiss();
        this.confirmed.next(true);
      }
    });
  }

  ngOnInit() {

    if (this.selectedQuestion === 'Switching to a different rain product') {
      this.title = 'wait up, you don’t have to cancel';
      this.body = `Did you know that you can switch to another ${this.type === '4G' ? '4G' : '5G'} product without cancelling or getting a new SIM? Just request for an upgrade or downgrade.`;
    } else if (this.selectedQuestion === 'I lost my SIM') {
      this.title = 'maybe we can help?';
      this.body = 'If you lost your SIM, you can request for a SIM swap and we will send you a new SIM, you don’t have to cancel.';
    }

  }

  requestSwitch(request) {
    this.activeModal.dismiss();
    if (request) {
      this.elementService.scrollToMigration();
    } else {
      this.confirmed.next(true);
    }
  }

  requestSIMSwap(request) {
    this.activeModal.dismiss();
    if (request) {
      this.activeModal.dismiss();
      this.elementService.scrollToMigration();
    } else {
      this.confirmed.next(true);
    }
  }

  ngOnDestroy(): void {
    this.simSwapSubscription.unsubscribe();
  }

}
