import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CACHE_AUTH_TOKEN, CACHE_Credential_TOKEN, CACHE_IDM_TOKEN, CACHE_SERVICE, CACHE_SERVICES, CACHE_SME_TOKEN } from '@models/constants';
import { IDeviceDetail } from '@models/deviceDetail';
import { ModelHelper } from '@models/modelHelper';
import { PukDetails } from '@models/PukDetails';
import { Result, Statuses } from '@models/result';
import { IServiceDetail, ServiceDetail, ServicePolicyRemote, ServiceStatuses } from '@models/serviceDetail';
import { IServiceUsageDetail, ServiceUsageDetail } from '@models/serviceUsageDetail';
import { IUserDetail } from '@models/userDetail';
import { UserRegisterRequest } from '@models/userRegisterRequest';
import { UserUpdateRequest } from '@models/userUpdateRequest';
import { isEmpty, isNil, result, uniq } from 'lodash';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, switchMap, timeout } from 'rxjs/operators';
import { AuthenticationService } from './auth.service';
import { CacheService } from './cache.service';
import { ConfigService } from './config.service';
import { DeviceService } from './device.service';
import { IdleService } from './idle.service';
import { ProductService } from './product.service';
import { ProfileService } from './profile.service';
import { ServiceHelper } from './serviceHelper';
import { ITowerStatus } from './simTowerDetail';
import { TokenService } from './token.service';
import { TowerService } from './tower.service';
import { Store } from '@ngxs/store';
import { ProductState } from '../store/state/product.state';
import { GetUserProfileSuccess, SetTokens } from '../core/store/actions/auth.actions';
import { environment } from 'src/environments/environment';
import { AxiomContactMediumModel, AxiomIUserDetail } from '../shared/interfaces/axiom/axiom.user.interfaces';
import { couldStartTrivia } from 'typescript';
import { UIState } from '../shared/store/state/ui.state';
import { AuthState, AuthStateModel } from '../core/store/state/auth.state';
import { CoreState } from '../core/store/state/core.state';
import { UnassignSIMModel } from '../store/state/services.state';

export interface PrimaryModel {
  msisdn: string;
  assignee_email: string;
  first_name: string;
}

export interface ScheduleAction {
  customerId: string;
  transactionType: string;
  currentProduct: string;
  newProduct?: string;
  serviceId: string;
}
@Injectable({
  providedIn: 'root'
})
export class UserService {
  email: string;
  public onServiceUpdated: Subject<string> = new Subject<string>();
  public onUserDetailUpdated: Subject<void> = new Subject<void>();

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private tokenService: TokenService,
    private profileService: ProfileService,
    private deviceService: DeviceService,
    private productService: ProductService,
    private authService: AuthenticationService,
    private cacheService: CacheService,
    private idleTimeoutService: IdleService,
    private datePipe: DatePipe,
    private configSVC: ConfigService,
    private store: Store,
    private towerService: TowerService
  ) { }

  public getUserId(): string {
    const token = this.tokenService.get(CACHE_AUTH_TOKEN);

    if (isNil(token)) {
      return null;
    }
    
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);

    return decodedToken.user_id;
  }
  public getServicePolicySpeed() : Observable<any>{
    const url = `${this.configSVC.BASE_API_URL}/V1/rain-web/fc/service-policy`
    
    let uiMode = this.store.selectSnapshot(UIState.GetUIMode);
    let smeToken = this.store.selectSnapshot(AuthState.getSmeToken)

    let userToken = ((uiMode !== 'consumer')&&smeToken) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: userToken
      })
    };

    return this.http.get(url, httpOptions)
  }

  public getActiveServices(ignoreCache: boolean = false): Observable<Result<IServiceDetail[]>> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    return this.getServices(ignoreCache).pipe(
      map(x => {
        if (x.status != Statuses.Success) {
          return x;
        }

        const activeServices = x.value.filter(y => y.status == ServiceStatuses.Active);

        return Result.success(activeServices);
      })
    );
  }

  public getServiceOnlineStatus(imsi: string) {
    const requestPath = `${this.configSVC.BASE_API_URL}/V1/rain-web/connection-service/imsi/${imsi}/status`

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'apikey': this.configSVC.API_KEY,
        'Authorization': this.tokenService.get(CACHE_IDM_TOKEN)
      })
    };

    return this.http.get(requestPath, httpOptions)
  }

  public getServiceConnectedTower(imsi: string) {
    const requestPath = `${this.configSVC.BASE_API_URL}/V1/rain-web/connection-service/imsi/${imsi}/location`

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'apikey': this.configSVC.API_KEY,
        'Authorization': this.tokenService.get(CACHE_IDM_TOKEN)
      })
    };

    return this.http.get(requestPath, httpOptions)
  }

  public getServices(ignoreCache: boolean = false, token?: string ): Observable<Result<IServiceDetail[]>> {
    let userMode = this.store.selectSnapshot(CoreState.GetUserRole)
    let uiMode = this.store.selectSnapshot(UIState.GetUIMode)
    let smeToken = this.store.selectSnapshot(AuthState.getSmeToken)
    let userToken = this.tokenService.getTokenForAccountType();
  

    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    if (ignoreCache === false && this.cacheService.exists(CACHE_SERVICES)) {
      const services = this.cacheService.getObject<IServiceDetail[]>(CACHE_SERVICES);

      services.forEach(service => {
        service.addons.forEach(addon => {
          addon.effectiveDate = isNil(addon.effectiveDate) ? null : ModelHelper.toDate(addon.effectiveDate.toString());
          addon.expiryDate = isNil(addon.expiryDate) ? null : ModelHelper.toDate(addon.expiryDate.toString());
        });

        service.activationDate = isNil(service.activationDate)
          ? null
          : ModelHelper.toDate(service.activationDate.toString());

        service.expiryDate = isNil(service.expiryDate) ? null : ModelHelper.toDate(service.expiryDate.toString());
      });

      this.idleTimeoutService.resetTimer();

      return of(Result.success(services));
    }
    const requestPath = `${this.configSVC.SERVICE_PROXY_API}/service`;
    // const requestPath = `https://sit-bss-api.rain.network/bss-api/v1/service-proxy/service`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization:userToken
      })
    };

    return this.http.get(requestPath, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((result: any) => {

        if (isNil(result) ||isNil(result.result) ) {
          return [];
        }

        const services = result.result.map(remoteService => ServiceDetail.adapt(remoteService));

        return services;
      }),

      mergeMap((services: IServiceDetail[]) => {
        if (isEmpty(services)) {
          return of([]);
        }

        const msisdns: string[] = uniq(
          services.filter(x => isNil(x.msisdn) === false && isEmpty(x.msisdn) === false).map(x => x.msisdn)
        );

        const observables = msisdns.map(msisdn => this.deviceService?.get(msisdn, true));

        return forkJoin(observables).pipe(
          map(phoneDetailResults => {
            phoneDetailResults.forEach(phoneDetailResult => {
              if (phoneDetailResult.status == Statuses?.Success) {
                const phoneDetail: IDeviceDetail = phoneDetailResult?.value;
                // remove cancelled services as we don't display them
                services = services.filter((svc) => svc.status !== 6);
                
                const selectedServiceDetail = services.find((x: IServiceDetail) => x?.msisdn === phoneDetail?.msisdn );

                if (isNil(selectedServiceDetail) == false) {
                  selectedServiceDetail.model = phoneDetail.model;
                  selectedServiceDetail.imei = phoneDetail.imei;
                }
              }
            });

            return services;
          })
        );
      }),
      mergeMap((services: IServiceDetail[]) => {
        if (isEmpty(services)) {
          return of(Result.success([]));
        }

        services.forEach(service => {
          const product: any = this.store.selectSnapshot(ProductState.GetCatelogueProducts).find((product: any) => product?.id == service?.productId)
          if (product && !service?.productName) {
            service.productName = product?.name
          }

        });

        let productIds = services.map(service => service?.productId);

        const addOnIds = services
          .filter(service => !isNil(service?.addons))
          .reduce((arr: string[], service: IServiceDetail) => {
            const addonIds = service?.addons?.map(addOns => addOns.id);
            return arr.concat(addonIds);
          }, []);

        productIds = uniq(productIds?.concat(addOnIds));

        const observables = productIds?.map(id => this.productService.getById(id));

        return forkJoin(observables).pipe(
          map(productDetailResults => {
            productDetailResults.forEach(productDetailResult => {
              if (productDetailResult?.status == Statuses?.Success) {
                const productDetail = productDetailResult?.value;

                const selectedServiceDetails = services.filter(
                  IServiceDetail => IServiceDetail.productId === productDetail?.id
                );
                selectedServiceDetails.forEach(element => {
                  element.productName = productDetail?.name;
                });

                const productAddOns = services
                  .filter(IServiceDetail => !isNil(IServiceDetail.addons))
                  .reduce((arr, service) => arr.concat(service?.addons?.map(addOns => addOns)), [])
                  .filter(addon => addon.id == productDetail?.id);

                productAddOns.forEach(addon => {
                  addon.productName = productDetail?.name;
                });
              } else {
                services.forEach(service => {
                  const product: any = this.store.selectSnapshot(ProductState.GetCatelogueProducts).find((product: any) => product?.id == service?.productId)
                  if (product && !service?.productName) {
                    service.productName = product?.name
                  }

                });
              }
            });

            this.cacheService.setObject(CACHE_SERVICES, services);

            return Result.success(services);
          })
        );
      }),
      catchError(result => ServiceHelper.handleError<IServiceDetail[]>(result))
    );
  }

  public getService(id: string, ignoreCache: boolean = false): Observable<Result<IServiceDetail>> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    const singleServiceCacheKey = this.singleServiceCacheKey(id);

    if (ignoreCache === false && this.cacheService.exists(singleServiceCacheKey)) {
      const service = this.cacheService.getObject<IServiceDetail>(singleServiceCacheKey);

      service.addons.forEach(addon => {
        addon.effectiveDate = isNil(addon.effectiveDate) ? null : ModelHelper.toDate(addon.effectiveDate.toString());
        addon.expiryDate = isNil(addon.expiryDate) ? null : ModelHelper.toDate(addon.expiryDate.toString());
      });

      service.activationDate = isNil(service.activationDate)
        ? null
        : ModelHelper.toDate(service.activationDate.toString());

      this.idleTimeoutService.resetTimer();

      return of(Result.success(service));
    }

    const requestPath = `${this.configSVC.SERVICE_PROXY_API}/service/${id}`

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.get(requestPath, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((res: any) => {
        const service = ServiceDetail.adapt(res?.result);

        return service;
      }),
      mergeMap((service: IServiceDetail) => {
        return this.deviceService.get(service.msisdn, true).pipe(
          map(result => {
            if (result.status == Statuses.Success) {
              const phoneDetail = result?.value;

              if (phoneDetail.msisdn == service.msisdn) {
                service.model = phoneDetail?.model;
                service.imei = phoneDetail?.imei;
              }
            }

            return service;
          })
        );
      }),
      mergeMap((service: IServiceDetail) => {
        let productIds = [service.productId];

        const addOnIds = isNil(service.addons) ? [] : service.addons.reduce((arr, addon) => arr.concat(addon.id), []);

        productIds = uniq(productIds.concat(addOnIds));

        const observables = productIds.map(id => this.productService.getById(id));

        return forkJoin(observables).pipe(
          map(productDetailResults => {
            productDetailResults.forEach(productDetailResult => {
              if (productDetailResult?.status === Statuses?.Success) {
                const productDetail = productDetailResult?.value;

                if (service?.productId == productDetail?.id) {
                  service.productName = productDetail?.name;
                }

                const productAddOns = isNil(service.addons)
                  ? []
                  : service.addons.filter(addon => addon?.id == productDetail?.id);

                productAddOns.forEach(element => {
                  element.productName = productDetail?.name;
                });
              }
            });

            this.cacheService.setObject(singleServiceCacheKey, service);

            return Result.success(service);
          })
        );
      }),
      catchError(result => ServiceHelper.handleError<IServiceDetail>(result))
    );
  }

  public getAssociatedPromoService(orderLineId: string, ignoreCache: boolean = false): Observable<Result<any>> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    const requestPath = `${this.configService.VOUCHER}/query-voucher?orderLine=${orderLineId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.get(requestPath, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((result: any) => Result.success(result)),
      catchError(result => ServiceHelper.handleError<any>(result))
    );
  }
  private determineConnectedTowerStatus(towerStatus: ITowerStatus) {

    const duration = towerStatus?.alarmInfomation?.hasOwnProperty('alarm_run_time') ? towerStatus.alarmInfomation?.alarm_run_time?.split(':').slice(0, 2) : 0
    let hoursDurationInMinutes = Array.isArray(duration) ?  Number(duration[0]) * 60 : duration;
    let minutesDuration = Array.isArray(duration) ? Number(duration[1]) : duration;
    let totalDuration = 0
    hoursDurationInMinutes < 0 ? hoursDurationInMinutes * -1 : hoursDurationInMinutes
    minutesDuration < 0 ? minutesDuration * -1 : minutesDuration

    totalDuration = hoursDurationInMinutes + minutesDuration ;

    if (towerStatus.isLoadshedding && totalDuration > 0)
      return "load_shedding"
    if (towerStatus.isLoadshedding && totalDuration === 0)
      return "flagged"
    if (!towerStatus.isLoadshedding && totalDuration > 0)
      return "site_offline"

    return "online";
  }
  public register(request: UserRegisterRequest) {
    const requestPath = `${this.configService.AXIOM_URL}/digital-identity/digitalIdentity/create`;
    // const requestPath = `https://prod-bss-api.rain.co.za/axiom/digital-identity/digitalIdentity/create`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const body: any = {
      user: {
        first_name: request.firstName,
        last_name: request.lastName,
        email: request.email,
        password: request.password,
        id_number: request.idNumber,
        phone: request.phone,
        vat_number: request.vat_number,
        company_name: request.company_name,
        company_number:request.company_number,
        is_rica_authority: request.is_rica_authority,
        is_sole_proprietor:request.is_sole_proprietor,
        is_business_verified:request.is_business_verified,
      }
    };

    if (isNil(request.address) == false) {
      body.user.ricaAddress = {
        street_number: request.address.streetNumber,
        street_name: request.address.streetName,
        building_name: request.address.buildingName,
        floor_level: request.address.floorLevel,
        unit_number: request.address.unitNumber,
        suburb: request.address.suburb,
        city: request.address.city,
        province: request.address.province,
        postal_code: request.address.postalCode,
        gps_coordinates: {
          longitude: request.address.gps_coordinates.longitude,
          latitude: request.address.gps_coordinates.latitude
        }
      };
    }

    return this.http.post<AxiomContactMediumModel>(requestPath, body, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      switchMap((createdUserResult) => {

        this.store.dispatch(new GetUserProfileSuccess(createdUserResult as any));

        return this.authService.axiomLogin(request.email, request.password).pipe(
          map(idmResult => {
            
            if (idmResult.status != Statuses.Success) {
              throw new Error(idmResult.message);
            }
            return Result.success(idmResult.value);
           
          })
        );
      }),
      catchError(result => ServiceHelper.handleError<string>(result))
    );
  }

  public coverageNotify(request: any): Observable<Result<boolean>> {
    const requestPath = `${this.configService.NOTIFY_COVERAGE_CHECK}/coverage/notify`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_IDM_TOKEN)
      })
    };

    const body = {
      type: request.type,
      first_name: request.firstName,
      last_name: request.lastName,
      telephone: request.telephone,
      email: request.email,
      address: request.address,
      longitude: +request.longitude.toFixed(4),
      latitude: +request.latitude.toFixed(4),
      is_capetown: request.isCpt
    };

    return this.http.post(requestPath, body, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map(result => {
        return Result.success(true);

      }),
      catchError(result => {

        if (result?.status === 409) {
          return of(Result.conflict<boolean>(result));
        } else {

          const errorCode = result.error.error_code;
          if (isNil(errorCode) == false) {
            const errorMessage = result.error.message;

            if (errorCode == 2) {
              return of(Result.conflict<boolean>(errorMessage));
            }

            if (errorCode == 1) {
              return of(Result.invalid<boolean>(errorMessage));
            }
          }

        }

        return ServiceHelper.handleError<boolean>(result);
      })
    );
  }

  public get(ignoreCache: boolean = false, token?: string): Observable<Result<IUserDetail>> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    let uiMode = this.store.selectSnapshot(UIState.GetUIMode)
    let smeToken = this.store.selectSnapshot(AuthState.getSmeToken) || this.tokenService.get(CACHE_SME_TOKEN);
    let orderToken = (uiMode === 'sme' && smeToken) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN)
  
    return this.profileService.get(ignoreCache, token).pipe(
      map(result => {
        if (result) {
          this.email = result.value?.email;
          return result;
        }
      })
    );
  }

  public undoCancelService(id: string): Observable<Result<boolean>> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    const configs = [];

    configs.push({
      name: 'action',
      value: 'undo_cancel'
    });

    const body: any = {
      product_configs: {
        configs
      }
    };

    return this.internalServiceUpdate(id, body);
  }

  public cancelMigration(id: string): Observable<Result<boolean>> {
    
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    const configs = [];

    configs.push({
      name: 'action',
      value: 'cancel_migration'
    });

    const body: any = {
      product_configs: {
        configs
      }
    };

    return this.internalServiceUpdate(id, body);
  }

  public cancelService(id: string, body: any): Observable<Result<boolean>> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    return this.internalServiceUpdate(id, body);
  }

  public addAddonService(id: string, productId: string): Observable<Result<boolean>> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    const configs = [];

    configs.push({
      name: 'action',
      value: 'add_value_add'
    });

    configs.push({
      name: 'type',
      value: 'immediate'
    });

    const body: any = {
      product_configs: {
        value_adds: [
          {
            id: productId
          }
        ],
        configs
      }
    };

    return this.internalServiceUpdate(id, body);
  }

  public removeAddonService(id: string, productId: string): Observable<Result<boolean>> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    const configs = [];

    configs.push({
      name: 'action',
      value: 'remove_value_add'
    });

    configs.push({
      name: 'type',
      value: 'immediate'
    });

    const body: any = {
      product_configs: {
        value_adds: [
          {
            id: productId
          }
        ],
        configs
      }
    };

    return this.internalServiceUpdate(id, body);
  }

  public updateServiceConfig(id: string, name: string, value: string): Observable<Result<boolean>> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    const configs = [];

    configs.push({ name: 'action', value: 'migrate' });
    configs.push({ name, value });

    const body: any = {
      product_configs: { configs }
    };

    return this.internalServiceUpdate(id, body);
  }

  public getCookieStatus(email: string): Observable<any> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    const requestPath = `${this.configService.AXIOM_IDM_URL}/characteristic?emailAddress=${email}&characteristicName=cookiePolicy`;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_IDM_TOKEN)
      })
    };

    return this.http.get(requestPath, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((result: any) => {
        return result;
      })
    );
  }

  public updatePaymentDate(email: string, date: any, token?: string): Observable<any> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    const tokenSme = this.store.selectSnapshot(AuthState.getSmeToken);

    const usedToken = token ?? this.tokenService.get(CACHE_IDM_TOKEN);

    const payloadEmail = token === tokenSme ? email + "_sme" : email;

    const id = usedToken ? JSON.parse(atob(usedToken.split('.')?.[1]))?.user_id : JSON.parse(atob(this.tokenService.get(CACHE_IDM_TOKEN)?.split(".")?.[1]))?.user_id;

    const requestPath = `${this.configService.AXIOM_IDM_URL}/characteristic`;

    const body = {
      id: id,
      email: payloadEmail,
      characteristics: [
        {
          name: 'recurringPaymentDate',
          valueType: 'int',
          value: date,
          lastUpdated: new Date()
        }
      ]
    }
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + usedToken
      })
    };

    return this.http.post(requestPath, body, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((result: any) => {
        return result;
      })
    );
  }

  public acceptCookies(email: string): Observable<any> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    const requestPath = `${this.configService.AXIOM_IDM_URL}/characteristic?emailAddress=${email}&characteristicName=cookiePolicy`;

    const body = {
      emailAddress: email,
      characteristics: [
        {
          name: 'cookiePolicy',
          valueType: 'boolean',
          value: true,
          lastEditedDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd')
        }
      ]
    }
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_IDM_TOKEN)
      })
    };

    return this.http.post(requestPath, body, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((result: any) => {
        return result;
      })
    );
  }

  // TODO : need to pass serviceid instead of msisdn
  public getUsage(msisdn: string, serviceId: string): Observable<Result<IServiceUsageDetail>> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    const billCycles = '0';

    const body = {
      billing_cycle: billCycles,
      billing_item: msisdn,
      // serviceid: serviceId
    };

    // const requestPath = `${this.configService.API_URL}/usage`; // TODO : new endpoint service/id/usage

    const requestPath = `${this.configService.API_URL}/service/${serviceId}/usage `; // TODO : new endpoint service/id/usage

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.post(requestPath, body, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((result: any) => {
        // const serviceUsage = ServiceUsageDetail.adapt(result.response);
        // const serviceUsage = ServiceUsageDetail.adapt(result);

        const serviceUsage = ServiceUsageDetail.adapt(result.response);     // TODO - Need to put back Olso on adat
        // const serviceUsage = ServiceUsageDetail.adapt(result);

        return Result.success(serviceUsage);
      }),
      catchError(result => ServiceHelper.handleError<IServiceUsageDetail>(result))
    );
  }


  public update(request: UserUpdateRequest, token?: string): Observable<Result<boolean>> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    const requestPath = `${this.configService.API_URL}/users/${request.id}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token ?? this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    let dirty = false;

    const user: any = {};

    if (isNil(request.address) == false) {
      user.ricaAddress = {
        street_number: request.address.streetNumber,
        street_name: request.address.streetName,
        building_name: request.address.buildingName,
        floor_level: request.address.floorLevel,
        unit_number: request.address.unitNumber,
        suburb: request.address.suburb,
        city: request.address.city,
        province: request.address.province,
        postal_code: request.address.postalCode
      };

      dirty = true;
    }

    if (isNil(request.firstName) == false) {
      user.first_name = request.firstName;
      dirty = true;
    }

    if (isNil(request.lastName) == false) {
      user.last_name = request.lastName;
      dirty = true;
    }

    if (isNil(request.email) == false) {
      user.email = request.email;
      dirty = true;
    }

    if (isNil(request.phone) == false) {
      user.phone = request.phone;
      dirty = true;
    }

    if (isNil(request.idNumber) == false) {
      user.id_number = request.idNumber;
      dirty = true;
    }

    if (isNil(request.company_name) == false) {
      user.company_name = request.company_name;
      dirty = true;
    }

    if (isNil(request.company_number) == false) {
      user.company_number = request.company_number;
      dirty = true;
    }
    if (isNil(request.vat_number) == false) {
      user.vat_number = request.vat_number;
      dirty = true;
    }
    
    

    if (dirty === false) {
      return of(Result.error('Nothing to update'));
    }

    const body = {
      user
    };

    return this.http.patch(requestPath, body, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((_: any) => {
        this.profileService.clearCache();
        this.onUserDetailUpdated.next();
        return Result.success(true);
      }),
      catchError(result => ServiceHelper.handleError<boolean>(result))
    );
  }

  public updateDigitalIdentity (request: UserRegisterRequest) {
    const requestPath = `${this.configService.AXIOM_URL}digital-identity/digitalIdentity/create`;
    // const requestPath = `https://prod-bss-api.rain.co.za/axiom/digital-identity/digitalIdentity/create`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const body: any = {
      user: {
        first_name: request.firstName,
        last_name: request.lastName,
        email: request.email,
        id_number: request.idNumber,
        phone: request.phone,
        vat_number: request.vat_number,
        company_name: request.company_name,
        company_number:request.company_number,
        is_rica_authority: request.is_rica_authority,
        is_sole_proprietor:request.is_sole_proprietor,
        is_business_verified:request.is_business_verified,
      }
    };

    if (isNil(request.address) == false) {
      body.user.ricaAddress = {
        street_number: request.address.streetNumber ?? "0000",
        street_name: request.address.streetName ?? " ",
        building_name: request.address.buildingName,
        floor_level: request.address.floorLevel,
        unit_number: request.address.unitNumber,
        suburb: request.address.suburb ?? " ",
        city: request.address.city ?? " ",
        province: request.address.province ?? " ",
        postal_code: request.address.postalCode ?? "0000",
        gps_coordinates: {
          longitude: request.address.gps_coordinates.longitude,
          latitude: request.address.gps_coordinates.latitude
        }
      };
    }

    return this.http.post(requestPath, body, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((_: any) => {
        const results = _;
        const { token, tokenCredential } = this.store.selectSnapshot<AuthStateModel>(AuthState);
        this.tokenService.set(CACHE_SME_TOKEN, results.tokenSme);
        this.store.dispatch(new SetTokens(token, tokenCredential, results?.tokenSme));
        this.profileService.clearCache();
        this.onUserDetailUpdated.next();
        return Result.success(true);
      }),
      catchError(result => ServiceHelper.handleError<boolean>(result))
    );

    // return this.http.post<any>(requestPath, body, httpOptions).pipe(
    //   timeout(this.configService.API_TIMEOUT),
    //   switchMap((createdUserResult) => {

    //     //this.store.dispatch(new GetUserProfileSuccess(createdUserResult as any));
    //     return 

    //     return this.authService.axiomLogin(request.email, request.password).pipe(
    //       map(idmResult => {
    //         return Result.success(idmResult.value);
           
    //       })
    //     );
    //   }),
    //   catchError(result => ServiceHelper.handleError<string>(result))
    // );
  }

  public decodeICCID(iccid: string): Observable<Result<string>> {
    const requestPath = this.configService.DEVOPS_URL + '/iccid/decode/?iccid_encoded=' + iccid;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN),
        apiKey: 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH'
      })
    };

    return this.http.get(requestPath, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((response: any) => {
        return Result.success(response.iccid_decoded);
      }),
      catchError(result => ServiceHelper.handleError<string>(result))
    );
  }

  public isICCIDValid(iccid: string): Observable<Result<any>> {
    const requestPath = `${this.configService.API_URL}/service/${iccid}/status`;
    // const requestPath = `${this.configService.DEVOPS_URL}/service/${iccid}/status`;


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apiKey: this.configService.ICCID_KEY,
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)

      })
    };

    return this.http.get(requestPath, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((response: any) => {
        return Result.success(response);
      }),
      catchError(result => ServiceHelper.handleError<boolean>(result))
    );
  }

  public getPrepaidBalance(): Observable<Result<any>> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    const requestPath = `${this.configService.BASE_API_URL}/V1/rain-web/billing/balance`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.get(requestPath, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((result: any) => Result.success(result)),
      catchError(result => ServiceHelper.handleError<any>(result))
    );

  }

  public updatePrepaidBalance(request: any): Observable<Result<any>> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

    const requestPath = `${this.configService.BASE_API_URL}/V1/rain-web/billing/save/balance`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.post(requestPath, request, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((result: any) => Result.success(result)),
      catchError(result => ServiceHelper.handleError<any>(result))
    );

  }

  public migrate(id: string, productId: string): Observable<Result<boolean>> {
    const body = {
      product_configs: {
        configs: [
          {
            name: 'action',
            value: 'product_migration'
          },
          {
            name: 'new_product',
            value: productId
          }
        ]
      }
    };

    return this.internalServiceUpdate(id, body);
  }

  public migrate4GTo5G(id: string, productId: string, deliveryAddress: any): Observable<any> {
    const body = {
      product_configs: {
        configs: [
          {
            name: 'action',
            value: 'migrate_to_5g'
          },
          {
            name: 'migrate_to',
            value: productId
          },
          {
            name: 'sim_name',
            value: ''
          }
        ],
        deliveryAddress,
        delivery: {
          type: 'scheduled',
          time: new Date(),
          instructions: '',
          sales_channel: 'WEB',
          rica_type: 'courier'

        }
      }
    };

    const requestPath = `${this.configService.API_URL}/service_migration/${id}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.post(requestPath, body, httpOptions);
  }

  public getPuk(iccid: string) {

    const idmToken = this.tokenService.get(CACHE_IDM_TOKEN);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.tokenService.get(CACHE_AUTH_TOKEN)}`
      }),
    };

    const requestPath = `${this.configService.BASE_API_URL}/V1/rain-web/resource-manager/pinpuk?iccid=${iccid}`;

    return this.http.get(requestPath, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map(result => {
        return Result.success(result as PukDetails);
      }),
      catchError(result => ServiceHelper.handleError<boolean>(result))
    );
  }

  public isICCIDAvailable(iccid: string): Observable<Result<boolean>> {
    const requestPath = this.configService.DEVOPS_URL + '/iccid/status/?iccid=' + iccid;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN),
        apiKey: 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH'
      })
    };

    return this.http.get(requestPath, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      switchMap((x: { status: boolean; iccid: string }) => {
        return of(Result.success(x.status == false));
      }),
      catchError(result => ServiceHelper.handleError<boolean>(result))
    );
  }

  checkOrderAddress(lat: number, lon: number) {
    const requestPath = `${this.configService.CHECK_ORDER_ADDRESS_URL}/depot/lat/${lat}/lon/${lon}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.head(requestPath, httpOptions);
  }
  checkOrderAddressDepot(lat: number, lon: number, productCategory: string) {
    const requestPath = `${this.configService.CHECK_ORDER_ADDRESS_URL}/depot/check?lat=${lat}&lon=${lon}&productCategory=${productCategory}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.head(requestPath, httpOptions);
  }

  private internalServiceUpdate(id: string, body: any): Observable<Result<boolean>> {
    
    let userId = this.store.selectSnapshot(CoreState.getUserProfile)?.id
    const requestPath = `${this.configService.BASE_API_URL}/bss-api/v1/service-proxy/fullcircle/service/${id}`;

    let uiMode = this.store.selectSnapshot(UIState.GetUIMode);
    let smeToken = this.store.selectSnapshot(AuthState.getSmeToken)

    let userToken = ((uiMode !== 'consumer') && smeToken) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: userToken,
        userId : userId
      })
    };


    return this.http.patch(requestPath, body, httpOptions).pipe(
      timeout(50000),
      map((result: any) => {
        if (result.error) {
          return Result.error<boolean>(result.error);
        }

        this.cacheService.remove(CACHE_SERVICES);

        const singleServiceCacheKey = this.singleServiceCacheKey(id);

        this.cacheService.remove(singleServiceCacheKey);

        this.onServiceUpdated.next(id);

        return Result.success(true);
      }),
      catchError(result => ServiceHelper.handleError<boolean>(result))
    );
  }

  cancel2for1Service(primaryUserId: string, primaryServiceId: string, body: any): Observable<Result<boolean>> {
    const requestPath = `${this.configService.BASE_API_URL}/v1/cancel-voucher?primaryUserId=${primaryUserId}&primaryServiceId=${primaryServiceId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.patch(requestPath, body, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((result: any) => {
        if (result.error) {
          return Result.error<boolean>(result.error);
        }

        this.cacheService.remove(CACHE_SERVICES);

        const singleServiceCacheKey = this.singleServiceCacheKey(primaryServiceId);

        this.cacheService.remove(singleServiceCacheKey);

        this.onServiceUpdated.next(primaryServiceId);

        return Result.success(true);
      }),
      catchError(result => ServiceHelper.handleError<boolean>(result))
    );
  }

  reverse2for1Service(primaryUserId: string, primaryServiceId: string, body: any): Observable<Result<boolean>> {
    const requestPath = `${this.configService.BASE_API_URL}/v1/revoke-cancel?primaryServiceId=${primaryServiceId}&primaryUserId=${primaryUserId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.patch(requestPath, body, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((result: any) => {
        if (result.error) {
          return Result.error<boolean>(result.error);
        }

        this.cacheService.remove(CACHE_SERVICES);

        const singleServiceCacheKey = this.singleServiceCacheKey(primaryServiceId);

        this.cacheService.remove(singleServiceCacheKey);

        this.onServiceUpdated.next(primaryServiceId);

        return Result.success(true);
      }),
      catchError(result => ServiceHelper.handleError<boolean>(result))
    );
  }

  updatePartialUserPaymentDetails(): Observable<Result<boolean>> {
    const requestPath = `${this.configService.RAIN_POS_API_URL}/user/complete`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apiKey: 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.post(requestPath, '', httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((result: any) => {
        if (result.error) {
          return Result.error<boolean>(result.error);
        }
        return Result.success(true);
      }),
      catchError(result => ServiceHelper.handleError<boolean>(result))
    );
  }

  private singleServiceCacheKey(id: string) {
    return CACHE_SERVICE + id;
  }


  recordScheduleActions(action: ScheduleAction) {
    const requestPath = `${this.configService.NOTIFY_URL}/v2/actions/schedule`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.post(requestPath, action, httpOptions);
  }

  getCategories() {
    const requestPath = `${this.configService.CUSTOMER_CONSENT}/category/list/customer/all`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.get(requestPath, httpOptions);
  }

  getCategoryChannels(request: any) {
    const requestPath = `${this.configService.CUSTOMER_CONSENT}/channel/list/all/category`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.post(requestPath, request, httpOptions);
  }

  updateChannelPreference(request: any) {
    const requestPath = `${this.configService.CUSTOMER_CONSENT}/consent`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.post(requestPath, request, httpOptions);
  }

  setChannelPreference(request: any) {
    const requestPath = `${this.configService.CUSTOMER_CONSENT}/preference`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.put(requestPath, request, httpOptions);
  }

  getPreferredChannel() {
    const requestPath = `${this.configService.CUSTOMER_CONSENT}/preference`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.get(requestPath, httpOptions);
  }


  deleteMessage(notificationId: string) {
    const requestPath = `${this.configService.CUSTOMER_CONSENT}/raininbox/delete/message/${notificationId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.delete(requestPath, httpOptions);
  }

  getAllInboxes(request: any) {
    const requestPath = `${this.configService.CUSTOMER_CONSENT}/raininbox/list`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.post(requestPath, request, httpOptions);
  }

  getInboxes(request: any, categoryId: number) {
    const requestPath = `${this.configService.CUSTOMER_CONSENT}/raininbox/list/category/${categoryId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.post(requestPath, request, httpOptions);
  }

  getCustomerMessageUrl(notificationId: string) {
    const requestPath = `${this.configService.CUSTOMER_CONSENT}/raininbox/readmore/id/detail/${notificationId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.get(requestPath, httpOptions);
  }

  countUnreadMessages() {
    const requestPath = `${this.configService.CUSTOMER_CONSENT}/raininbox/count/unread-messages`;

    const token = this.tokenService.getTokenForAccountType();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };

    return this.http.get(requestPath, httpOptions);
  }

  getAllChannels(): any {
    const requestPath = `${this.configService.CUSTOMER_CONSENT}/channel/list/all/category`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.get(requestPath, httpOptions);
  }

  optInPromo(request: any) {
    const requestPath = `${this.configService.CUSTOMER_CONSENT}/consent/category`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.post(requestPath, request, httpOptions);
  }

  getOptInPromoDetails() {
    const requestPath = `${this.configService.CUSTOMER_CONSENT}/consent/category`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.get(requestPath, httpOptions);
  }

  optInWhatsApp(request: any) {
    const requestPath = `${this.configService.CUSTOMER_CONSENT}/consent/channel`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.post(requestPath, request, httpOptions);
  }

  getOptInWhatsAppDetails() {
    const requestPath = `${this.configService.CUSTOMER_CONSENT}/consent/channel`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.get(requestPath, httpOptions);
  }

  markInboxAsRead(request: string[]) {
    const requestPath = `${this.configService.RAIN_INBOX}/raininbox/markRead`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.post(requestPath, request, httpOptions);
  }

  markReadMoreClick(request: string[]) {
    const requestPath = `${this.configService.RAIN_INBOX}/raininbox/markReadMoreClick`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.post(requestPath, request, httpOptions);
  }

  markRainInboxDeepLink(notificationId: string) {
    const requestPath = `${this.configService.REDIRECT_INBOX_URL}/raininbox/${notificationId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.patch(requestPath, {}, httpOptions);
  }

  markEmailDeepLink(notificationId: string) {
    const requestPath = `${this.configService.REDIRECT_EMAIL_URL}/${notificationId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.patch(requestPath, {}, httpOptions);
  }

  markSMSDeepLink(notificationId: string) {
    const requestPath = `${this.configService.REDIRECT_SMS_URL}/${notificationId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.patch(requestPath, {}, httpOptions);
  }

  markWhatsappDeepLink(notificationId: string) {
    const requestPath = `${this.configService.REDIRECT_WHATSAPP_URL}/${notificationId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.patch(requestPath, {}, httpOptions);
  }

  markPushDeepLink(request: any) {
    const requestPath = `${this.configService.REDIRECT_PUSH_URL}/webhook/push-notification/status`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
      })
    };

    return this.http.patch(requestPath, request, httpOptions);
  }

  toggleInternationalCallingCalls(serviceID: string, userID: string, isEnabled: boolean) {
    const url = `${this.configSVC.BASE_API_URL}/V3/fullcircle/service/${serviceID}`
    let toggle = isEnabled === true? "enable": "disable"
    const body = {
      product_configs: {
        configs: [
          {
            name: "action",
            value: "international_access"
          },
          {
            name: "status",
            value: toggle
          }
        ]
      }
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization:  this.tokenService.getTokenForAccountType()
      })
    };

    return this.http.patch(url, body, httpOptions)
  }
  getUserRole(userId: string) {
    const requestPath = `${this.configService.AXIOM_URL}/digital-identity/digitalIdentity/${userId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenService.get(CACHE_Credential_TOKEN)
      })
    };

    return this.http.get(requestPath, httpOptions);
  }

  unAssignSIMService(payload: UnassignSIMModel) {

      const url = `${this.configService.AXIOM_URL}digital-identity/digitalIdentity/unassociate`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: this.tokenService.get(CACHE_Credential_TOKEN)
        })
      };

      return this.http.post(url, payload, httpOptions);

  }

  doSIMSwap() {

    

  }

  getPrimaryAccountHolder(id: string) {

    const url = `${this.configService.AXIOM_URL}digital-identity/assigned/primary/${id}`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: this.tokenService.get(CACHE_Credential_TOKEN)
        })
      };

      return this.http.get<PrimaryModel[]>(url, httpOptions);

  }
}
