import { isEmpty, isNil } from 'lodash';
import { AddressDetail, IAddress } from './addressDetail';
import { ModelHelper } from './modelHelper';
import { IRemoteUser } from './remote/remoteUser';

export class UserDetail {
  public static adapt(remoteUser: IRemoteUser): IUserDetail {
    const addresses =
      isNil(remoteUser.addresses) || isEmpty(remoteUser.addresses)
        ? []
        : remoteUser.addresses.map(remoteAddress => AddressDetail.adapt(remoteAddress));

    return  {
      id: remoteUser.id,
      firstName: remoteUser.first_name,
      lastName: remoteUser.last_name,
      idNumber: remoteUser.id_number,
      email: remoteUser.email,
      phone: remoteUser.phone,
      addresses,
      available4G: remoteUser.available_sims,
      available5G: remoteUser.available_5Gsims,
      available_rainmaker_starter: remoteUser.available_rainmaker_starter,
      available_rainmaker_topup: remoteUser.available_rainmaker_topup,
      advance_billing: remoteUser.advance_billing,
      cash_account: remoteUser.cash_account || false,
      flag: remoteUser.flag,
      user_created_dated: remoteUser.user_created_dated,
      bill_cycle_spec_detail: remoteUser.bill_cycle_spec_detail,
      company_name:remoteUser.company_name,
      company_number:remoteUser.company_number,
      vat_number:remoteUser.vat_number,
      is_rica_authority: remoteUser.is_rica_authority || false,
      is_sole_proprietor: remoteUser.is_sole_proprietor || false,
      is_business_verified:remoteUser.is_business_verified || false
    } as IUserDetail;
  }
}

export interface IUserDetail {
  id: string;
  firstName: string;
  lastName: string;
  idNumber: string;
  email: string;
  phone: string;
  addresses: IAddress[];
  available4G: number;
  available5G: number;
  available_rainmaker_starter: number;
  available_rainmaker_topup: number;
  advance_billing: boolean;
  cash_account: boolean;
  flag: boolean;
  bill_cycle_spec_detail?: BillCycleDetail;
  user_created_dated: string;
  vat_number?: string,
  company_name?: string,
  company_number?:string,
  is_rica_authority?: boolean;
  is_sole_proprietor?: boolean;
  is_business_verified?: boolean;
}

export interface BillCycleDetail {
  bill_cycle_spec: string;
  cycle_period: {
      end: string | number;
      start: number;
  };
}
