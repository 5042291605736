<div class="product-nav-wrapper fixed-top">
    <div class="product-nav-container">
        <div class="product-nav-items" *ngIf="selectedMode$ | async as selectedMode">
            <div class="product-nav-item consumer-img" (click)="selectProduct('consumer')"
                [ngClass]="{'selected': selectedMode === 'consumer'}"></div>
            <div class="product-nav-item work-img" (click)="selectProduct('sme')"
                [ngClass]="{'selected': selectedMode === 'sme'}"></div>
            <div class="product-nav-item mobile-img" (click)="selectProduct('mobile')"
                [ngClass]="{'selected': selectedMode === 'mobile'}"></div>
        </div>

    </div>
</div>