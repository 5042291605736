import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import * as AuthActions from "../actions/auth.actions";
import { AxiomDigitalIdentityUserDetail } from "src/app/shared/interfaces/axiom/axiom.interfaces";

export interface AuthStateModel {
    token: string,
    tokenCredential: string,
    tokenSme: string,
    digital_identity_info: AxiomDigitalIdentityUserDetail,
    has5GPrepaid: boolean
}


@State<AuthStateModel>({
    name: 'AuthState',
    defaults: {
        token: null,
        tokenCredential: null,
        tokenSme: null,
        digital_identity_info: null,
        has5GPrepaid: false 

    }
})

@Injectable({ providedIn: 'root' })

export class AuthState {
    @Selector()
    static getTokens(state: AuthStateModel) {
        return state;
    }
    @Selector()
    static getSmeToken(state: AuthStateModel) {
        return state.tokenSme;
    }
    @Selector()
    static getConsumerToken(state: AuthStateModel) {
        return state.token;
    }
    @Selector()
    static getTokenCredential(state: AuthStateModel) {
        return state.tokenCredential;
    }
    
    @Selector()
    static getDigitalIdentity(state: AuthStateModel) {
        return state.digital_identity_info;
    }

    @Selector()
    static getHasPrepaid(state: AuthStateModel) {
        return state.has5GPrepaid;
    }

    @Action(AuthActions.SetTokens)
    SetTokens(ctx: StateContext<AuthStateModel>, action: AuthActions.SetTokens) {
      ctx.patchState({
        token: action.token,
        tokenCredential : action.tokenCredentials,
        tokenSme : action.tokenSme
      })
    }

    @Action(AuthActions.AssignAxiomUser)
    assignAxiomUser(ctx: StateContext<AuthStateModel>, action: AuthActions.AssignAxiomUser) {
        ctx.patchState({
            digital_identity_info: action.payload
        })
    }

    @Action(AuthActions.AssignHasPrepaidFlag)
    AssignHasPrepaidFlag(ctx: StateContext<AuthStateModel>, action: AuthActions.AssignHasPrepaidFlag) {
        
        console.log({action})
        ctx.patchState({
            has5GPrepaid: action.payload || false
        })
    }

    @Action(AuthActions.ClearTokens)
    clearTokens(ctx: StateContext<AuthStateModel>) {
        ctx.setState({
            token: null,
            tokenCredential: null,
            tokenSme: null,
            digital_identity_info: null,
            has5GPrepaid: false
        })
    }

}