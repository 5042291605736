import { CheckCoverageComponent } from '../../../components/check-coverage/check-coverage.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { SupportService, ISupportItemDetail } from '@services/support.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '@services/auth.service';
import { ElementService } from '@services/element.service';
import { isNil } from 'lodash';
import { Statuses } from '@models/result';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/baseComponent';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ServicesState } from 'src/app/store/state/services.state';
import { CoreState } from 'src/app/core/store/state/core.state';

/**
 * @ignore
 */
@Component({
  selector: 'app-about-my-rain-one-faq',
  templateUrl: './about-my-rain-one-faq.component.html',
  styleUrls: ['./about-my-rain-one-faq.component.scss']
})
export class AboutMyRainOneFAQComponent extends BaseComponent implements OnInit {

  @Input() fromPage: string;

  /**
   *loading @param {boolean}
   */
  loading: boolean;

  /**
   *supportQuestions @param {any}
   */
  supportQuestions: any[] = [
    {
      dataText: "onboarding",
      description: "<h4 class='text-primary'>how do I buy rainone?</h4><ul><li>You can buy rain<span class='font-weight-bold'>one</span>, or upgrade to rain<span class='font-weight-bold'>one</span> from your existing rain plan on the website or by requesting a sales call. Once your order has been processed, we’ll deliver it to you within 3-5 working days. Our courier CCD will RICA you at the door. And once that’s done, you’re good to go. ​</li><li>You can also get rain<span class='font-weight-bold'>one</span> by visiting one of our stores.​</li>",
      name: "onboarding",
      path: "/",
      pathTitle: "onboarding"
    },
    {
      dataText: "RICA",
      description: "<h4 class='text-primary'>what do I need to RICA when the courier arrives with my order?</h4><ul><li>A valid South African ID, South African passport or foreign passport.</li><li>​Proof of address not older than 3 months (like a municipal bill, utility bill, lease agreement or bank statement) that shows your name, address and the date.</li><li>Yourself! You'll need to RICA your SIM yourself — no one can do it on your behalf.</li></ul><h4 class='text-primary'>can I RICA in-store? </h4><p>Yes, you can! When you choose to collect your order in store, you will RICA with one of our sales agents. Please make sure to bring the documents below:</p><ul><li>A valid South African ID, South African passport or foreign passport.</li><li>Proof of address not older than 3 months (like a municipal bill, utility bill, lease agreement or bank statement) that shows your name, address and the date.</li></ul>​",
      name: "RICA",
      path: "/",
      pathTitle: "RICA"
    },
    {
      dataText: "deliveries",
      description: "<h4 class='text-primary'>when will my order arrive?​</h4><ul><li>Online orders take between 3 and 5 working days to be delivered by our courier partner. You can track your delivery as soon as you receive your reference number via SMS from the courier.​</li><li>If you haven't received any communication within 5 working days, please go to my orders and contact support.</li></ul><h4 class='text-primary'>can I pick up my order in store?</h4><ul><li>Yes, you can! Visit any one of our rain stores and be sure to bring your RICA documents along with you so that one of our agents can help you complete the RICA process successfully. You will need: a valid SA ID or passport, proof of address that is dated within the last 3 months and yourself!</li></ul>​<h4 class='text-primary'>how do I change my delivery address?​</h4><ul><li>For online orders, look out for an SMS sent from our courier partner. You can use the reference number in your SMS to contact the courier partner directly.</li></ul>​",
      name: "deliveries",
      path: "/",
      pathTitle: "deliveries"
    },
    {
      dataText: "payment",
      description: "<h4 class='text-primary'>my order payment was unsuccessful... what now?</h4><p>First, please check with your bank that the card you’re using has 3D Secure (you might know it as MasterCard SecureCode or Verified by Visa) – that means it’s enabled for online transactions. If your card is 3D secure and you are still experiencing this problem, please contact your bank to investigate. An unsuccessful transaction is usually the result of one of the following reasons:</p><ul><li>The OTP has not been applied within the necessary timeframe​</li><li>The bank card you are using is from an international bank which rain can’t process</li><li>The card is being used or has been used under a different rain account​</li><li>You've exceeded your online spend limit or you have insufficient funds</li></ul><h4 class='text-primary'>how do I update my payment details?​</h4><p>You can update your bank card details on myrain, under ‘my account’.</p><h4 class='text-primary'>how do I track my refund?​</h4><p>Please note that once you’ve received approval on your refund, it will take between 7 to 10 business days from receipt of confirmation to reflect in your bank account. If you have still not received your refund after this timeframe, please contact us on <a href='tel:081 610 1000​'>081 610 1000​</a></p>​",
      name: "payment",
      path: "/",
      pathTitle: "payment"
    },
   
    {
      dataText: "network",
      description: "<h4 class='text-primary'>how do I check my coverage?</h4><p>Enter your address on <a href='/our-network' class='text-primary text-underline'>our coverage map</a> and use the map key in the corner to see if you’re in 4G/5G home coverage.</p><p>Please note you will need to be in 4G/5G home coverage in order to purchase a rain<span class='font-weight-bold'>one</span> plan.</p><h4 class='text-primary'>I’m connected but can’t get onto the internet?​</h4><p>This might be because of your APN settings. For phones and most other devices your APN settings are automatically set up. If not, the default APN settings are as follows:​</p><p>APN name: rain​<br>All other settings can be left blank​</p><p>If it’s not your APN settings, this might be the cause:​</p><ul><li>You’re using the incorrect device - make sure you’re using a 4G enabled device for your 4G mobile SIM, or rain’s 5G router for your 5G SIM. If you are on a <span class='font-weight-bold'>4G for phones</span> product, your SIM will only work in a 4G phone.​</li><li>You’ve reached your gig limit – you can manage your limit on my rain​</li><li>An unsuccessful debit order – your rain account may be in arrears. Sign in to my rain to pay now and get back online​</li></ul>",
      name: "network",
      path: "/",
      pathTitle: "network"
    },
    {
      dataText: "activations",
      description: "<h4 class='text-primary'>how long will it take for my SIM to activate?</h4><p>Your SIM(s) should be active within 2 hours of successfully completing RICA. We’ll send you an SMS to let you know that your SIM is active. You can also sign in to your myrain page and look out for a green tick next to your SIM in myrain.</p>",
      path: "/",
      name: "activations",
      pathTitle: "join rain "
    }
  ];

  /**
   *isSignedIn @param {false}
   */
  isSignedIn = false;

  

  @Select(ServicesState.hasPostPaid)
  hasPostPaid$: Observable<boolean>

  /**
   * Default methods supportService,Router, ToastrService, elementService, Auth service,  Modal service,
   *
   * @param supportService
   * @param router
   * @param toastr
   * @param elementService
   * @param authService
   * @param modalService
   */
  constructor(
    private supportService: SupportService,
    private router: Router,
    private toastr: ToastrService,
    private elementService: ElementService,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private store: Store
  ) {
    super();
  }

  /**
   * init app check if user is signed in
   */
  ngOnInit() {
    this.isSignedIn = this.authService.isSignedIn;
    this.loading = false;
  }

  signIn() {
    this.router.navigate(['/my-rain']);
  }

  /**
   * open support FAQs when user clicks on markdown
   */
  openSupportItem(item: ISupportItemDetail) {
    if (isNil(item.pathElement) === false) {
      this.elementService.scrollToElement(item.path, item.pathElement, 200, 1000);
    } else if (item.path.includes('https://')) {
      window.open(item.path, '_blank');
    } else {
      this.router.navigateByUrl(item.path);
    }
  }

  /**
   * get supported top 10 Questions
   */
  getSupportQuestions() {
    this.supportService
      .getTop10Questions()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {
        if (result.status !== Statuses.Success) {
          
          this.loading = false;
          setTimeout(() => this.toastr.error('An error occurred while getting the details', null));

          /**
           *@returns {boolean} returns the value
           */
          return;
        } else {
          this.loading = false;
          this.supportQuestions = result.value;
        }
      });
  }

  /**
   * scrolls to anchor shop on FAQs page
   */
  scrollToShop() {
    this.elementService.scrollToShop();
  }

  goTo(link) {
    this.router.navigateByUrl(link);
  }
  getDescription(item: any){
    const hasPostPaid = this.store.selectSnapshot(ServicesState.hasPostPaid);
    if(!hasPostPaid && (item.name.includes('billing') || item.name.includes('switch to'))){
      return  item?.upfrontDescription;
    }
    return item.description;
  }
  /**
   * Sets current location on map using map coordinates of user, when the user has shared their location
   */
  openCoverageMap() {
    this.modalService.open(CheckCoverageComponent, { size: 'xl' as any, windowClass: 'slideInUp' });
  }
}
