import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';
import { IDeliveryAddress } from '@services/cart.service';
import { ModelHelper } from './modelHelper';
import { IRemoteAddress as IRemoteUserAddress } from './remote/remoteAddress';
import { isNil, isEmpty, isNumber } from 'lodash';

export class AddressDetail {
  static fromDelivery(deliveryAddress: IDeliveryAddress): IAddress {
    const userAddress = { ...deliveryAddress };

    delete userAddress.instructions;

    return userAddress;
  }

  public static adapt(remote: IRemoteUserAddress): IAddress {
    let streetName = ModelHelper.clean(remote.street_name);

    let streetNumber = ModelHelper.clean(remote.street_number);

    if (streetName === streetNumber) {
      streetNumber = null;
    }

    if (isNil(streetNumber) || (isEmpty(streetNumber) && !(isNil(streetName) || isEmpty(streetName)))) {
      if (isNil(streetName) == false) {
        let streetNameSegments: string[] = streetName.split(' ');

        if (streetNameSegments.length > 0 && !isNaN(parseInt(streetNameSegments[0], 10))) {
          streetNumber = streetNameSegments[0];

          streetName = streetNameSegments.slice(1).join(' ');
        } else {
          streetNumber = null;
        }
      }
    }

    return {
      streetNumber,
      city: ModelHelper.clean(remote.city),
      country: ModelHelper.clean(remote.country),
      postalCode: ModelHelper.clean(remote.postal_code),
      province: ModelHelper.clean(remote.province),
      streetName,
      suburb: ModelHelper.clean(remote.suburb),
      buildingName: ModelHelper.clean(remote.building_name),
      floorLevel: ModelHelper.clean(remote.floor_level),
      unitNumber: ModelHelper.clean(remote.unit_number)
    };
  }

  static getFormatAddress(deliveryAddress: IAddress): string {

    let parts = [ModelHelper.clean(deliveryAddress.streetNumber),
    ModelHelper.clean(deliveryAddress.streetName),
    ModelHelper.clean(deliveryAddress.suburb),
    ModelHelper.clean(deliveryAddress.city),
    ModelHelper.clean(deliveryAddress.province),
    ModelHelper.clean(deliveryAddress.postalCode)]

    parts = parts.filter(x => isNil(x) == false);

    let formattedAddress = parts.join(" ");

    formattedAddress = formattedAddress.trim();

    return formattedAddress;
  }

  static fromGoogle(address: GoogleAddress): IAddress {

    let addressComponents = address.address_components;

    return AddressDetail.fromAddressComponents(addressComponents);
  }

  static fromGeoCode(geoCoeResult: google.maps.GeocoderResult): IAddress {

    let addressComponents = geoCoeResult.address_components;

    return AddressDetail.fromAddressComponents(addressComponents);
  }


  private static fromAddressComponents(addressComponents: google.maps.GeocoderAddressComponent[]): IAddress {

    let streetNumber: string, streetName: string, suburb: string, city: string, province: string, postalCode: string;

    addressComponents.forEach(element => {
      element.types.forEach(type => {
        if (type === 'administrative_area_level_2') {
          city = element.long_name;
        }
        else if (type === 'administrative_area_level_1') {
          province = element.long_name;
        }
        else if (type === 'street_number') {
          streetNumber = element.long_name;
        }
        else if (type === 'street_name' || type == 'route') {
          streetName = element.long_name;
        }
        else if (type === 'sublocality') {
          suburb = element.long_name;
        }
        else if (type === 'postal_code') {
          postalCode = element.long_name;
        }
      });
    });

    return {
      city: city,
      streetName: streetName,
      streetNumber: streetNumber,
      postalCode: postalCode,
      province: province,
      suburb: suburb
    } as IAddress;
  }
}

export interface IAddress {
  streetNumber: string;
  streetName: string;
  suburb: string;
  city: string;
  province: string;
  postalCode: string;
  country?: string;
  buildingName: string;
  floorLevel: string;
  unitNumber: string;
  gps_coordinates?: GpsLocation
}

export interface GpsLocation {
  latitude: string;
  longitude: string;
}
