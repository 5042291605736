import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { GetPortStatusById } from 'src/app/store/actions/number-porting-actions';
import { ServicesState } from 'src/app/store/state/services.state';

@Component({
  selector: 'rain-port-pending-modal',
  templateUrl: './port-pending-modal.component.html',
  styleUrls: ['./port-pending-modal.component.scss']
})
export class PortPendingModalComponent implements OnInit {
  @Input() id: string;

  constructor(

    public activeModal: NgbActiveModal,
    private store: Store,
    private ngModal: NgbModal,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.store.dispatch(new GetPortStatusById(this.id))
  }
  gotIt() {
    const services = this.store.selectSnapshot(ServicesState.getAllServices)

    //parent service id will be null for 5G services because they are the parent services 
    const parentService = services.find(service => service.parent_service_id === null)
    this.activeModal.close()
    this.router.navigate(['/service',parentService.id])

  }
}
