import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import * as AssignClaimActions from '../actions/assign-claim-sim.actions';
import { CoreState } from 'src/app/core/store/state/core.state';
import { IAssociateStatus } from '@models/asociate-status.interface';
import { map, switchMap, tap } from 'rxjs/operators';
import { error } from 'console';
import { AssignAndClaimSimService } from '@services/assign-sim.service';
import { UserService } from '@services/user.service';
import { Observable, of } from 'rxjs';
import { result } from 'lodash';

export interface AssignAndClaimSimStateModel {
  SaId: string;
  assignSim?: IAssignSim;
  claimSim?: IClaimSim;
  simAssociateStatus?: IAssociateStatus[];
  showAssociateSuccessModal: boolean;
  showClaimSuccessModal: boolean;
}

export interface IClaimSim {
  user_id: string;
  iccid: string;
  extra: {};
}

export interface IAssignSim {
  name: string;
  user_id: string;
  service_id: string;
  email: string;
  message: string;
  extra: {};
}

@State<AssignAndClaimSimStateModel>({
  name: 'AssignAndClaimSIM',
  defaults: {
    SaId: '',
    showAssociateSuccessModal: false,
    showClaimSuccessModal: false
  }
})
@Injectable({ providedIn: 'root' })
export class AssignAndClaimSimState {
  constructor(private store: Store, private assignAndClaimService: AssignAndClaimSimService, private userService: UserService) {}

  @Selector()
  static showClaimSuccessModal(state: AssignAndClaimSimStateModel) {
    return state.showClaimSuccessModal;
  }
  @Selector()
  static showAssignSuccessModal(state: AssignAndClaimSimStateModel) {
    return state.showAssociateSuccessModal;
  }
  @Selector()
  static getSaId(state: AssignAndClaimSimStateModel) {
    return state.SaId;
  }

  @Selector()
  static getAssignedasOwner(state: AssignAndClaimSimStateModel){
    return state.simAssociateStatus.filter((items,index)=> (index===state.simAssociateStatus.length-1))[0]
  }


  @Action(AssignClaimActions.SetSaId)
  setSaId(ctx: StateContext<AssignAndClaimSimStateModel>, action: AssignClaimActions.SetSaId) {
    ctx.patchState({
      SaId: action.id
    });
  }

  @Action(AssignClaimActions.DismissModals)
  DismissModals(ctx: StateContext<AssignAndClaimSimStateModel>, action: AssignClaimActions.DismissModals){
    ctx.patchState({
        showAssociateSuccessModal: false,
        showClaimSuccessModal: false
    })
  }

  @Action(AssignClaimActions.AssociateSim)
  associateSim(ctx: StateContext<AssignAndClaimSimStateModel>, action: AssignClaimActions.AssociateSim) {
    const body: IAssignSim = {
      name: action.formPayload.name,
      user_id: this.store.selectSnapshot(CoreState.getUserProfile).id,
      service_id: action.formPayload.serviceId,
      email: action.formPayload.email,
      message: action.formPayload.message,
      extra: {}
    };
    return this.assignAndClaimService.associateSim(body).pipe(
      tap({
        next: () => this.store.dispatch(new AssignClaimActions.AssociateSimSuccess(body)),
        error: err => this.store.dispatch(new AssignClaimActions.AssociateSimFailure(err))
      })
    );
  }
  @Action(AssignClaimActions.AssociateSimSuccess)
  associateSimSuccess(ctx: StateContext<AssignAndClaimSimStateModel>, action: AssignClaimActions.AssociateSimSuccess) {
    ctx.patchState({
      assignSim: action.body,
      showAssociateSuccessModal: true
    });
  }
  @Action(AssignClaimActions.AssociateSimFailure)
  associateSimFailure(ctx: StateContext<AssignAndClaimSimStateModel>, action: AssignClaimActions.AssociateSimFailure) {
    console.log(action.error);
  }

  @Action(AssignClaimActions.ClaimSim)
  claimSim(ctx: StateContext<AssignAndClaimSimStateModel>, action: AssignClaimActions.ClaimSim) {
    const saId = this.store.selectSnapshot(AssignAndClaimSimState.getSaId);
    let decodedICCID;
    
    return this.userService.decodeICCID(action.iccid).pipe(
      map(res => {
        decodedICCID = res.value;
        const body: IClaimSim = {
          user_id: this.store.selectSnapshot(CoreState.getUserProfile).id,
          iccid: decodedICCID,
          extra: {}
        };

        return body;
      }),
      switchMap((body: IClaimSim) => {
        return this.assignAndClaimService.claimSim(saId, body).pipe(
          tap({
            next: () => {
              this.store.dispatch(new AssignClaimActions.ClaimSimSuccess(body));
            },
            error: error => {
              this.store.dispatch(new AssignClaimActions.ClaimSimFailure(error));
            }
          })
        );
      })
    );
  }
  @Action(AssignClaimActions.ClaimSimSuccess)
  claimSimSuccess(ctx: StateContext<AssignAndClaimSimStateModel>, action: AssignClaimActions.ClaimSimSuccess) {
    ctx.patchState({
      claimSim: action.body,
      showClaimSuccessModal: true
      
    });
  }
  @Action(AssignClaimActions.ClaimSimFailure)
  claimSimFailure(ctx: StateContext<AssignAndClaimSimStateModel>, action: AssignClaimActions.ClaimSimFailure) {
    console.log(action.error);
  }

  @Action(AssignClaimActions.AssociateStatusBySaId) //SaId Service Assoctiate ID
  AssociateStatusBySaId(ctx: StateContext<AssignAndClaimSimStateModel>, action: AssignClaimActions.AssociateStatusBySaId) {
    return this.assignAndClaimService.getPendingAssignmentBySaId(action.saId).pipe(
      tap({
        next: res => {
          this.store.dispatch(new AssignClaimActions.CheckAssociateStatusSuccess(res));
        },
        error: err => {
          this.store.dispatch(new AssignClaimActions.CheckAssociateStatusFailure(err));
        }
      })
    );
  }

  @Action(AssignClaimActions.AssociateStatusByServiceId)
  AssociateStatusByServiceId(ctx: StateContext<AssignAndClaimSimStateModel>, action: AssignClaimActions.AssociateStatusByServiceId) {
    return this.assignAndClaimService.getPendingAssignmentByServiceId(action.serviceId).pipe(
      tap({
        next: res => {
          this.store.dispatch(new AssignClaimActions.CheckAssociateStatusSuccess(res));
        },
        error: err => {
          this.store.dispatch(new AssignClaimActions.CheckAssociateStatusFailure(err));
        }
      })
    );
  }
  @Action(AssignClaimActions.CheckAssociateStatusSuccess)
  checkAssociateStatusSuccess(ctx: StateContext<AssignAndClaimSimStateModel>, action: AssignClaimActions.CheckAssociateStatusSuccess) {
    ctx.patchState({
      simAssociateStatus: action.result.result
    });
  }
  @Action(AssignClaimActions.CheckAssociateStatusFailure)
  checkAssociateStatusFailure(ctx: StateContext<AssignAndClaimSimStateModel>, action: AssignClaimActions.CheckAssociateStatusFailure) {
    console.log(action.error);
  }

  @Action(AssignClaimActions.RemovePendingAssociate)
  removePendingAssociation(ctx: StateContext<AssignAndClaimSimStateModel>, action: AssignClaimActions.RemovePendingAssociate) {
    return this.assignAndClaimService.removeAssociateRqst(action.id).pipe(
      tap({
        next: () => {
          this.store.dispatch(new AssignClaimActions.RemovePendingAssociateSuccess());
        },
        error: err => {
          this.store.dispatch(new AssignClaimActions.RemovePendingAssociateFailure(err));
        }
      })
    );
  }
  @Action(AssignClaimActions.RemovePendingAssociateSuccess)
  removePendingAssociationSuccess(ctx: StateContext<AssignAndClaimSimStateModel>, action: AssignClaimActions.RemovePendingAssociateSuccess) {
    /// open modal?
  }
  @Action(AssignClaimActions.RemovePendingAssociateFailure)
  removePendingAssociationFailure(ctx: StateContext<AssignAndClaimSimStateModel>, action: AssignClaimActions.RemovePendingAssociateFailure) {
    console.log(action.error);
  }
}