<ng-container *ngIf="{ap: ap$ | async} as viewModel">

    <div *ngIf="uiMode !== 'consumer'" [class]="'spacer is-'+viewModel.ap"></div>
    <rain-sticky-nav class="sticky-nav"
                     (requestSalesCallTriggered)="handleRequestSalesCallFromSticky()"
                     (playVideoTrigger)="handlePlayVideoTrigger()">
        <div Title class="sticky-nav-grid__content pr-10-custom text-primary hide-mobile">
            <span class="sticky-text-1"><span class="text-primary">rain<span class="font-weight-bold">one</span></span> for<span
                    class="font-weight-bold" *ngIf="(selectedMode$ | async) === 'consumer'">&nbsp;home</span> <span
                    class="font-weight-bold" *ngIf="(selectedMode$ | async) === 'sme'">&nbsp;work</span>&nbsp;&<span
                    class="font-weight-bold">&nbsp;phones</span></span>
        </div>
        <div ButtonBlue class="pl-2 justify-item-left">
            <button class="btn d-block btn-md btn-primary mb-3 mt-3 button3" (click)="goToWizard()"><span class="copy">buy now</span>
            </button>
        </div>
    </rain-sticky-nav>

    <rain-product-nav></rain-product-nav>

    <rain-rain-one-purchase [position]="1"></rain-rain-one-purchase>

    <section *ngIf="viewModel.ap" [class]="'page-lockup is-'+viewModel.ap" id="home-scroller">
    </section>

    <!-- sat20 promo -->
    <section class="mb-10" *ngIf="(flags$ | async)?.sa_t20_promo">
        <rain-sa20-banner-billing [page]="'home'"></rain-sa20-banner-billing>
    </section>

    <rain-carousel-one></rain-carousel-one>

    <rain-rain-one-purchase [position]="2"></rain-rain-one-purchase>
    <!-- <rain-rain-one-wifi-days></rain-rain-one-wifi-days>  -->

    <!-- skin colors options are in the below component -->
    <rain-router-skin-options></rain-router-skin-options>

    <!-- for your phone section 2 free sims   -->
    <section class="section-phones-sims">
        <div class="phones-sims-grid ">
            <div class="phones-sims-grid-item text-grid">
                <div class="free-calls-heading">
    <span *ngIf=" (selectedMode$ |async) === 'consumer'">
      you get <span class="bold-text">FREE</span> calls <br>& data every month
    </span>
                    <span *ngIf=" (selectedMode$ |async) === 'sme'">
      <span class="bold-text">keep collaborating</span> with <br> <span class="bold-text">FREE</span> calls & data for 5 phones
    </span>

                </div>
                <div class="sims-heading">
                    <ul>
                        <li><span class="bold-text" *ngIf=" (selectedMode$ |async) === 'consumer'"><b>2</b> </span>
                            <span class="bold-text" *ngIf=" (selectedMode$ |async) === 'sme'"><b>5</b></span> <b>
                                FREE</b> 4G mobile SIMs
                        </li>
                        <li>Each SIM comes with:<br>2GB | 60min | 100sms <b>FREE every month</b></li>
                        <li>National 4G mobile coverage</li>
                        <li>HD voice calls with VoLTE</li>
                    </ul>

                </div>

            </div>
            <div class="phones-sims-grid-item img-right">
                <div class="sims-4image">
                    <img *ngIf=" (selectedMode$ |async) === 'sme'"
                         src="assets/images/rain-101/homepage/4g-mobile-sim.png" alt="the101">
                    <img *ngIf=" (selectedMode$ |async) === 'consumer'"
                         src="assets/images/rain-101/homepage/4g-mobile-sim2.png" alt="the101">
                </div>
            </div>

        </div>
        <div class="section-phones-terms">
            <div class="terms-lockup">
                <a (click)="gotoTerms();">Ts & Cs apply</a>
            </div>
        </div>
    </section>
    <section class="rainGo-ge-force-section">
        <div *ngIf=" (selectedMode$ |async) === 'consumer'" class="rainGo-ge-force-grid-container">
            <div class="rainGo-container">
                <div class="top-position-wrapper">
                    <div class="buy-more-container">
                        <span><img class="rainGo-logo-img" src="assets/images/rain-101/homepage/rainGO-logo.svg" alt=""></span>
                        <p class="buy-more-text"><span class="bold-text">buy more</span> <br> data & minutes <br> on the
                            go</p>
                    </div>
                    <div class="rainGo-phone-img">
                        <img src="assets/images/rain-101/homepage/rainGOPhone-8.png" alt="">
                    </div>
                </div>
                <div class="bottom-position-wrapper">
                    <div class="section-phones-terms">
                        <div class="terms-lockup">
                            <a (click)="gotoTerms();">Ts & Cs apply</a>
                        </div>
                    </div>
                    <a class="rainGo-button" (click)="openRainGoIfSignedIn()"><span>go to rain<strong>GO</strong></span></a>

                </div>
            </div>
            <div class="ge-force-container">
                <div class="geforce-details-wrapper">
                    <div class="geforce-details">
                        <img src="assets/images/nvidia/desktop-logo.svg" alt="">
                        <p> GeForce NOW <br> <span> powered by </span> <img src="assets/images/rain-101/rain_logo.svg"
                                                                            alt=""></p>
                        <div class="join-button-container">
                            <button (click)="routeToNvidia()">join now</button>
                        </div>
                    </div>
                    <div class="geforce-image">
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf=" (selectedMode$ |async) === 'sme'" class="rainGo-tile-work">
            <div class="rainGo-text-container">
                <div class="rainGo-text-wrapper">
                    <img class="rainGo-logo-img" src="assets/images/rain-101/homepage/rainGO-logo.svg" alt="">
                    <p class="buy-more-text-work"><span class="bold-text">buy more</span> data, minutes & sms, and <span
                            class="bold-text">assign</span> your mobile SIMs to others </p>
                    <div class="section-phones-terms">
                        <div class="terms-lockup">
                            <a (click)="gotoTerms();">Ts & Cs apply</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rainGo-img-container">
                <div class="rainGo-mobile-img-wrapper">
                    <img class="rainGo-mobile-img" src="assets/images/rain-101/homepage/rainGOPhone-11.png" alt="">
                    <div class="rainGo-btn-wrapper">
                        <a class="rainGo-button"
                           (click)="openRainGoIfSignedIn()"><span>go to rain<strong>GO</strong></span></a>
                    </div>
                </div>

            </div>
        </div>
    </section>
</ng-container>