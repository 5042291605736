import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LimitSalesTicketCreationState} from '@components/sales-modal/store/state/limit-ticket-creation.state';
import {Select, Store} from '@ngxs/store';
import {FirebaseConfigsState} from 'src/app/core/store/state/firebase-configs.state';
import {Observable, Subject} from 'rxjs';
import {CoreState} from 'src/app/core/store/state/core.state';
import {UIActions} from 'src/app/shared/store/actions/ui.actions';
import {UIMode, UIState} from 'src/app/shared/store/state/ui.state';
import {RainFlags} from 'src/app/core/interfaces/rain-flags.interface';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'rain-rain-one-purchase',
    templateUrl: './rain-one-purchase.component.html',
    styleUrls: ['./rain-one-purchase.component.scss']
})
export class RainOnePurchaseComponent implements OnInit {
    @Select(LimitSalesTicketCreationState.isTicketCreationAllowed) isTicketCreationAllowed$: Observable<boolean>;
    @Select(CoreState.activeBreakpoint) activeBreakpoint$: Observable<string>;
    @Select(UIState.GetUIMode) selectedMode$: Observable<UIMode>;
    @Select(FirebaseConfigsState.getFlags) flags$: Observable<RainFlags>;
    private unsubscribe$: Subject<void> = new Subject<void>();
    selectedUiMode = this.store.selectSnapshot(UIState.GetUIMode)
    home_work_mode_selector: boolean;
    @Input() position;


    constructor(
        private router: Router,
        private store: Store
    ) {
    }

    ngOnInit(): void {
        this.store.dispatch(new UIActions.SwitchUIMode({mode: this.selectedUiMode}));
        this.flags$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((flags: RainFlags) => {
                this.home_work_mode_selector = flags?.home_work_mode_selector;
            });
    }

    gotoTerms() {
        this.router.navigateByUrl('/legal?section=terms');
    }

    setProductType(type: UIMode) {
        this.store.dispatch(new UIActions.SwitchUIMode({mode: type}));
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
