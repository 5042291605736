
/**
 * link to figma to see temapltes - https://www.figma.com/file/N1Ipm987EWX2X1i9Oild6u/the101-PROTOTYPE?type=design&node-id=227%3A42&mode=design&t=4Bglg6eXEKIuqaIf-1
 */
export enum Rain101Templates {
    Promo = 1, // miles template - mobi figma tile 1
    Right_Aligned_Image_Left_Aligned_Text = 2, //KG template - mobi figma tile 2
    Centered_Image_Left_Aligned_Text = 3, // miles template - mobi figma tile 3
    Full_Width_Image_Centered_Title = 4, // Jaco's template - mobi figma tile 4
    Centered_Image_Large_Left_Aligned_Text = 5, // Jaco's template - mobi figma tile 5
    Centered_Small_Image_Left_Aligned_Text = 6, // Jaco's template - mobi figma tile 6
    Grid_column_left_copy_overlapping_image = 7,
    Grid_row_with_image_bottom = 8,
    Left_Aligned_Image = 9,
    Level_Up = 10,
    Powerful_wifi = 11,
    GeForce = 12,
    GeForceNowHere = 13
}