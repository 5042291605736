import { Injectable } from '@angular/core';
import { flattenDeep, isNil } from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CONFIG_POSITION, CONFIG_SPEND_LIMIT, DEFAULT_TARRIF } from '@models/constants';
import { IProductDetail, IProductItemDetail, ProductCategories, ProductTypes } from '@models/productDetail';
import { Statuses } from '@models/result';
import { CartItem, ICartItemMapped } from './cart.service';
import { ProductService } from './product.service';
import { UserService } from './user.service';
import { AuthenticationService } from './auth.service';
import { IAnalyticsBundleProductItem, IAnalyticsCartBundleProductItem, IAnalyticsCartProduct, IAnalyticsCartProductBundle, IAnalyticsProduct, IAnalyticsPromoProduct } from '@models/analysticsProduct';
import { Store } from '@ngxs/store';
import { ProductState } from '../store/state/product.state';
import { CartState } from '../store/state/cart.state';
import { TotalDueNowPipe } from '../shared/pipes/total-due-now.pipe';
import { CookieService } from './cookie.service';
import { CoreState } from '../core/store/state/core.state';
import { ServicesState } from '../store/state/services.state';
import { UIState } from '../shared/store/state/ui.state';

interface DataLayerCartItems {
  item_name: string,
  id: string,
  item_brand: string,
  price: string | number,
  currency: string
}

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  /**
   * This was implemented for analytics tracing on modals
   * across the site
   */
  modalTitleMap = {
    'sign-in': 'Sign In',
    'forgot-password': 'Forgot Password',
    '5g-coverage-check': '5g: Coverage Check',
    'unsuccessful-coverage-result': 'Unsuccessful Coverage Check Result',
    'set-peak-gig-limit': 'Set a peak GIG limit',
    'coverage-check': 'Coverage Check',
    'successful-coverage-result': 'Successful Coverage Check Result',
    'unlimited-home-5G-standard-in-coverage': '5g Promo: Unlimited home 5G standard',
    'unlimited-home-5G-standard-in-coverage-gotSim': '5g Promo: Unlimited home 5G standard - GOTSIM',
    'unlimited-4g-for-homes': 'Unlimited 4G for phones',
    'cart-empty': 'Cart: empty',
    'my-account/contact-support': 'Contact: My Account Support',
    'change-password': 'My Account - Change Password',
    'contact/billing-support': 'Contact: Billing Support',
    'contact/network-support': 'Contact: Network Support',
    'my-account/tax-invoice': 'My Account: Tax invoice',
    'cancel-service': 'My Account: Cancel Service'
  };

  push(event: any) {
    window.dataLayer.push(event);
  }

  constructor(
    private productService: ProductService,
    private userService: UserService,
    private authService: AuthenticationService,
    private store: Store,
    private totalDueNowPipe: TotalDueNowPipe,
    private cookieService: CookieService
  ) {}

  private mapCategory(category: ProductCategories): string {
    switch (category) {
      case ProductCategories.Device:
        return 'device';
      case ProductCategories.FiveG:
        return '5G';
      case ProductCategories.FourG:
        return '4G';
      case ProductCategories.ServiceFee:
        return 'serviceCredit';
      case ProductCategories.None:
        return 'none';
    }
  }

  /*==============================*/
  /*========update - 2024=========*/
  /*==============================*/

  /**
   * Tracking for when a user views a new page on the site
   * @param page_location URL path including parameters
   * @param page_title page title as requested for measurement plan
   * @param user_id dynamic ID if a user is logged in, otherwise blank string
   * @param user_type guest / existing customer / guest
   */
  pageView(page_location: string, page_title: string, user_id?: string, user_type?: 'guest' | 'new customer' | 'existing cutsomer') {
    window.dataLayer.push({
      page_location,
      page_title,
      user_id: !user_id ? '' : user_id,
      user_type: !user_id ? 'guest' : 'existing customer',
      event: 'page_view'
    });
  }

  selectContent(color: string) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'select_content',
      ecommerce: {
        content_type: 'rain one router skin',
        content_colour: color
      }
    });
  }

  addToCart() {
    let dataLayerProducts: DataLayerCartItems[] = this.formatDataLayerProducts(this.store.selectSnapshot(CartState.getDataLayerItems));

    const totalFullPrice = dataLayerProducts.reduce((n, { price }) => n + Number(price), 0);

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        currency: 'ZAR',
        value: totalFullPrice,
        items: [...dataLayerProducts]
      }
    });
  }

  viewCart() {
    const dataLayerProducts = this.formatDataLayerProducts(this.store.selectSnapshot(CartState.getDataLayerItems));

    const totalFullPrice = dataLayerProducts.reduce((n, { price }) => n + Number(price), 0);

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'view_cart',
      ecommerce: {
        currency: 'ZAR',
        value: totalFullPrice,
        items: [...dataLayerProducts]
      }
    });
  }

  removeFromCart() {
    const dataLayerProducts = this.formatDataLayerProducts(this.store.selectSnapshot(CartState.getDataLayerItems));

    const totalFullPrice = dataLayerProducts.reduce((n, { price }) => n + Number(price), 0);

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'remove_from_cart',
      ecommerce: {
        currency: 'ZAR',
        value: totalFullPrice,
        items: [...dataLayerProducts]
      }
    });
  }

  selectPaymentDate() {
    const dataLayerProducts = this.formatDataLayerProducts(this.store.selectSnapshot(CartState.getDataLayerItems));

    const totalFullPrice = dataLayerProducts.reduce((n, { price }) => n + Number(price), 0);

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'select_payment_date',
      ecommerce: {
        currency: 'ZAR',
        value: totalFullPrice,
        items: [...dataLayerProducts]
      }
    });
  }

  beginCheckkout() {
    const dataLayerProducts = this.formatDataLayerProducts(this.store.selectSnapshot(CartState.getDataLayerItems));

    const totalFullPrice = dataLayerProducts.reduce((n, { price }) => n + Number(price), 0);

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        currency: 'ZAR',
        value: totalFullPrice,
        items: [...dataLayerProducts]
      }
    });
  }

  signUp(method: 'SA ID' | 'Passport') {
    window.dataLayer.push({
      method: method,
      event: 'sign_up',
      user_id: this.authService.isSignedIn ? this.userService.getUserId() : ''
    });
  }

  coverageSearch() {
    window.dataLayer.push({
      event: 'coverage_search',
      user_id: this.authService.isSignedIn ? this.userService.getUserId() : ''
    });
  }

  // -------

  // orderCollection(selectedStore) {
  //   const dataLayerProducts = this.formatDataLayerProducts(this.store.selectSnapshot(CartState.getDataLayerItems))

  //   const totalFullPrice = dataLayerProducts.reduce((n, {total_product_value}) => n + total_product_value, 0)

  //   window.dataLayer.push({ ecommerce: null });
  //   window.dataLayer.push({
  //     event: "order_collection",
  //     ecommerce: {
  //       currency: "ZAR",
  //       value: totalFullPrice,
  //       items: [...dataLayerProducts],
  //       fulfillment_type: "collection",
  //       collection_method: selectedStore
  //     }
  //   });
  // }

  // -------

  addShippingInformation(deliveryInfo) {
    const dataLayerProducts = this.formatDataLayerProducts(this.store.selectSnapshot(CartState.getDataLayerItems));

    const totalFullPrice = dataLayerProducts.reduce((n, { price }) => n + Number(price), 0);

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'add_shipping_info',
      ecommerce: {
        currency: 'ZAR',
        value: totalFullPrice,
        items: [...dataLayerProducts],
        fulfillment_type: 'delivery',
        shipping_information: deliveryInfo
      }
    });
  }

  addPaymentInformation() {
    const dataLayerProducts = this.formatDataLayerProducts(this.store.selectSnapshot(CartState.getDataLayerItems));

    const totalFullPrice = dataLayerProducts.reduce((n, { price }) => n + Number(price), 0);

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'add_payment_info',
      ecommerce: {
        currency: 'ZAR',
        value: totalFullPrice,
        items: [...dataLayerProducts],
        payment_type: 'card'
      }
    });
  }

  purchase(transaction_id: string) {
    const dataLayerProducts = this.formatDataLayerProducts(this.store.selectSnapshot(CartState.getDataLayerItems));

    const totalFullPrice = dataLayerProducts.reduce((n, { price }) => n + Number(price), 0);
    // const tax = (dataLayerProducts.reduce((n, {price}) => n + price, 0) / 115) * 15

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: transaction_id,
        value: totalFullPrice,
        currency: 'ZAR',
        items: [...dataLayerProducts]
      }
    });
  }

  purchaseFailure(transaction_id: string, fail_issue?: any) {
    const dataLayerProducts = this.formatDataLayerProducts(this.store.selectSnapshot(CartState.getDataLayerItems));

    const totalFullPrice = dataLayerProducts.reduce((n, { price }) => n + Number(price), 0);

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'purchase_fail',
      ecommerce: {
        transaction_id: transaction_id ?? '',
        value: totalFullPrice,
        currency: 'ZAR',
        items: [...dataLayerProducts],
        fail_issue: fail_issue ?? ''
      }
    });
  }

  // -------
  // coverageNotificationForm() {
  //   window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  //   window.dataLayer.push({
  //     event: "contact_notify_coverage",
  //     user_id: this.authService.isSignedIn ? this.userService.getUserId() : ''
  //   });
  // }
  // -------

  login() {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'login',
      user_id: this.userService.getUserId(),
      method: 'email'
    });
  }

  requestSalesCall(leadID: string, productName: string) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      lead_id: leadID,
      user_id: this.authService.isSignedIn ? this.userService.getUserId() : '',
      event: 'generate_lead'
    });
  }

  // -------
  // clickToCall() {
  //   window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  //   window.dataLayer.push({
  //     event: "click_to_call",
  //     user_id: this.authService.isSignedIn ? this.userService.getUserId() : '',
  //   });
  // }
  // -------

  whatsappOptIn() {
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'whatsapp_opt_in',
      user_id: this.authService.isSignedIn ? this.userService.getUserId() : ''
    });
  }

  addToNvidiaWaitingList() {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'nvidia-waiting-list',
      value: {
        method: 'email',
        user_id: this.authService.isSignedIn ? this.userService.getUserId() : ''
      }
    });
  }

  private formatDataLayerProducts(dataLayerProducts: any[]): DataLayerCartItems[] {
    const rainOneInCart = this.store.selectSnapshot(CartState.GetSelectedRainOneBundle);
    const hasActiveRainOne = this.store.selectSnapshot(ServicesState.hasActiveRainOne5GService);
    const cartCategory = this.store.selectSnapshot(UIState.GetUIMode);
    const items = dataLayerProducts.map(product => {
      const totalProductValue =
        product?.item_brand?.toLocaleLowerCase() === 'nvidia' && (rainOneInCart || hasActiveRainOne)
          ? product?.totalProductValue * 0.8
          : product?.totalProductValue;

      let item_categories = [];

      if (product?.item_categories) {
        item_categories = Object.keys(product?.item_categories).map((category, index) => {
          const i = index ? `${index + 1}` : '';
          return { [`item_category${i}`]: product?.item_categories[category] };
        });
      }

      const itemName = product?.item_name ? product?.item_name : product?.name;
      const priceToDisplay = totalProductValue ? totalProductValue : product?.price;

      const item = {
        item_name: itemName,
        currency: 'ZAR',
        id: product?.id,
        price: priceToDisplay,
        item_brand: cartCategory
      };

      item_categories.forEach(itemCategory => {
        Object.assign(item, itemCategory);
      });
      return item;
    });

    return items;
  }

  /*==============================*/
  /*========update - 2024=========*/
  /*==============================*/

  cartStep(name: string, stepNumber: number, mappedCartItems: ICartItemMapped[]): Observable<boolean> {
    return this.productService.get().pipe(
      map(result => {
        if (result.status != Statuses.Success) {
          return false;
        }

        const products = result.value;
        let analyticProducts = mappedCartItems.map(mappedCartItem => {
          const { product, item: cartItem } = mappedCartItem;

          if (product.type === ProductTypes.Bundle || (product.type as any) === 'bundle') {
            return product.items.map(item => {
              const itemProduct = products.find(x => {
                if (x.id === item.id) return x;
              });

              const limit = cartItem.config[CONFIG_SPEND_LIMIT] ? parseInt(cartItem.config[CONFIG_SPEND_LIMIT], 10) : null;
              if (itemProduct) {
                return {
                  name: isNil(itemProduct?.tag) ? itemProduct?.name : itemProduct?.tag,
                  id: itemProduct.sku,
                  quantity: 1,
                  variant: limit ? limit / DEFAULT_TARRIF + 'GB' : null
                };
              }
            });
          } else {
            const limit = cartItem.config[CONFIG_SPEND_LIMIT] ? parseInt(cartItem.config[CONFIG_SPEND_LIMIT], 10) : null;

            const productItems = [];

            productItems.push({
              name: isNil(product.tag) ? product.name : product.tag,
              id: product.sku,
              quantity: 1,
              variant: limit ? limit / DEFAULT_TARRIF + 'GB' : null
            });

            product.addons?.forEach(addon => {
              const addonProduct = products.find(x => x.id === addon.id && addon.optional == false);

              if (isNil(addonProduct) == false) {
                productItems.push({
                  name: isNil(addonProduct.tag) ? addonProduct.name : addonProduct.tag,
                  id: addonProduct.sku,
                  quantity: 1,
                  variant: limit ? limit / DEFAULT_TARRIF + 'GB' : ''
                });
              }
            });

            return productItems;
          }
        });

        const combined = flattenDeep(analyticProducts);

        // window.dataLayer.push({
        //   event: "checkout",
        //   ecommerce: {
        //     checkout: {
        //       actionField: {
        //         step: stepNumber,
        //         option: name
        //       },
        //       products: combined
        //     }
        //   }
        // });

        return true;
      })
    );
  }

  productView(product: IProductDetail, location: string): Observable<boolean> {
    return this.productService.get().pipe(
      map(result => {
        if (result.status != Statuses.Success) {
          return false;
        }

        const products = result.value;

        let dataLayerProducts = [];

        const position = product.config[CONFIG_POSITION] ? product.config[CONFIG_POSITION].value : '';

        if (product.type == ProductTypes.Bundle) {
          dataLayerProducts = product.items
            .filter(x => x.category != ProductCategories.ServiceFee)
            .map(x => ({
              name: isNil(x.tag) ? x.name : x.tag,
              id: x.sku,
              list: location,
              position: position
            }));
        } else {
          dataLayerProducts.push({
            name: isNil(product.tag) ? product.name : product.tag,
            id: product.sku,
            list: location,
            position: position
          });

          product.addons?.forEach(addon => {
            const addonProduct = products.find(x => x.id === addon.id && addon.optional == false);

            if (isNil(addonProduct) == false) {
              dataLayerProducts.push({
                name: isNil(addonProduct.tag) ? addonProduct.name : addonProduct.tag,
                id: addonProduct.sku,
                list: location,
                position: position
              });
            }
          });
        }

        // window.dataLayer.push({
        //   'ecommerce': {
        //     'currencyCode': 'ZAR',
        //     'impressions': dataLayerProducts
        //   }
        // });

        return true;
      })
    );
  }

  productsView(products: IProductDetail[], location: string): Observable<boolean> {
    return this.productService.get().pipe(
      map(result => {
        if (result.status != Statuses.Success) {
          return false;
        }

        const allProducts = result.value;

        let dataLayerProducts = [];

        products.forEach(product => {
          const position = product.config[CONFIG_POSITION] ? product.config[CONFIG_POSITION].value : '';

          if (product.type == ProductTypes.Bundle) {
            dataLayerProducts.push(
              ...product.items
                .filter(x => x.category != ProductCategories.ServiceFee)
                .map(x => ({
                  name: isNil(x.tag) ? x.name : x.tag,
                  id: x.sku,
                  list: location,
                  position: position
                }))
            );
          } else {
            dataLayerProducts.push({
              name: isNil(product.tag) ? product.name : product.tag,
              id: product.sku,
              list: location,
              position: position
            });

            product.addons.forEach(addon => {
              const addonProduct = allProducts.find(x => x.id === addon.id && addon.optional == false);

              if (isNil(addonProduct) == false) {
                dataLayerProducts.push({
                  name: isNil(addonProduct.tag) ? addonProduct.name : addonProduct.tag,
                  id: addonProduct.sku,
                  list: location,
                  position: position
                });
              }
            });
          }
        });

        // window.dataLayer.push({
        //   'ecommerce': {
        //     'currencyCode': 'ZAR',
        //     'impressions': dataLayerProducts
        //   }
        // });

        return true;
      })
    );
  }

  // addToCart(cartItem: CartItem): Observable<boolean> {

  //   const productId = cartItem.productId;

  //   const config = cartItem.config;

  //   return this.productService.get()
  //     .pipe(
  //       map(result => {

  //         if (result.status != Statuses.Success) {
  //           return false;
  //         }

  //         const products = result.value;

  //         const product = products.find(x => x.id == productId);

  //         const dataLayerProducts = [];

  //         if(product){
  //           if (product.type === ProductTypes.Bundle || 'bundle') {

  //             product.items?.forEach(item => {

  //               const itemProduct = result.value.find(x => x.id === item.id)

  //               if (isNil(itemProduct) == false) {

  //                 let totalGigs = 0;

  //                 if (isNil(itemProduct?.config) == false &&
  //                   isNil(itemProduct.config[CONFIG_SPEND_LIMIT]) == false &&
  //                   isNil(config[CONFIG_SPEND_LIMIT]) == false) {

  //                   totalGigs = parseInt(config[CONFIG_SPEND_LIMIT], 10);
  //                 }

  //                 dataLayerProducts.push({
  //                   name: isNil(itemProduct.tag) ? itemProduct.name : itemProduct.tag,
  //                   id: itemProduct.sku,
  //                   variant: totalGigs,
  //                   quantity: 1
  //                 });

  //               }

  //             });

  //           } else {

  //             let totalGigs = 0;

  //             if (isNil(product.config) == false &&
  //               isNil(product.config[CONFIG_SPEND_LIMIT]) == false &&
  //               isNil(config[CONFIG_SPEND_LIMIT]) == false) {

  //               totalGigs = parseInt(config[CONFIG_SPEND_LIMIT], 10);
  //             }

  //             // dataLayerProducts.push({
  //             //   name: isNil(product.tag) ? product.name : product.tag,
  //             //   id: product.sku,
  //             //   variant: totalGigs,
  //             //   quantity: 1
  //             // });

  //             product.addons?.forEach(addon => {

  //               const addonProduct = products.find(x => x.id === addon.id && addon.optional == false);

  //               if (isNil(addonProduct) == false) {

  //                 let totalGigs = 0;

  //                 if (isNil(addonProduct.config) == false &&
  //                   isNil(addonProduct.config[CONFIG_SPEND_LIMIT]) == false &&
  //                   isNil(config[CONFIG_SPEND_LIMIT]) == false) {

  //                   totalGigs = parseInt(config[CONFIG_SPEND_LIMIT], 10);
  //                 }

  //                 // dataLayerProducts.push({
  //                 //   name: isNil(addonProduct.tag) ? addonProduct.name : addonProduct.tag,
  //                 //   id: addonProduct.sku,
  //                 //   variant: totalGigs,
  //                 //   quantity: 1
  //                 // });
  //               }

  //             });

  //           }
  //         }

  //         // window.dataLayer.push({
  //         //   event: "addToCart",
  //         //   ecommerce: {
  //         //     currencyCode: "ZAR",
  //         //     add: {
  //         //       products: dataLayerProducts
  //         //     }
  //         //   }
  //         // });

  //         return true;
  //       })
  //     );
  // }

  // removeFromCart(cartItem: CartItem): Observable<boolean> {

  //   const productId = cartItem.productId;

  //   const config = cartItem.config;

  //   return this.productService.get()
  //     .pipe(
  //       map(result => {

  //         if (result.status != Statuses.Success) {
  //           return false;
  //         }

  //         const products = result.value;

  //         const product = products.find(x => x.id == productId);

  //         const dataLayerProducts = [];

  //         if (product?.type === ProductTypes.Bundle || 'bundle') {

  //           product.items?.forEach(item => {

  //             const itemProduct = result.value.find(x => x.id === item.id)

  //             let totalGigs = 0;

  //             if (isNil(itemProduct?.config) == false &&
  //               isNil(itemProduct.config[CONFIG_SPEND_LIMIT]) == false &&
  //               isNil(config[CONFIG_SPEND_LIMIT]) == false) {

  //               totalGigs = parseInt(config[CONFIG_SPEND_LIMIT], 10);
  //             }

  //             // dataLayerProducts.push({
  //             //   name: isNil(itemProduct.tag) ? itemProduct.name : itemProduct.tag,
  //             //   id: itemProduct.sku,
  //             //   variant: totalGigs,
  //             //   quantity: 1
  //             // });

  //           });

  //         } else {

  //           let totalGigs = 0;

  //           if (isNil(product.config) == false &&
  //             isNil(product.config[CONFIG_SPEND_LIMIT]) == false &&
  //             isNil(config[CONFIG_SPEND_LIMIT]) == false) {

  //             totalGigs = parseInt(config[CONFIG_SPEND_LIMIT], 10);
  //           }

  //           // dataLayerProducts.push({
  //           //   name: isNil(product.tag) ? product.name : product.tag,
  //           //   id: product.sku,
  //           //   variant: totalGigs,
  //           //   quantity: 1
  //           // });

  //           product.addons.forEach(addon => {

  //             const addonProduct = result.value.find(x => x.id === addon.id && addon.optional == false);

  //             if (isNil(addonProduct) == false) {

  //               let totalGigs = 0;

  //               if (isNil(addonProduct.config) == false &&
  //                 isNil(addonProduct.config[CONFIG_SPEND_LIMIT]) == false &&
  //                 isNil(config[CONFIG_SPEND_LIMIT]) == false) {

  //                 totalGigs = parseInt(config[CONFIG_SPEND_LIMIT], 10);
  //               }

  //               // dataLayerProducts.push({
  //               //   name: isNil(addonProduct.tag) ? addonProduct.name : addonProduct.tag,
  //               //   id: addonProduct.sku,
  //               //   variant: totalGigs,
  //               //   quantity: 1
  //               // });
  //             }

  //           });

  //         }

  //         // window.dataLayer.push({
  //         //   event: "removeFromCart",
  //         //   ecommerce: {
  //         //     currencyCode: "ZAR",
  //         //     remove: {
  //         //       products: dataLayerProducts
  //         //     }
  //         //   }
  //         // });

  //         return true;
  //       })
  //     );
  // }

  productClick(productId: string): Observable<boolean> {
    return this.productService.get().pipe(
      map(result => {
        if (result.status != Statuses.Success) {
          return false;
        }

        const products = result.value;

        const product = products.find(x => x.id == productId);

        const dataLayerProducts = [];

        let position = 0;

        if (isNil(product.config) == false && isNil(product.config[CONFIG_POSITION]) == false) {
          position = parseInt(product.config[CONFIG_POSITION].value, 10);
        }

        if (product.type === ProductTypes.Bundle || 'bundle') {
          product.items.forEach(item => {
            const itemProduct = result.value.find(x => x.id === item.id);

            let totalGigs = 0;

            if (
              isNil(itemProduct?.config) == false &&
              isNil(itemProduct.config[CONFIG_SPEND_LIMIT]) == false &&
              isNil(itemProduct.config[CONFIG_SPEND_LIMIT]['default']) == false
            ) {
              totalGigs = parseInt(itemProduct.config[CONFIG_SPEND_LIMIT]['default'], 10);
            }

            dataLayerProducts.push({
              name: isNil(item.tag) ? item.name : item.tag,
              id: item.sku,
              variant: totalGigs,
              position: position
            });
          });
        } else {
          let totalGigs = 0;

          if (
            isNil(product.config) == false &&
            isNil(product.config[CONFIG_SPEND_LIMIT]) == false &&
            isNil(product.config[CONFIG_SPEND_LIMIT]['default']) == false
          ) {
            totalGigs = parseInt(product.config[CONFIG_SPEND_LIMIT]['default'], 10);
          }

          dataLayerProducts.push({
            name: isNil(product.tag) ? product.name : product.tag,
            id: product.sku,
            variant: totalGigs,
            position: position
          });

          product.addons.forEach(addon => {
            const addonProduct = result.value.find(x => x.id === addon.id && addon.optional == false);

            if (isNil(addonProduct) == false) {
              let totalGigs = 0;

              if (
                isNil(addonProduct.config) == false &&
                isNil(addonProduct.config[CONFIG_SPEND_LIMIT]) == false &&
                isNil(addonProduct.config[CONFIG_SPEND_LIMIT]['default']) == false
              ) {
                totalGigs = parseInt(addonProduct.config[CONFIG_SPEND_LIMIT]['default'], 10);
              }

              dataLayerProducts.push({
                name: isNil(addonProduct.tag) ? addonProduct.name : addonProduct.tag,
                id: addonProduct.sku,
                variant: totalGigs,
                position: position
              });
            }
          });
        }

        return true;
      })
    );
  }

  // New implementation starts here

  /**
   * Return the index on an Object by it's value
   */
  getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  getTotalPrice(values: number[]) {
    return values.reduce((a, b) => a + b, 0);
  }

  /**
   * Returns the category of a product for Data Layer Analytics
   */
  getProductCategory(product: IProductItemDetail): string {
    if (product.category === ProductCategories.Device) {
      return 'device';
    } else if (product.category === ProductCategories.FourG) {
      return '4G';
    } else if (product.category === ProductCategories.FiveG) {
      return '5G';
    } else if (product.category === ProductCategories.RainMaker) {
      return 'Rainmaker';
    } else if (product.category === ProductCategories.ServiceFee) {
      return 'Service Fee';
    } else {
      return 'none';
    }
  }

  // productCategoryMap = ['none', '5G', '4G', 'device', 'Service fee', 'Rain maker'];

  productCategoryMap = {
    none: 'none',
    '5G': '5G',
    '4G': '4G',
    device: 'device',
    serviceFee: 'Service Fee',
    rainMaker: 'Rain maker'
  };

  productTypeMap = {
    none: 0,
    product: 1,
    variant: 2,
    bundle: 3,
    ServiceFee: 4,
    delivery: 5
  };

  /**
   * For tracking all modal popups excluding small alert boxes or error/confirm messages
   */
  modalView(page_title: string) {
    let user_id = '';

    if (this.authService.isSignedIn) {
      user_id = this.userService.getUserId();
    }

    const pageLocationSlug = this.getKeyByValue(this.modalTitleMap, page_title);

    const dataLayerObj = {
      page_location: `/modal/${pageLocationSlug}`,
      page_title,
      user_id,
      user_type: user_id === '' ? 'guest' : 'existing customer',
      event: 'page_view'
    };

    window.dataLayer.push(dataLayerObj);
  }

  /**
   * Implemented to track the list of products
   * visible to the user - excludes promo items
   */
  viewItemList(products: IProductDetail[], location: string) {
    // Clear previous ecommerce object
    window.dataLayer.push({ ecommerce: null });

    let items: IAnalyticsProduct[] = [];

    products?.forEach(i => {
      const productIsNotOnPromotion = !i.config.promo?.value;

      let productCategory: string;
      let productIndex: number;

      i.items.forEach((productItem, index) => {
        if (productItem.category === 1 || productItem.category === 2) {
          productCategory = this.getProductCategory(productItem);
          productIndex = index;
        }
      });

      if (productIsNotOnPromotion) {
        items.push({
          id: i.items[productIndex] ? i.items[productIndex].id : i.id,
          name: i.name,
          list: location,
          brand: 'rain',
          position: i.config.position?.value,
          price: i.price,
          category: productCategory ? productCategory : this.productCategoryMap[i.category]
        });
      }
    });

    const dataLayerObj = {
      event: 'view_item_list',
      ecommerce: {
        currencyCode: 'ZAR',
        impressions: items
      }
    };

    window.dataLayer.push(dataLayerObj);
  }

  selectItem(products: IAnalyticsProduct[]) {
    // Clear previous ecommerce object
    window.dataLayer.push({ ecommerce: null });

    window.dataLayer.push({
      event: 'select_item',
      ecommerce: {
        click: {
          actionField: {
            list: products[0].list
          },
          products
        }
      }
    });
  }

  viewItem(products: IAnalyticsProduct[]) {
    // Clear previous ecommerce object
    window.dataLayer.push({ ecommerce: null });

    window.dataLayer.push({
      event: 'view_item',
      ecommerce: {
        detail: {
          actionfield: {
            list: products[0].list
          },
          products
        }
      }
    });
  }

  viewPromotion(products: IAnalyticsPromoProduct[]) {
    window.dataLayer.push({
      event: 'view_promotion',
      ecommerce: {
        promoView: {
          promotions: products
        }
      }
    });
  }

  selectPromotion(products: IAnalyticsPromoProduct[]) {
    window.dataLayer.push({
      event: 'select_promotion',
      ecommerce: {
        promoClick: {
          promotions: products
        }
      }
    });
  }

  productAddToCart(product: IProductDetail) {
    window.dataLayer.push({ ecommerce: null });

    const productType = this.productTypeMap[this.getKeyByValue(this.productTypeMap, product.type)];

    if (productType === this.productTypeMap.product) {
      const productObj: IAnalyticsCartProduct = {
        brand: 'rain',
        category: this.getProductCategory(product.items[0]),
        id: product.items[0].id,
        name: product.name,
        price: product.price,
        quantity: 1
      };

      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          currencyCode: 'ZAR',
          add: {
            products: [productObj]
          }
        }
      });
    } else if (productType === this.productTypeMap.ServiceFee) {
      const categoryIdIndex = product.items.findIndex((i: IProductItemDetail) => {
        return i.category === 1 || i.category === 2;
      });

      if (categoryIdIndex > -1) {
        const productObj: IAnalyticsCartProduct = {
          brand: 'rain',
          id: product.items[categoryIdIndex].id,
          name: product.name,
          category: this.getProductCategory(product.items[categoryIdIndex]),
          price: product.price,
          quantity: 1
        };

        window.dataLayer.push({
          event: 'add_to_cart',
          ecommerce: {
            currencyCode: 'ZAR',
            add: {
              products: [productObj]
            }
          }
        });
      }
    }
  }

  // viewCart(products: IProductDetail[]) {
  //   const values = products.map((i: IProductDetail) => {
  //     return i.price;
  //   });
  //   const productsObj: IAnalyticsCartProduct[] = [];

  //   products.forEach((i: IProductDetail) => {

  //     const categoryIdIndex = i?.items?.findIndex(
  //       (i: IProductItemDetail) => {
  //         return i.category === 1 || i.category === 2;
  //       }
  //     );

  //     if (categoryIdIndex > -1) {

  //       const productObj: IAnalyticsCartProduct = {
  //         brand: 'rain',
  //         id: i.items[categoryIdIndex].id,
  //         name: i.name,
  //         category: this.getProductCategory(i.items[categoryIdIndex]),
  //         price: i.price,
  //         quantity: 1
  //       }
  //       productsObj.push(productObj);
  //     }

  //   });

  //   window.dataLayer.push({ ecommerce: null });
  //   window.dataLayer.push({
  //     event: 'view_cart',
  //     ecommerce: {
  //       currencyCode: 'ZAR',
  //       value: this.getTotalPrice(values),
  //       view: {
  //         products: productsObj
  //       }
  //     }
  //   })
  // }

  productRemoveFromCart(product: IProductDetail) {
    window.dataLayer.push({ ecommerce: null });

    const categoryIdIndex = product.items?.findIndex((i: IProductItemDetail) => {
      return i.category === 1 || i.category === 2;
    });

    if (categoryIdIndex > -1) {
      const productObj: IAnalyticsCartProduct = {
        brand: 'rain',
        id: product.items[categoryIdIndex].id,
        name: product.name,
        category: this.getProductCategory(product.items[categoryIdIndex]),
        price: product.price,
        quantity: 1
      };

      window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
          currencyCode: 'ZAR',
          remove: {
            products: [productObj]
          }
        }
      });
    }
  }

  startCheckOut(products: IProductDetail[]) {
    const productsObj: IAnalyticsCartProduct[] = [];

    products?.forEach((i: IProductDetail) => {
      if (i && i?.items) {
        const categoryIdIndex = i?.items?.findIndex((i: IProductItemDetail) => {
          return i?.category === 1 || i?.category === 2;
        });

        if (categoryIdIndex > -1) {
          const productObj: IAnalyticsCartProduct = {
            name: i?.name,
            id: i?.items?.[categoryIdIndex]?.id,
            price: i?.price,
            brand: 'rain',
            category: this.getProductCategory(i?.items?.[categoryIdIndex]),
            quantity: 1
          };

          productsObj.push(productObj);
        }
      }
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        currencyCode: 'ZAR',
        checkout: {
          actionField: { step: 1 },
          products: productsObj
        }
      }
    });
  }

  checkoutShippingInfo(products: IProductDetail[]) {
    const productsObj: IAnalyticsCartProduct[] = [];

    products.forEach((i: IProductDetail) => {
      const categoryIdIndex = i?.items?.findIndex((i: IProductItemDetail) => {
        return i?.category === 1 || i?.category === 2;
      });

      if (categoryIdIndex > -1) {
        const productObj: IAnalyticsCartProduct = {
          name: i?.name,
          id: i?.items[categoryIdIndex].id,
          price: i?.price,
          brand: 'rain',
          category: this.getProductCategory(i?.items?.[categoryIdIndex]),
          quantity: 1
        };

        productsObj.push(productObj);
      }
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'add_shipping_info',
      ecommerce: {
        currencyCode: 'ZAR',
        checkout: {
          actionField: { step: 2 },
          products: productsObj
        }
      }
    });
  }

  checkoutPaymentInformation(products: IProductDetail[]) {
    const productsObj: IAnalyticsCartProduct[] = [];

    products?.forEach((i: IProductDetail) => {
      const categoryIdIndex = i?.items?.findIndex((i: IProductItemDetail) => {
        return i?.category === 1 || i?.category === 2;
      });

      if (categoryIdIndex > -1) {
        const productObj: IAnalyticsCartProduct = {
          name: i?.name,
          id: i?.items[categoryIdIndex].id,
          price: i?.price,
          brand: 'rain',
          category: this.getProductCategory(i?.items[categoryIdIndex]),
          quantity: 1
        };

        productsObj.push(productObj);
      }
    });

    window?.dataLayer?.push({ ecommerce: null });
    window?.dataLayer?.push({
      event: 'add_payment_info',
      ecommerce: {
        currencyCode: 'ZAR',
        checkout: {
          actionField: { step: 3 },
          products: productsObj
        }
      }
    });
  }

  // purchase(products: ICartItemMapped[], orderId: any) {
  //   const productsObj: IAnalyticsCartProduct[] = [];

  //   const productsArray: IProductDetail[] = products?.map(item => {
  //     return item?.product;
  //   })

  //   productsArray?.forEach((i: IProductDetail) => {

  //     const categoryIdIndex = i?.items?.findIndex(
  //       (i: IProductItemDetail) => {
  //         return i?.category === 1 || i?.category === 2;
  //       }
  //     );

  //     if (categoryIdIndex > -1) {

  //       const productObj: IAnalyticsCartProduct = {
  //         name: i?.name,
  //         id: i?.items[categoryIdIndex]?.id,
  //         price: i?.price,
  //         brand: 'rain',
  //         category: this.getProductCategory(i?.items?.[categoryIdIndex]),
  //         quantity: 1,
  //         coupon: ''
  //       }

  //       productsObj?.push(productObj);
  //     }
  //   });

  //   const priceValues = products?.map((product: ICartItemMapped) => {
  //     return product?.product?.price;
  //   });

  //   window.dataLayer.push({ ecommerce: null });
  //   window.dataLayer.push({
  //     event: 'purchase',
  //     ecommerce: {
  //       purchase: {
  //         actionField: {
  //           id: orderId,
  //           affiliation: 'rain: web',
  //           revenue: this.getTotalPrice(priceValues),
  //           tax: '',
  //           shipping: '',
  //           coupon: ''
  //         },
  //         products: productsObj
  //       }
  //     }
  //   })
  // }

  purchaseFailed(products: ICartItemMapped[], orderId: any) {
    const productsObj: IAnalyticsCartProduct[] = [];

    const productsArray: IProductDetail[] = products.map(item => {
      return item.product;
    });

    productsArray.forEach((i: IProductDetail) => {
      const categoryIdIndex = i.items.findIndex((i: IProductItemDetail) => {
        return i.category === 1 || i.category === 2;
      });

      if (categoryIdIndex > -1) {
        const productObj: IAnalyticsCartProduct = {
          name: i.name,
          id: i.items[categoryIdIndex].id,
          price: i.price,
          brand: 'rain',
          category: this.getProductCategory(i.items[categoryIdIndex]),
          quantity: 1,
          coupon: ''
        };

        productsObj.push(productObj);
      }
    });

    const priceValues = products.map((product: ICartItemMapped) => {
      return product.product.price;
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'purchase_failed',
      revenue: this.getTotalPrice(priceValues),
      id: orderId,
      fail_issue: '',
      ecommerce: {
        products: productsObj
      }
    });
  }

  forgotPasswordRequest() {
    window.dataLayer.push({
      event: 'reset_password_request'
    });
  }

  forgotPassword() {
    window.dataLayer.push({
      event: 'reset_password'
    });
  }

  activateSIMCheck(status: string) {
    window.dataLayer.push({
      sim_check_status: status,
      event: 'sim_check'
    });
  }

  viewInvoice() {
    window.dataLayer.push({
      event: 'view_invoice'
    });
  }

  coverageMapSearch(type) {
    window.dataLayer.push({
      coverage_search_type: type,
      event: 'coverage_search'
    });
  }

  myAccountEditDetails(editedDetail: 'Personal Detail' | 'Payment Dates' | 'Payment Method') {
    window.dataLayer.push({
      edited_account_section: editedDetail,
      event: 'edit_account'
    });
  }

  myAccountContactSupport() {
    window.dataLayer.push({
      event: 'contact_account_support'
    });
  }

  contactSupport() {
    window.dataLayer.push({
      event: 'contact_account_support'
    });
  }

  editSIM_SetPeakLimit(limit: string) {
    window.dataLayer.push({
      sim_limit: limit,
      event: 'set_peak_limit'
    });
  }

  notifyCoverageForm() {
    window.dataLayer.push({
      event: 'contact_notify_coverage'
    });
  }

  legalButton(name) {
    window.dataLayer.push({
      event: name
    });
  }

  dataPrivacyQueryButton(name) {
    window.dataLayer.push({
      event: name
    });
  }

  dataPrivacySubmitButton(name) {
    window.dataLayer.push({
      event: name
    });
  }

  selectedPaymentMethod(label: string) {
    window.dataLayer.push({
      page_location: '/payment-details',
      page_title: 'Payment Details',
      user_id: this.userService.getUserId(),
      user_type: 'existing customer',
      event_action: 'btn-click',
      event_category: 'content selected',
      event_label: label
    });
  }

  rainOnePurchaseSuccess(transactionId: string, productId: string, price: string) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: transactionId,
            affiliation: 'rain: web',
            revenue: price,
            coupon: ''
          },
          products: [
            {
              name: 'rainOne',
              id: productId,
              price,
              brand: 'rain'
            }
          ]
        }
      }
    });
  }

  nvidiaPurchaseSuccess(transactionId: string, productId: string, price: string) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: transactionId,
            affiliation: 'rain: web',
            revenue: price,
            coupon: ''
          },
          products: [
            {
              name: 'nvidia',
              id: productId,
              price,
              brand: 'rain'
            }
          ]
        }
      }
    });
  }

  rainOnePurchaseFail(transactionId: string, productId: string, price: string) {
    console.log('IN FAIL');
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'purchase_failed',
      revenue: price,
      id: transactionId,
      fail_issue: 'unsuccessful purchase',
      ecommerce: {
        products: [
          {
            name: 'rainOne',
            id: productId,
            price,
            brand: 'rain'
          }
        ]
      }
    });
  }

  takeMyMoneyPaymentPop(paymentAmount: number) {
    window.dataLayer.push({
      event: 'take_my_money_pop_launched',
      user_id: this.userService.getUserId(),
      user_type: 'existing customer',
      context: 'take my money pop launched',
      amount: paymentAmount,
      event_action: 'btn-click',
      event_category: 'content selected'
    });
  }

  takeMyMoneyPaymentSuccess(paymentAmount: number, message: string) {
    window.dataLayer.push({
      event: 'take_my_money_payment_success',
      user_id: this.userService.getUserId(),
      user_type: 'existing customer',
      context: 'clicked on take my money payment went succeeded',
      amount: paymentAmount,
      event_action: 'btn-click',
      event_category: 'content selected',
      message: message
    });
  }

  takeMyMoneyPaymentFail(paymentAmount: number, message: string) {
    window.dataLayer.push({
      event: 'take_my_money_payment_fail',
      user_id: this.userService.getUserId(),
      user_type: 'existing customer',
      context: 'clicked on take my money payment failed',
      amount: paymentAmount,
      event_action: 'btn-click',
      event_category: 'content selected',
      message: message
    });
  }

  takeMyMoneyPaymentError(paymentAmount: number, message: string) {
    window.dataLayer.push({
      event: 'take_my_money_payment_error',
      user_id: this.userService.getUserId(),
      user_type: 'existing customer',
      context: 'clicked on take my money but got api error',
      amount: paymentAmount,
      event_action: 'btn-click',
      event_category: 'content selected',
      message: message
    });
  }

  takeMyMoneyPaymentOtherOptions(paymentAmount: number) {
    window.dataLayer.push({
      event: 'take_my_money_payment_OtherOptions',
      user_id: this.userService.getUserId(),
      user_type: 'existing customer',
      context: 'clicked pay with other options while on take my money pop',
      amount: paymentAmount,
      event_action: 'btn-click',
      event_category: 'content selected'
    });
  }

  takeMyMoneyPaymentFailOptionRoute(paymentAmount: number) {
    window.dataLayer.push({
      event: 'take_my_money_payment_fail_option',
      user_id: this.userService.getUserId(),
      user_type: 'existing customer',
      context: 'payment failed, user redirected to other payment options',
      amount: paymentAmount,
      event_action: 'btn-click',
      event_category: 'content selected'
    });
  }

  takeMyMoneyPaymentMethod() {
    window.dataLayer.push({
      event: 'take_my_money_payment_Method',
      user_id: this.userService.getUserId(),
      user_type: 'existing customer',
      context: 'take my money popped but did not get payment method,redirected to payment page',
      event_action: 'btn-click',
      event_category: 'content selected'
    });
  }

  manageContactFormSubmit(subject: string) {
    window.dataLayer.push({
      event: 'manage_contact_form_submitted ',
      user_id: this.userService.getUserId(),
      user_type: 'existing customer',
      context: `manage contact form submitted for ${subject}`,
      subject: subject,
      event_action: 'btn-click',
      event_category: 'content selected'
    });
  }

  rainGoClicked() {
    window.dataLayer.push({
      event: 'rain_go_clicked_from_manage_page',
      user_id: this.userService.getUserId(),
      user_type: 'existing customer',
      context: 'rain go linked clicked from manage page',
      event_action: 'btn-click',
      event_category: 'content selected'
    });
  }

  requestUpgradeCall(leadID: string, subject: string) {
    window.dataLayer.push({
      event: 'Monarch_upgrade_to_rainOne_5G_ticket_created',
      lead_id: leadID,
      user_type: 'existing customer',
      context: 'Monarch upgrade to rainOne 5G ticket created',
      subject: subject,
      user_id: this.authService.isSignedIn ? this.userService.getUserId() : ''
    });
  }

  requestUpgradeCallModalPopped(subject: string) {
    window.dataLayer.push({
      event: 'Monarch_upgrade_to_rainOne_5G_modal_popped',
      user_type: 'existing customer',
      context: 'Monarch upgrade to rainOne 5G modal opened',
      subject: subject,
      user_id: this.authService.isSignedIn ? this.userService.getUserId() : ''
    });
  }

  homeLandingPageBtnClicks(subject: string) {
    window.dataLayer.push({
      event: `home landing Page ${subject} button clicked`,
      context: `home landing Page ${subject} button clicked`,
      subject: subject,
      event_action: 'btn-click',
      user_id: this.authService.isSignedIn ? this.userService.getUserId() : '',
      event_category: 'content selected'
    });
  }
}
