import { Component, OnInit } from '@angular/core';
import { DataLayerService } from '@services/data-layer.service';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UIMode, UIState } from 'src/app/shared/store/state/ui.state';
import { CoreState } from 'src/app/core/store/state/core.state';

@Component({
  selector: 'rain-router-skin-options',
  templateUrl: './router-skins-options.component.html',
  styleUrls: ['./router-skins-options.component.scss']
})
export class RouterSkinsOptionsComponent implements OnInit {
  isWorkMode: boolean;
  is_ios: boolean;
  loading: boolean;
  @Select(UIState.GetUIMode) selectedMode$: Observable<UIMode>;
  private unsubscribe$: Subject<void> = new Subject<void>();
  constructor(private dataLayerService: DataLayerService, private store: Store) { }
  optionSelected = 'art';
  public skinColors: string[] = [
    'lavender',
    'orange',
    'redpink',
    'blue',
    'black',
    'pink',
    'greigesoft',
    'mildblue'
  ];

  public artColors: string[] = [
    'CPE_101_Front_KRISTEN',
    'CPE_101_Front_CHELSEA',
    'CPE_101_Front_KHWEZI',
    'CPE_101_Front_MAJA',
    'CPE_101_Front_SANA',
    'CPE_101_Front_ZOLILIE',
  ];
  public selectedSkinColor: string = 'CPE_101_Front_KRISTEN';
  items: string[] = [
    'Item 1',
    'Item 2',
    'Item 1',
    'Item 2',
    'Item 1',
    'Item 2',
    // Add more items as needed to total 12 items
  ];

  ngOnInit(): void {
    this.optionSelected = 'art';
    this.selectedMode$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.isWorkMode = result === "sme";
        this.selectedSkinColor = this.isWorkMode
          ? (this.optionSelected === 'rain' ? 'black' : 'CPE_101_Front_KRISTEN')
          : (this.optionSelected === 'rain' ? 'lavender' : 'CPE_101_Front_KRISTEN');
      });
    this.is_ios = this.store.selectSnapshot(CoreState.getUserDevice).os === "IOS";
  }

  public selectSkinColor(color: string): void {
    this.loading = true;
    
    this.selectedSkinColor = color;
    this.dataLayerService.selectContent(color);
    navigator.vibrate(50);
  }

  onImageLoad() {
    this.loading = false;
  }

  setType(type: string): void {
    this.optionSelected = type;
    this.selectedSkinColor = this.optionSelected === 'rain'
      ? 'lavender'
      : 'CPE_101_Front_KRISTEN';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
// ToDo: type the data, have default skin, check how buttons will look when all skins are available
