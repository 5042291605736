<section class="section-phones-sims" *ngIf="(activeBreakpoint$ | async ) as deviceType">
  <div class="rain-one-101-tile-wrapper">
    <div class="product-lockup-wrapper">
        <br>
        <img class="rainMobile-product-img" src="assets/images/rain-101/homepage/rain-mobile-sim3.webp" alt="">

    </div>
    <div class="product-description-wrapper">
        <img class="rainMobile-logo"
            src="assets/images/rain-101/homepage/rain-mobile-logo.svg" alt="">
        <ul>
    
          <li>2 gigs, 60 minutes & 100 sms <span class="fw-bold">every month</span></li>
          <li>On rain's national 4G network</li>
          <li>With HD voice calls*</li>
          <li>No contracts</li>
        </ul>
        <p class="for-phones-text">*For phones that don't support VoLTE, download rainTalk</p>
        <div class="rain-one-button-container">
            <div class="price-wrapper">
                <span class="price">R165</span>
                <span class="month-to-month">month-to-month</span>
            </div>
        </div>
    </div>

</div>
</section>