import { CartItem } from '@services/cart.service';
import { Utils } from 'src/app/utils';
import { OrderType } from '../state/cart.state';

export class AddToCart {
  static type = Utils.Helpers.Type('[CART] Add To Cart');
  constructor(public item: CartItem) { }
}

export class RemoveFromCart {
  static type = Utils.Helpers.Type('[CART] Remove From Cart');
  constructor(public item: CartItem) { }
}

export class RemoveNvidiaFromCart {
  static type = Utils.Helpers.Type('[CART] Remove nvidia from Cart');
  constructor(public item: CartItem) { }
}
export class SetOrderType {
  static type = Utils.Helpers.Type('[CART] Set Order Type');
  constructor(public type: OrderType) { }
}

export class SetAllowedProductLimit {
  static type = Utils.Helpers.Type('[CART] Set Allowed Product Limits');
  constructor(public limits: { allowed5G: number; allowed4G: number }) { }
}

export class ResetCart {
  static type = Utils.Helpers.Type('[CART] Reset Cart');
}
export class EmptyCart {
  static type = Utils.Helpers.Type('[CART] Empty Cart');
}
export class ClearCart {
  static type = Utils.Helpers.Type('[CART: Clear] Clear Cart');
}

export class GenerateNewRainOneOrder {
  static type = Utils.Helpers.Type('[CART: Generate] Generate new rain one order');
}

export class StartRainOnePurchase {
  static type = Utils.Helpers.Type('[CART: Purchase] Start rain one bundle purchase');
  constructor(public readonly payload: string) { }
}

export class StartMobilePurchase {
  static type = Utils.Helpers.Type('[CART: Purchase] Start mobile bundle purchase');
  constructor(public readonly payload: string) { }
}
export class AddExtraPhoneLine {
  static type = Utils.Helpers.Type('[CART: Add-on] Add extra phone line');
}
export class MigratingToR1FiveG {
  static type = Utils.Helpers.Type('[CART: Migrate] Migrate to rain 1 five G');
  constructor(public readonly payload: string) { }
}

export class RemoveExtraPhoneLine {
  static type = Utils.Helpers.Type('[CART: Add-on] Remove extra phone line');
  constructor(public readonly payload: string) { }
}
export class ChangeWifiSpeed {
  static type = Utils.Helpers.Type('[CART: VAS] Add wifi speed vas');
  constructor(public readonly payload: number) { }
}

export class RemoveWifiSpeed {
  static type = Utils.Helpers.Type('[CART: VAS] Remove wifi speed vas');
  constructor(public readonly payload: string) { }
}

export class ChangeRainOneLevel {
  static type = Utils.Helpers.Type('[CART: Level] Change rain one level');
  constructor(public readonly payload: {name: string, type: string}) { }
}

export class ChangeRainOneVas {
  static type = Utils.Helpers.Type('[CART: Vas] Change rain one vas for new level vas');
}

export class SetSelectedRouterSkin {
  static type = Utils.Helpers.Type('[CART: Skin] Set selected router skin');
  constructor(public readonly payload: any) {}
}

export class SwapRainOneAuForPP {
  static type = Utils.Helpers.Type('[CART: Swap] Swap Rain One AU for Rain One PP');
}

export class SwapRainOneWifiAuForPP {
  static type = Utils.Helpers.Type('[CART: Swap] Swap Rain One Wifi AU for Rain One PP');
}

export class SetTopUpAmounts {
  static type = Utils.Helpers.Type('[CART: Top-Up] Set buy more values');
  constructor(public readonly payload: { [type: string]: { price: number; quantity: number; displaySymbol: string }, multiplier: any }) { }
}

export class IsRainOneTopup {
  static type = Utils.Helpers.Type('[CART: Top-Up] Top-Up is rainOne');
  constructor(public isRain1TopUp: boolean){}
}

export class TopUPPurchase {
  static type = Utils.Helpers.Type('[CART: Top-Up] Purchase top up');
  constructor(public readonly payload?: any) { }
}
export class TopUPPurchaseSuccess {
  static type = Utils.Helpers.Type('[CART: Top-Up] Purchase top up success');
  constructor(public readonly payload: any) { }
}
export class TopUPPurchaseFail {
  static type = Utils.Helpers.Type('[CART: Top-Up] Purchase top up fail');
  constructor(public readonly payload: any) { }
}

export class ClearTopUpDetails {
  static type = Utils.Helpers.Type('[CART: Top-Up] clear Top-Up details')
}

export class ClearAllVas {
  static type = Utils.Helpers.Type('[CART: VAS] clear all vas')
}

export class StartNvidiaPurchase {
  static type = Utils.Helpers.Type('[CART: Purchase] Start Nvidia purchase');
  constructor(public readonly payload?: string, public readonly isMultiPurchase?: boolean) { }
}

export class StartNvidiaPurchaseWith5GMigrations {
  static type = Utils.Helpers.Type('[CART: Purchase] Start Nvidia purchase with 5G migrations');
  constructor(public readonly payload?: string, public readonly isMultiPurchase?: boolean) { }
}

export class ChangeNvidiaTier {
  static type = Utils.Helpers.Type('[CART: Change] Change Nvidia tier');
  constructor(public readonly payload: string) { }
}

export class SwapNvidiaProducts {
  static type = Utils.Helpers.Type('[CART: Swap] Swap Nvidia product type');
}

export class CheckForOpenNvidiaOrder {
  static type = Utils.Helpers.Type('[CART: Check] Swap Nvidia product type');
}

export class RemoveRainOneFromCart {
  static type = Utils.Helpers.Type('[CART: Remove] Remove rainone package from cart');
}

export class RemoveNvidiaAddon {
  static type = Utils.Helpers.Type('[CART: nvidia] Remove nvidia addon');
}
export class CoverageCheckedOnRegistration {
  static type = Utils.Helpers.Type('[CART: coverage] Set coverage checked on registration');
  constructor(public checked: boolean) {}
}

export class RemoveBundle {
  static type = Utils.Helpers.Type('[CART: nvidia] Remove rainone bundle from cart');
}

export class RegisterWithoutCompanyDetails {
  static type = Utils.Helpers.Type('[CART: check] Set register without company details checked on registration');
  constructor(public checked: boolean) {}
}

export class AcceptTsCs {
  static type = Utils.Helpers.Type('[CART: Ts and Cs] Accept Ts and Cs'); 
}

