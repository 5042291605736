<div class="modal-container" *ngIf="activeBreakPoint$ | async">
  <div class="modal-content-wrapper">
    <div class="close-modal">
      <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" />
    </div>
    <br />
    <div class="modal-title">
      <h2>there’s no <span class="bold-text">5G</span> in your area yet</h2>
      <p *ngIf="(selectedUiMode$ | async) === 'consumer'">
        But you can still get rain<b class="bold-text">one</b> with unlimited 4G wifi.
      </p>
      <p *ngIf="(selectedUiMode$ | async) === 'sme'">
        But you can still get rain<b class="bold-text">one work</b> with unlimited 4G wifi.
      </p>
    </div>
    <div class="modal-image-wrapper">
      <div class="modal-image" *ngIf="(selectedUiMode$ | async) === 'sme'">
        <img
          *ngIf="(activeBreakPoint$ | async) !== 'mobile'"
          src="/assets/images/rain-101/coverage-modal/sme-coverage-desktop.png"
          alt="not in coverage "
        />
        <img
          *ngIf="(activeBreakPoint$ | async) === 'mobile'"
          src="/assets/images/rain-101/coverage-modal/sme-coverage-mobi.png"
          alt="not in coverage "
        />
      </div>

      <div class="modal-image" *ngIf="(selectedUiMode$ | async) === 'consumer'">
        <img
          *ngIf="(activeBreakPoint$ | async) !== 'mobile'"
          src="/assets/images/rain-101/coverage-modal/home-coverage-desktop.png"
          alt="not in coverage "
        />
        <img
          *ngIf="(activeBreakPoint$ | async) === 'mobile'"
          src="/assets/images/rain-101/coverage-modal/home-coverage-mobi.png"
          alt="not in coverage "
        />
      </div>

      <div class="bottom-sub-content">
        <p *ngIf="(selectedUiMode$ | async) === 'consumer'">
          As soon as you’re in 5G coverage, we’ll upgrade you. Then you’ll have the option to <b class="bold-text">speed up</b> your home wifi. If
          you’d prefer to wait for 5G, tap <b class="bold-text">‘notify me’</b> and we’ll let you know the moment it reaches you.
        </p>
        <p *ngIf="(selectedUiMode$ | async) === 'sme'">
          As soon as you’re in 5G coverage, we’ll upgrade you. Then you’ll have the option to <b class="bold-text">speed up</b>
          your work wifi. If you’d prefer to wait for 5G, tap
          <b class="bold-text">‘notify me’</b> and we’ll let you know the moment it reaches you.
        </p>
        <div class="terms-and-conditions">
          <span class="terms" (click)="openTerms()">Ts & Cs apply</span>
        </div>
      </div>
    </div>
    <div class="button-container">
      <div class="left-buttons">
        <button (click)="onNotifyMe()" class="btn btn-sm btn-transparent border-primary text-primary mr-4 notify-btn">notify me</button>
      </div>
      <div class="right-buttons">
        <app-support-button
          [disabled]="isTicketCreationAllowed$ | async"
          btnClass="btn btn-sm btn-transparent border-primary text-primary on-register "
          [dataId]="'btn-click'"
          [isPromo]="false"
          fromPage="coverage check"
          fromButton="productCallMe"
          title="request sales call"
          id="btn-cust"
          [subject]="'rain sales support #'"
          [bypassModal]="false"
          [productId]="rainOneBundle.id"
          [showSignIn]="false"
          (click)="onOpenSupport()"
        >
        </app-support-button>
        <button (click)="onContinueToBuy()" class="btn btn-sm btn-primary text-white buy-btn">buy</button>
      </div>
    </div>
  </div>
</div>
